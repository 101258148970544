.checkbox {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    padding: 5px;
    width: 100%;

    @media (min-width: $tablet) {
        width: auto;
    }

    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 0;
        margin: 0;
    }

    &__check {
        flex-shrink: 0;
        position: relative;
        height: 18px;
        width: 18px;
        background: white;
        border: 1px solid $omm-blue;
        margin-right: 8px;
        padding: 0;
        border-radius: 2px;

        &:after {
            left: 5px;
            top: 1px;
            content: '';
            position: absolute;
            display: none;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
        }
    }

    span {
        font-size: 14px;
        color: $slate-dark;
    }

    &:hover {
        input~.checkbox__check {
            background: white;
        }

        input:not([disabled]):checked~.checkbox__check {
            background: $omm-blue;
        }
    }

    input:checked~.checkbox__check:after {
        display: block;
    }

    input:focus~.checkbox__check {
        background: white;
    }

    input:checked~.checkbox__check,
    input:checked:focus~.checkbox__check {
        background: $omm-blue;
    }

    input:disabled~.checkbox__check {
        background: white;
        border: 1px solid $slate;
        pointer-events: none;
    }
}

.professionals-checkbox {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    padding: 5px;
    width: 100%;
    min-width: 160px;

    @media (min-width: $tablet) {
        width: auto;
        justify-content: flex-end;
    }

    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 0;
        margin: 0;
    }

    &__check {
        flex-shrink: 0;
        position: relative;
        height: 12px;
        width: 12px;
        background: white;
        border: 1px solid $slate;
        margin-left: 8px;
        padding: 0;
        border-radius: 2px;

        &:after {
            left: 3px;
            top: -1px;
            content: '';
            position: absolute;
            display: none;
            width: 3px;
            height: 8px;
            border: solid white;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
        }
    }

    span {
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        color: $slate;
    }

    &:hover {
        input~.professionals-checkbox__check {
            background: white;
        }

        input:not([disabled]):checked~.professionals-checkbox__check {
            background: $omm-blue;
            border: 1px solid $omm-blue;
        }
    }

    input:checked~.professionals-checkbox__check:after {
        display: block;
    }

    input:focus~.professionals-checkbox__check {
        background: white;
    }

    input:checked~.professionals-checkbox__check,
    input:checked:focus~.professionals-checkbox__check {
        background: $omm-blue;
        border: 1px solid $omm-blue;
    }

    input:disabled~.professionals-checkbox__check {
        background: white;
        border: 1px solid $slate;
        pointer-events: none;
    }
}