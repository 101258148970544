.share-buttons {
    display: flex;
    align-items: center;
    gap: 5px;

    &__label {
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.3px;
        text-align: left;
        color: $slate;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 80px;
        background-color: #f4f7fc;
        text-decoration: none;

        i {
            color: $omm-blue;
            font-size: 14px;
            ;
        }
    }
}