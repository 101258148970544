.featured-slider {
    border-radius: 10px;
    overflow: hidden;
    background-color: $omm-dark-blue-02;
    margin-bottom: 16px;
    min-height: 250px;
    flex: 1;

    .swiper-pagination-bullet {
        background: transparent;
        background-color: rgba(255, 255, 255, 0.5);
        opacity: 1;
        width: var(--swiper-pagination-bullet-width,
                var(--swiper-pagination-bullet-size, 8px));
        height: var(--swiper-pagination-bullet-height,
                var(--swiper-pagination-bullet-size, 8px));

        &-active {
            border: 1px solid white;
            background: white;
        }
    }

    .swiper-pagination {
        bottom: 24px !important;
        text-align: center;

        @media (min-width: $desktop) {
            bottom: unset !important;
            top: 10px;
            right: 19px;
            padding-right: 19px;
            text-align: right;
        }
    }

    &__slide {
        min-height: 250px;
        @include flexbox();
        @include justify-content(flex-start);
        @include flex-direction(row);
        @include align-items(flex-start);
        flex-wrap: wrap;
        border-radius: 10px;
        width: 100%;
        flex: 1;
        text-decoration: none;

        @media (min-width: $desktop) {
            min-height: 250px;
        }
    }

    &__image {
        background-position: center;
        background-size: cover;
        width: 65px;
        height: 65px;
        border-radius: 65px;
        align-self: center;
        margin-left: 21px;

        @media (min-width: $desktop) {
            width: 129px;
            height: 129px;
            border-radius: 129px;
        }
    }

    &__content {
        flex: 1;
        @include flexbox();
        @include justify-content(center);
        @include flex-direction(column);
        @include align-items(flex-start);
        padding: 0 16px;
        padding-right: 37px;
        align-self: stretch;

        @media (min-width: $desktop) {
            padding: 16px;
        }
    }

    &__supertitle {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.24px;
        text-align: left;
        color: white;
        text-transform: uppercase;
    }

    &__title {
        font-size: 24px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.72px;
        color: white;
        text-decoration: none;

        @media (min-width: $desktop) {
            margin-bottom: 16px;
        }
    }

    &__caption {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        a {
            color: white;
        }

        a:hover,
        a:focus {
            color: white;
            text-decoration: underline;
        }

        & p {
            color: white;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: -0.3px;
            line-height: 1.4;
        }

        &--desktop {
            display: none;

            @media (min-width: $desktop) {
                display: block;
            }
        }

        &--mobile {
            margin-bottom: 39px;
            padding: 0 37px 0 23px;

            @media (min-width: $desktop) {
                display: none;
            }
        }
    }
}