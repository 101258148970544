.testimonial-video-hero {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: $tablet) {}

    &__content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__text {
        max-width: 512px;
        padding: 24px 18px;

        h2 {
            color: white;
            font-size: 18px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: -0.54px;

            @media (min-width: $tablet) {
                font-size: 28px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.84px;
            }
        }

        p {
            color: $omm-blue-soft-3;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: -0.24px;

            @media (min-width: $tablet) {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.48px;
            }

        }
    }

    &__video {

        width: 100%;
        height: 188px;
        background-color: $slate;
        background-position: center;
        background-size: cover;
        overflow: hidden;
        position: relative;

        @media (min-width: $tablet) {
            width: 50%;
            height: 572px;
        }
    }
}