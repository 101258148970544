.testimonial-slider {
    width: 100%;

    &__header {
        margin-bottom: 30px;
        display: block;
    }

    &__title {
        font-size: 24px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.86px;
        text-align: center;
        color: $omm-blue;
        text-align: center;
        margin-bottom: 20px;

        @media (min-width: $tablet) {
            font-size: 40px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: center;
            margin-bottom: 32px;
        }
    }

    &__slide {
        height: 100% !important;
        background-color: $omm-blue-soft-1;
        padding: 32px 32px 30px 32px;
        min-height: 300px;
        border-radius: 10px;
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(space-between);
        margin: 0;

        @media (min-width: $tablet) {
            min-height: 330px;
        }

        &-content {
            color: #000;
            margin-bottom: 24px;
            font-size: 20px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.83px;
            text-align: left;
        }

        &-tagline {
            font-size: 14px;
            letter-spacing: -0.42px;
            color: $omm-blue;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            margin-bottom: 0;
        }
    }

    .swiper {
        border-radius: 10px;
        
        // this is to compensate for the next and prev buttons
        @media (min-width: $laptop) {
            width: calc(100% - (2 * 44px));
        }
    }

    .swiper-slide {
        height: auto !important;
        margin-bottom: $space-xxs;
    }

    .swiper-pagination {
        padding-top: $space-sm;
        position: initial;
    }
}

.testimonial {
    margin: 50px 0 92px;
    padding: 0 12px;
    width: 100%;

    @media (min-width: $laptop) {
        margin: 50px auto 80px;
        padding: 0;
    }

    &__header {
        margin-bottom: 30px;
        display: block;
    }

    &__title {
        color: $omm-blue;
        @extend h1;
        text-align: center;
    }

    &__slide {
        background-color: $omm-blue-soft-2;
        padding: 48px 32px 48px 32px;
        border-radius: 10px;
        text-align: center;
        max-width: 80%;
        margin: 0 auto;

        &-content {
            color: #000;
            margin-bottom: 29px;
            font-size: 34px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -1.02px;
            text-align: center;
            color: $omm-dark-blue-01;

        }

        &-tagline {
            font-size: 20px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.6px;
            text-align: center;
            color: $omm-blue;
            margin-bottom: 0;
        }
    }
}

.container .testimonial-slider {
    @include gutters();
}

// .container-fluid .testimonial-slider {
//     @include guttersFullWidth();
// }