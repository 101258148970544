.locations {
    padding-top: 30px;
    min-height: 100vh;

    &__title-bar {
        border-top: 1px solid $soft-grey-03;
        border-bottom: 1px solid $soft-grey-03;
        padding: 6px 0px;
        margin-bottom: 16px;

        @media (min-width: $tablet) {
            margin-bottom: 23px;
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;

        }

        p {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.36px;
            text-align: left;
            color: $slate;
            margin: 0;
            display: none;

            @media (min-width: $tablet) {
                display: block;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.36px;
            }
        }
    }

    &__title {
        @include gutters();

        flex: 1;
        padding-top: 36px;

        @media (min-width: $tablet) {
            padding-top: $space-sm;
        }

        @media (min-width: $desktop) {
            padding-top: $space-xxxl;
        }

        h1 {
            font-size: 28px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
            color: $omm-blue;
            margin-bottom: 6px;

            @media (min-width: $tablet) {
                font-size: 40px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -1px;
                text-align: left;
                margin-bottom: 16px;
            }
        }

        p {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.36px;
            color: $slate;
            margin-bottom: 22px;

            @media (min-width: $tablet) {
                display: none;
            }
        }
    }

    &__filters {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
        padding-bottom: 56px;
    }

    &__error {}

    &__filter-region {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 32px;
    }

    &__filter-display {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__filter-button {
        color: $omm-blue;
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 29px;
        background-color: $omm-blue-soft-1;
        border-radius: 14px;
        border: 1px solid $omm-blue-soft-1;
        padding: 0 16px;
        cursor: pointer;
        margin-right: 5px;
        min-width: 77px;
        flex: 1;

        &:hover,
        &:focus {
            background-color: $omm-grey-soft-2;
        }

        &--active {
            color: white;
            background-color: $omm-blue;

            &:hover,
            &:focus {
                background-color: $omm-blue;
            }
        }

        &--map,
        &--grid {
            padding: 0;
            width: 29px;
            height: 29px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 17px;
            border-radius: 29px;
        }

        @media (min-width: $tablet) {
            flex: none;
            padding: 0 31px;
        }
    }

    &__grid-view {
        @include gutters();

        @media (min-width: $tablet) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

    }

    &__map-view {
        @include gutters();
    }

    &__map {
        z-index: 1000;
        border: 1px solid $omm-grey-soft-3;
        // height: 70vh;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 10px;
        min-height: 400px;

        @media (min-width: $tablet) {
            margin-bottom: 20px;
            min-height: 679px;
        }

        .vue-map>div {
            background-color: #192330 !important;
        }
    }

    &__map-key {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        i {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 17px;
            color: $omm-blue;
            width: 29px;
            height: 29px;
            border-radius: 29px;
            background-color: $soft-blue-02;
            margin-right: 10px;
        }

        p {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.36px;
            color: $omm-blue;
            margin: 0;

            @media (min-width: $tablet) {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.36px;
            }
        }
    }

}

.location-content__content {
    .container {
        padding: 0 $gutter-sm;
    }
}

.location-filters {
    @include sticky;
    background-color: white;
    z-index: 2000;

    .container {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(center);
        height: 42px;
    }

    border-top: 1px solid $soft-grey-03;
    border-bottom: 1px solid $soft-grey-03;

    &__wrapper {
        margin-bottom: $space-xxs;
    }

    @media (min-width: $tablet) {
        margin-bottom: $space-xxs;
    }

    &__count-desktop {
        display: none;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        color: $slate;
        margin: 0;

        @include gutters();

        @media (min-width: $tablet) {
            display: block;
            flex: 1;
        }
    }

    &__count-mobile {
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        color: $slate;
        margin: 0;
        margin-bottom: 14px;
        @include gutters();

        @media (min-width: $tablet) {
            display: none;
        }
    }

    &__filter {
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);

        flex: 1;
        border-right: 1px solid $soft-grey-03;
        align-self: stretch;

        @media (min-width: $tablet) {
            display: none;
        }

        span {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: -0.24px;
            text-align: left;
            color: $omm-blue;
        }

        i {
            font-size: 16px;
            color: $omm-blue;
            margin-right: 17px;
        }

    }

    &__filter-dropdown {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(center);
        width: 100%;
        padding: 0 21px;

        span {
            margin: 0;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
        }

        i {
            font-size: 24px;
            margin-right: 0;
        }

        @media (min-width: $tablet) {
            display: none;
        }
    }

    &__filter-desktop {
        display: none;
        margin-right: 23px;

        p {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            margin: 0;
            color: $slate;
            margin-right: 18px;
        }

        @media (min-width: $tablet) {
            @include flexbox;
            @include justify-content(space-between);
            @include align-items(center);
        }
    }

    &__filter-dropdown-content {
        @include flexbox;
        @include justify-content(flex-start);
        @include align-items(center);
        z-index: 9400;
        border-bottom: 1px solid $soft-grey-03;
        background-color: white;
        // position: absolute;
        // top: calc(100% + 1px);
        padding: 12px;
        width: 100%;
        // left: 0;
        margin-bottom: 16px;

        @media (min-width: $tablet) {
            display: none;
        }
    }

    &__display {
        @include guttersFullWidth();

        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;

        @media (min-width: $tablet) {
            border-left: 1px solid $soft-grey-03;
            border-right: 1px solid $soft-grey-03;
        }
    }

    &__display-button {
        color: $slate;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.36px;
        text-align: center;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0 15px;
        cursor: pointer;
        margin-left: 8px;
        margin-right: 8px;
        min-width: auto;
        padding: 0;
        width: 29px;
        height: 29px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 27px;

        &:hover,
        &:focus {
            background-color: transparent;
            border: 1px solid transparent;
        }

        &--active {
            color: $omm-blue;
            background-color: transparent;
            border: 1px solid transparent;

            &:focus {
                color: $omm-blue;
                background-color: transparent;
                border: 1px solid transparent;
            }
        }
    }
}

.locations-card {
    display: block;
    min-height: 209px;
    margin-bottom: 16px;
    display: flex;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid $omm-grey-soft-3;
    // transition: all 200ms ease-in-out;

    @media (min-width: $desktop) {
        width: calc(50% - 16px);
        min-height: 209px;
    }

    &--half {
        @media (min-width: $laptop) {
            width: calc(50% - 8px);
        }
    }

    a {
        transition: all 200ms ease-in-out;
        color: $omm-blue;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__phone {
        color: $slate;
        font-size: 14px;
        line-height: 1.29;
        letter-spacing: -0.28px;
        margin-bottom: 10px;

        @media (min-width: $tablet) {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;
        }
    }

    &__title {
        display: inline-block;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        margin-bottom: 8px;
        text-decoration: none;

        @media (min-width: $tablet) {
            font-size: 16px;
        }
    }

    &__address {
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        line-height: 1.3;
        text-align: left;
        color: $slate;
        margin-bottom: 7px;

        @media (min-width: $tablet) {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;
            margin-bottom: 8px;
        }
    }

    &__actions {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(center);
        flex-wrap: wrap;
        gap: $space-xxxs;
        width: 100%;

        a {
            display: block;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;

            @media (min-width: $tablet) {
                font-size: 14px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -0.5px;
                text-align: left;
            }
        }
    }

    &__image {
        width: 40%;
        background-size: cover;
        background-position: center;
        display: none;

        @media (min-width: $tablet) {
            display: block;
            width: 54%;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        // width: 100%;
        padding: 22px;
        white-space: pre-wrap;
        flex: 1;

        @media (min-width: $tablet) {
            // width: 60%;
        }
    }

    &__details {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-self: stretch;
    }

    &--info-window {
        border: none;
        width: 100%;
        font-family: 'proxima-nova', sans-serif !important;

        .locations-card__image {
            display: none;
        }

        .locations-card__inner {
            width: 100%;
        }
    }

    &__translations {
        color: $slate;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        margin-bottom: 7px;
    }
}

.gm-style-iw-t button.gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;
}

.gm-style-iw.gm-style-iw-c {
    min-width: 300px !important;
}