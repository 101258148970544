.history {
    background-color: $omm-dark-blue-02;
    position: relative;
    height: 550px;
    overflow: hidden;
    padding: 20px 0;

    @media (min-width: $tablet) {
        height: 680px;
        padding: 40px 0;
    }

    &-overview {
        display: none;

        @media (min-width: $tablet) {
            display: block;
            position: relative;
            right: auto;
            top: auto;
        }
    }

    &-overview--mobile {
        display: flex;

        .button {
            display: inline-flex;
            margin: 18px auto;
            align-self: center;
        }

        @media (min-width: $tablet) {
            display: none;
        }
    }

    &-footer {
        padding-left: 0px;
        position: absolute;
        bottom: 30px;
        z-index: 3900;
        overflow-x: auto;
        overflow-y: hidden;
        @include hiddenScrollbar();
        width: 100%;

        @media (min-width: $tablet) {
            position: absolute;
            bottom: 30px;
            left: 1%;
            right: 1%;
            margin: 0 auto;
            max-width: 974px;
            padding: 0 18px;
            @include flexbox();
            @include flex-direction(row);
            @include align-items(center);
        }
    }
}

.history-item {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    z-index: 12;
    transition: all 200ms ease-in-out;

    @media (min-width: $tablet) {
        min-width: 480px;
        max-width: 480px;
    }

    &:nth-child(even) {
        .history-item__year {
            order: 1;
            margin-top: -25px;
            margin-bottom: 0;

            @media (min-width: $tablet) {
                margin-top: -54px;
                margin-bottom: 0;
            }
        }

        .history-item__header {
            margin-bottom: 0px;
        }
    }

    &__wrapper {
        @include flexbox();
        @include flex-direction(row);
        @include align-items(flex-start);
        width: auto;
        overflow-x: scroll;
        overflow-y: hidden;
        height: 100%;
        padding: 12px 16px 60px 16px;
        gap: 0 45px;
        @include hiddenScrollbar();

        @media (min-width: $tablet) {
            padding: 0 600px 60px 24px;
            gap: 0 120px;
        }
    }

    &__header {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(flex-end);
        margin-bottom: 16px;

        @media (min-width: $tablet) {
            margin-bottom: 28px;
        }
    }

    &__year {
        font-size: 60px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -2.14px;
        text-align: center;
        line-height: 1;
        color: $omm-accent;
        margin-top: 0;
        margin-bottom: -22px;
        z-index: 1;

        @media (min-width: $tablet) {
            margin-top: -22px;
            font-size: 140px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -5.83px;
            margin-bottom: -60px;
            text-align: left;
        }
    }

    &__image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 185px;
        border-radius: 10px;
        background-color: grey;
        width: 100%;

        @media (min-width: $tablet) {
            height: 270px;
        }
    }

    &__title {
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.71px;
        text-align: left;
        color: white;


    }

    &__content {
        color: white;

        @media (min-width: $tablet) {
            margin-left: 0px;
        }

        p {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            color: white;

        }

    }

    // Handle the pop effect
    opacity: 0.8;
    transform: scale(0.9);

    &--active {
        opacity: 1;
        transform: scale(1);
    }
}

.history-legend {
    position: relative;
    width: 100%;
    background-color: rgba($omm-blue, 0.2);
    border-radius: 30px;
    border-radius: 0;
    border-radius: 0;
    padding: 12px 0 12px 0px;
    overflow: hidden;
    @include hiddenScrollbar();

    @media (min-width: $tablet) {
        max-width: 900px;
        max-height: 55px;
        border-radius: 30px;
        flex: 1;
        margin-right: 24px;
    }

    &__wrapper {
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
        width: auto;
        margin: 0;
        padding: 0 64px;
        overflow-x: auto;
        @include hiddenScrollbar();


        li {
            list-style: none;
        }
    }

    &__arrow {
        position: absolute;
        top: 0;
        height: 55px;
        padding-right: 16px;
        cursor: pointer;


        &:first-child {
            background-image: linear-gradient(to right, rgba(27, 54, 112, 1), rgba(27, 54, 112, 0));
            left: 0;
            padding-left: 32px;
        }

        &:last-child {
            background-image: linear-gradient(to left, rgba(27, 54, 112, 1), rgba(27, 54, 112, 0));
            right: 0;
            padding-right: 32px;
        }

        i {
            font-size: 22px;
            color: white;
            height: 55px;
            line-height: 55px;
        }
    }

    &__item {
        border-radius: 30px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.33;
        color: white;
        padding: 7px 14px;
        letter-spacing: -0.36px;
        width: 90px;
        min-width: 90px;
        max-width: 90px;
        margin-right: 16px;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        &:hover {
            background-color: rgba(white, 0.2);
        }

        &.active {
            background-color: rgba(white, 0.2);
        }
    }


}

.container .history {
    @media (min-width: $desktop) {
        border-radius: 10px;
    }
}

.container-fluid .history {
    border-radius: 0px;
}