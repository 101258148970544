.error-page {

    .container {
        @include gutters();
        padding-top: 20vh;
        padding-bottom: 20vh;
        text-align: center;

        .button {
            display: inline-flex;
        }
    }

    h1 {
        font-size: 40px;
        font-weight: 300;
        line-height: 1.15;
        letter-spacing: -1px;
        text-align: center;
        color: $omm-blue;
    }

    p {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.4;
        letter-spacing: -0.5px;
        text-align: center;
        color: black;
        margin-bottom: $gutter-lg;
    }
}