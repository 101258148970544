.card-carousel {
    max-width: 100%;
    overflow: hidden;
    display: block;
    position: relative;

    &__gradient-overlay {
        transition: all ease-in-out 200ms;

        @media (max-width: calc($desktop + 72px)) {
            display: none;
        }

        height: 1000px;
        position: absolute;

        width: 72px;
        top: 0;
        z-index: 4000;
    }

    &__gradient-overlay--left {
        left: 0;
        // background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(27, 54, 112, 0));
    }

    &__gradient-overlay--right {
        right: 0;
        // background-image: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(27, 54, 112, 0));
    }

    &__section-supertitle {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.12px;

        color: $omm-dark-blue-02;
        margin-bottom: 16px;
        text-transform: uppercase;

        padding: 0 12px;

        @media (min-width: $desktop) {
            padding: 0 0px;
        }

        &--left {
            text-align: left;
        }

        &--center {
            text-align: center;
        }

        &--right {
            text-align: right;
        }
    }

    &__section-title {
        color: $omm-blue;
        font-size: 24px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.86px;
        text-align: center;
        line-height: 1.2;
        padding: 0 21px 29px 21px;

        @media (min-width: $tablet) {
            padding: 35px 0px;
            font-size: 40px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: center;
            padding: 39px 10% 39px 10%;
        }

        &--left {
            text-align: left;
        }

        &--center {
            text-align: center;
        }

        &--right {
            text-align: right;
        }
    }

    &__slide {
        min-height: 410px;
        max-height: 450px;
        border-radius: 10px;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 35px 32px;
        @include flexbox();
        @include justify-content(flex-end);
        @include flex-direction(column);
        width: 100%;
        height: auto;
        min-width: 88vw;
        margin-right: 12px;
        position: relative;

        &--top {
            @include justify-content(flex-start);
        }

        @media (min-width: $tablet) {
            width: 415px;
            min-width: 415px;
            max-width: 415px;
            padding: 35px 60px 32px 30px;
        }

        &.swiper-slide {
            height: auto;
            margin-bottom: $space-xxs;
            min-width: initial;
            width: auto;

            @media (min-width: $tablet) {
                max-width: none;
                min-width: initial;
                width: auto;
            }
        }
    }

    &__title {
        color: white;
        font-size: 28px;
        font-weight: 300;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.5px;
        margin-bottom: 8px;
    }

    &__content {
        color: white;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.5px;
        margin-bottom: 8px;
        flex: 1;
        white-space: pre-wrap;
    }

    &__rich-text-content {
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.5px;
        text-align: left;
        margin-bottom: 8px;

        @media (min-width: $tablet) {
            font-size: 18px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
        }

        p {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.42px;
            text-align: left;
            margin-bottom: 8px;

            @media (min-width: $tablet) {
                font-size: 16px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -0.83px;
                text-align: left;
            }
        }

        ul,
        li {
            font-size: 16px;
            font-weight: 300;
            padding-left: 20px;
        }
    }

    &__container {
        z-index: 1;
        width: 100%;
    }

    &__opacity-bg {
        top: 0;
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 14%, black);
    }

    &__link {
        color: white;
        font-size: 14px;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.64px;
        margin-bottom: 8px;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &__image {
        background-color: grey;
        display: block;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
        flex: 1;
        max-height: 280px;
    }

    &__wrapper {
        @include flexbox();
        margin: 0px 0;

        &--multi {
            @include flex-direction(column);

            .card-carousel__slide {
                min-width: auto;
                padding: 0;
                width: 100%;

                &:last-child {
                    margin-right: 30px;
                }

                @media (min-width: $tablet) {
                    max-width: 415px;
                    min-width: auto;
                    width: auto;
                }
            }

            .card-carousel__title {
                margin-bottom: 2px;
            }

            .card-carousel__container {
                padding: 25px 30px;
                width: 100%;
                background-color: $omm-dark-blue-02;
            }

            .card-carousel__content {
                font-size: 14px;
                font-weight: 300;
            }

            .card-carousel__link {
                font-size: 14px;
                font-weight: 300;
                margin-left: auto;
                display: block;
                text-align: right;
            }

            .card-carousel__image {
                border-radius: 10px 10px 0 0;
            }
        }
    }

    &__legend {
        width: 700px;
        margin: $space-sm auto;
        height: 1px;
        max-width: 87%;
        background-color: $soft-grey-03;
        position: relative;

        @media (min-width: $desktop) {
            max-width: 700px;
            display: block;
        }

        &-bar {
            background-color: $omm-blue;
            height: 8px;
            position: absolute;
            top: -4px;
            left: 0;
        }
    }

    &__row {
        @include flexbox();
        @include flex-direction(row);
        margin-bottom: 16px;
        position: relative;
    }

    &--narrow {
        &.card-carousel__slide {
            min-width: 418px;
            @include justify-content(flex-start);
        }
    }

    &--text {
        border-radius: 10px;
        background-color: white;
        min-height: 338px;

        &.card-carousel__slide {
            // min-width: 335px;
            @include justify-content(flex-start);
            padding: 22px 16px;

            @media (min-width: $tablet) {
                padding: 36px 40px;
                // min-width: 632px;
                max-width: 632px;
                @include justify-content(flex-start);
            }
        }

        .card-carousel__container {
            @include flexbox();
            flex: 1;
            flex-direction: column;
            align-self: stretch;
        }

        .card-carousel__title {
            color: $omm-dark-blue-01;
            font-size: 20px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.83px;
            text-align: left;
            margin-bottom: 8px;
            flex: 1;

            @media (min-width: $tablet) {
                font-size: 34px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -1px;
                text-align: left;
            }
        }

        .card-carousel__content {
            color: $omm-blue;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.42px;
            text-align: left;
            margin-bottom: 8px;

            @media (min-width: $tablet) {
                font-size: 18px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -0.83px;
                text-align: left;
            }
        }
    }

    &__footer-text {
        color: $omm-blue !important;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
    }

    .swiper {
        border-radius: 10px;

        // this is to compensate for the next and prev buttons
        @media (min-width: $laptop) {
            width: calc(100% - (2 * 44px));
        }
    }
}