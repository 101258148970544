@import url("https://use.typekit.net/gfk8gcu.css");

body,
select,
input[type="text"],
input[type="submit"] {
    font-family: "proxima-nova", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    margin-bottom: 8px;

    @media (min-width: $tablet) {
        font-size: 16px;
    }
}

p {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: -0.3px;
    line-height: 1.4;
    text-align: left;
}

a {
    color: $omm-accent;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

ul,
li {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: -0.3px;
    line-height: 1.4;
    text-align: left;
}

h1 {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.14;

    @media (min-width: $tablet) {
        font-size: 40px;
    }
}

h2 {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.17;
    letter-spacing: -0.72px;

    @media (min-width: $tablet) {
        font-size: 32px;
        line-height: 1.2;
        letter-spacing: -1.02px;
    }
}

h3 {
    font-weight: 300;
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: -0.6px;

    @media (min-width: $tablet) {
        font-size: 28px;
        letter-spacing: -0.84px;
        font-weight: 300;
    }
}

h4 {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.33;
    letter-spacing: -0.54px;

    @media (min-width: $tablet) {
        font-size: 24px;
        font-weight: 300;
        letter-spacing: -0.72px;
    }
}

h5 {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: -0.5px;
}

h6 {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 300;
    letter-spacing: -0.5px;
}

.caption-title {
    letter-spacing: 0.12px;
    font-size: 16px;
    line-height: normal;
    text-transform: uppercase;
}

strong {
    font-weight: 500;
}

::marker {
    color: $omm-blue;
    vertical-align: middle;
    line-height: 1;
}

@font-face {
    font-family: "icomoon";
    src: url("/fonts/icomoon/fonts/icomoon.eot?8oyg18");
    src: url("/fonts/icomoon/fonts/icomoon.eot?8oyg18#iefix") format("embedded-opentype"),
        url("/fonts/icomoon/fonts/icomoon.ttf?8oyg18") format("truetype"),
        url("/fonts/icomoon/fonts/icomoon.woff?8oyg18") format("woff"),
        url("/fonts/icomoon/fonts/icomoon.svg?8oyg18#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
    content: "\e900";
}

.icon-search:before {
    content: "\e901";
}

.icon-grid:before {
    content: "\e902";
}

.icon-map-marker:before {
    content: "\e903";
}

.icon-close:before {
    content: "\e904";
}

.icon-chevron-down:before {
    content: "\e905";
}

.icon-chevron-up:before {
    content: "\e906";
}

.icon-chevron-left:before {
    content: "\e907";
}

.icon-chevron-right:before {
    content: "\e908";
}

.icon-envelope:before {
    content: "\e909";
}

.icon-linkedin:before {
    content: "\e90a";
}

.icon-instagram:before {
    content: "\e90b";
}

.icon-twitter:before {
    content: "\e90c";
}

.icon-facebook:before {
    content: "\e90d";
}

.icon-plus:before {
    content: "\e90e";
}

.icon-minus:before {
    content: "\e90f";
}

.icon-x:before {
    content: "\e90";
}

.icon-linkedin-in:before {
    content: "\e910";
}

.icon-phone:before {
    content: "\e911";
}

.icon-play:before {
    content: "\e912";
}

.icon-pdf:before {
    content: "\e913";
}

.icon-share:before {
    content: "\e914";
}

.icon-microphone:before {
    content: "\e915";
}

.icon-return:before {
    content: "\e916";
}

.icon-arrow-left:before {
    content: "\e917";
}

.icon-arrow-right:before {
    content: "\e918";
}

.icon-arrow-up:before {
    content: "\e919";
}

.icon-arrow-down:before {
    content: "\e91a";
}

.icon-youtube:before {
    content: "\ea9d";
}

.icon-person:before {
    content: "\e91b";
}