.radio-button {
    display: flex;
    padding: 2px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    margin-bottom: 12px;
    width: 100%;

    @media (min-width: $tablet) {
        margin-bottom: 8px;
        width: auto;
    }

    &__title {
        font-size: 12px;
        text-transform: uppercase;
        color: $slate-dark;
        letter-spacing: 0.5px;
    }

    input {
        margin: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 18px;
        height: 18px;
        border: 1px solid $slate;
        margin-right: 9px;
        border-radius: 50%;
        background: #fff;
    }

    input:checked {
        border-color: transparent;
        background: $omm-blue border-box;
        box-shadow: 0 0 0 2px inset #fff;
    }

    &__group {
        width: auto;
        margin-bottom: 10px;

        &--row {
            @include flex-direction(row);
            flex-wrap: wrap;
            @include flexbox();
            @include align-items(center);
            @include justify-content(flex-start);
            gap: 0 24px;

            @media (min-width: $desktop) {
                gap: 0 40px;
            }

            .radio-button:not(:last-child) {
                margin-right: 16px;
            }
        }
    }

    &__heading {
        color: $omm-blue;
        font-size: 12px;
        letter-spacing: 0.5px;
        margin-bottom: 16px;
        text-transform: uppercase;
    }
}