.alumni {

    &__hero-wrapper {
        padding: 13px 12px;

    }

    &__hero {
        margin-top: 13px;
        margin-bottom: 23px;
        padding: 18px 13px 16px 18px;
        border-radius: 10px;
        min-height: 400px;
        background-color: $slate;
        position: relative;
        background-size: cover;
        background-position: center;

        @media (min-width: $tablet) {
            min-height: 768px;
            padding: 35px 37px 30px 37px;
        }
    }

    &__hero-action {
        position: absolute;
        top: 18px;
        right: 13px;

        @media (min-width: $tablet) {
            top: 36px;
            right: 37px;
        }

        a {
            @extend .button;
        }
    }

    &__hero-content {
        min-height: 400px;
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(flex-end);
        @include align-items(flex-start);

        @media (min-width: $tablet) {
            @include justify-content(space-between);
            @include align-items(space-between);
            min-height: 768px;
            max-width: 60%;
        }

    }

    &__hero-title {
        color: white;
        font-size: 28px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: -0.84px;

        @media (min-width: $tablet) {
            font-size: 40px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -1.2px;
        }

    }

    &__hero-description {
        display: none;
        font-size: 28px;
        color: white;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.84px;

        @media (min-width: $tablet) {
            display: block;
        }
    }

    &__hero-disclaimer {
        display: none;
        color: white;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;

        @media (min-width: $tablet) {
            display: block;
        }
    }

    .standard-hero__wrapper {
        margin-bottom: 0px;
    }
}