.home-page-hero {
    overflow: hidden;

    &__wrapper {
        overflow: hidden;
    }

    &__inner {
        isolation: isolate;
        overflow: hidden;
        height: 400px;
        background-color: $slate;
        position: relative;
        background-size: cover;
        background-position: center;

        @media (min-width: $tablet) {
            height: 680px;
        }
    }

    &__actions {
        display: none;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-top: 1px solid white;
        width: 100%;
        border-color: rgba(222, 227, 231, 0.35);

        @media (min-width: $desktop) {
            display: flex;
            flex-direction: row;
        }
    }

    &__actions--mobile {
        // @include resetGutters();

        @media (min-width: $desktop) {
            display: none;
        }
    }

    &__action {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $space-22 calc($gutter-md + $space-12);
        border-bottom: 1px solid $omm-grey-soft-3;

        @media (min-width: $desktop) {
            display: block;
            border-right: 1px solid white;
            border-color: rgba(222, 227, 231, 0.35);
            padding: $space-md calc($gutter-md + $space-12);
        }

        &-title {
            color: $omm-dark-blue-01;
            font-size: 12px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0.5px;
            text-align: left;
            text-transform: uppercase;

            @media (min-width: $desktop) {
                color: white;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: 0.32px;
                text-align: center;
                text-transform: uppercase;
            }
        }

        a {
            display: block;
            color: $omm-blue;
            font-size: 20px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.71px;
            text-align: left;
            text-decoration: none;

            @media (min-width: $desktop) {
                display: block;
                color: white;
                font-size: 24px;
                font-weight: 100;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.72px;
                text-align: center;
            }
        }

        i {
            color: $omm-blue;
            font-size: 24px;

            @media (min-width: $desktop) {
                display: none;
            }
        }
    }

    &__content {
        position: relative;
        align-self: stretch;
        margin: 0 auto;
        height: 100%;
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        z-index: 900;
        background-color: rgba(0, 0, 0, 0.35);

        @media (min-width: $tablet) {
            @include justify-content(center);
            @include align-items(center);
            height: 100%;
            width: 100%;
        }
    }

    &__content-push {
        padding-top: 144px;

        @media (min-width: $desktop) {
            padding-top: 141px;
        }
    }

    &__content-bottom {
        width: 80%;
        color: #fff;
        min-height: 144px;

        @media (min-width: $desktop) {
            width: 65%;
            min-height: 141px;
        }
    }

    &__content-bottom p {
        text-align: center;
        font-size: 14px;

        @media (min-width: $desktop) {
            font-size: 19px;
        }
    }

    &__content-bottom a {
        color: #fff;
        text-decoration: underline;
        font-size: 14px;

        @media (min-width: $desktop) {
            font-size: 19px;
        }
    }

    &__content-top {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-bottom: 31px;
        @include justify-content(center);
        @include align-items(center);


        @media (min-width: $tablet) {
            padding-bottom: 0px;
            @include justify-content(center);
            @include justify-content(center);
            height: 100%;
            width: 100%;
        }
    }

    &__title {
        color: white;
        font-size: 28px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        text-align: center;
        line-height: 1.14;
        margin-bottom: $space-12;

        @media (min-width: $tablet) {
            font-size: 40px;
            font-stretch: normal;
            letter-spacing: -1.2px;
        }
    }

    &__description {
        font-size: 24px;
        color: $omm-blue;
        font-weight: 300;
        line-height: 1.29;
        letter-spacing: -0.84px;
        text-align: center;

        @media (min-width: $tablet) {
            font-size: 28px;
            color: white;
        }
    }

    &__disclaimer {
        color: $slate-dark;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        text-align: center;

        @media (min-width: $tablet) {
            color: white;
        }
    }

    &__maincontent {
        flex-direction: column;
        justify-content: flex-start;
        align-self: stretch;

        @media (min-width: $tablet) {
            display: flex;
            justify-content: center;
        }

        &--mobile {
            display: flex;

            @media (min-width: $tablet) {
                display: none;
            }

            .standard-hero__description {
            }
        }
    }

    &__primary-action {
        text-align: center;
    }

    &__primary-action a {
        @extend .button;
        display: inline-flex;
    }

    &__video {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        width: 101%;
        height: 101%;
        position: absolute;
        top: 0;
        left: 0;
        max-width: none;
        z-index: 1;

        &::after {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;
        }
    }

    &__video-player {
        z-index: 2;
        display: block;
        object-fit: cover;
        width: 100%;
        height: 125%;
        position: absolute;
        top: -88px;
        left: 0;
        max-width: none;

        @media (min-width: $tablet) {
            top: -106px;
        }

        @media (min-width: $desktop) {
            top: -166px;
        }
    }
}

.container .home-page-hero__inner {
    border-radius: 10px;
}

.container .home-page-hero__wrapper {
    border-radius: 10px;
    @include guttersSmall();
}