.video-button {
    width: 100%;
    align-self: stretch;
    cursor: pointer;

    &__icon {
        width: 68px;
        height: 68px;
        border-radius: 68px;
        background-color: rgba(255, 255, 255, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: calc(50% - 34px);
        left: calc(50% - 34px);

        @media (min-width: $tablet) {
            width: 128px;
            height: 128px;
            top: calc(50% - 64px);
            left: calc(50% - 64px);
        }

        i {
            color: white;
            font-size: 32px;
            text-align: center;
            margin-left: 5px;

            @media (min-width: $tablet) {
                font-size: 62px;
            }
        }
    }
}