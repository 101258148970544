.search-results {
    padding: 0px 0px 12px 0;

    @media (min-width: $tablet) {
        padding: 0px 0px 30px;
    }

    &__page-title {
        font-size: 28px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        text-align: left;
        color: $omm-blue;
        margin-bottom: 7px;
        padding-left: 18px;
        padding-top: 36px;

        @media (min-width: $tablet) {
            font-size: 40px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: center;
            margin-bottom: 19px;
            padding-left: 0px;
            padding-top: 72px;
        }
    }

    &__results {
        padding-top: 0;

        @media (min-width: $tablet) {
            padding-top: 0;
        }

        .professionals__wrapper {
            padding-left: 0;
            padding-right: 0;
        }

        &--no-padding {
            padding-top: 0;

            @media (min-width: $tablet) {
                padding-top: 0;
            }

            .search-results__section-body--professionals {
                margin-bottom: 0px;
            }
        }

        .pagination {
            padding-top: 0;
            padding-bottom: $space-32;

            @media (min-width: $tablet) {
                padding-top: 0;
                padding-bottom: $space-72;
            }
        }
    }

    &__form {
        position: relative;
    }

    &__input-wrapper {
        padding: 0px 8px;
        position: relative;
        max-width: 956px;
        margin: 0 auto;

        input[type="text"] {
            height: 42px;
            border-radius: 30px;
            border: 1px solid $soft-blue-03;
            padding: 0px 60px 0 21px;
            display: block;
            width: 100%;
            background-color: $soft-blue-02;
            color: $omm-midnight;
            margin-bottom: 12px;
            outline: none;
            transition: background-color 200ms ease-in-out;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            color: $omm-blue;

            @media (min-width: $tablet) {
                padding: 0px 28px;
                height: 60px;
                font-size: 16px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -0.5px;
                text-align: left;
                color: black;
            }

            &:focus {
                background-color: $soft-blue-03;
                outline: none;
            }
        }

        button {
            background-color: transparent;
            border: none;
            color: $omm-blue;
            font-size: 16px;
            height: 40px;
            width: 40px;
            right: 20px;
            top: 1px;
            position: absolute;
            cursor: pointer;
            transition: color 200ms ease-in-out;

            &:hover,
            &:focus {
                color: $omm-blue;
            }

            @media (min-width: $tablet) {
                height: 60px;
                font-size: 20px;
            }
        }

        &--small {
            @media (min-width: $tablet) {
                input[type="text"] {
                    height: 42px;
                }

                button {
                    height: 44px;
                    font-size: 18px;
                }
            }
        }
    }

    &__count {
        max-width: 956px;
        margin: 0 auto;
        padding: 0px 12px;
        margin-bottom: 19px;
        display: none;

        @media (min-width: $tablet) {
            display: block;
            padding: 0px 40px;
            margin-bottom: 72px;

        }

        p {
            font-size: 12px;
            line-height: 1.5;
            letter-spacing: -0.24px;
            text-align: center;
            color: $slate;

            @media (min-width: $tablet) {
                font-size: 12px;
                letter-spacing: -0.36px;
                text-align: left;
            }
        }
    }

    &__filter-bar {
        border-top: 1px solid $soft-grey-03;
        border-bottom: 1px solid $soft-grey-03;
    }

    &__filter-bar-inner {
        .container {
            height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 12px;

            @media (min-width: $tablet) {
                padding: 0px 12px;
            }

            @media (min-width: $desktop) {
                padding: 0px 0px;
            }
        }

    }

    &__filters-wrapper {
        flex: 1;
        align-self: stretch;
        border-right: 1px solid $soft-grey-03;

        @media (min-width: $tablet) {
            border-right: none;
        }
    }

    &__filters-toggle {
        padding: 0px 11px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        cursor: pointer;

        @media (min-width: $tablet) {
            justify-content: flex-start;
        }

        @media (min-width: $desktop) {
            padding-left: 0;
        }

        &--mobile {
            @media (min-width: $tablet) {
                display: none;
            }
        }
    }

    &__filters-title {
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        color: $slate;
        margin-right: 12px;
    }

    &__filters-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        font-size: 16px;
        letter-spacing: -0.36px;
        text-align: left;
        color: $omm-blue;

        @media (min-width: $tablet) {
            // display: none;
        }
    }

    &__filters-card {
        display: none;
        margin: 19px 12px 0px 12px;
        border: 1px solid $soft-grey-03;
        padding: 16px;
        border-radius: 5px;

        @media (min-width: $desktop) {
            margin: 19px 0px 0px 0px;
        }

        &--open {
            display: block;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

        }
    }

    &__filters-form {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 0 12px;
        margin-bottom: 16px;

        @media (min-width: $tablet) {
            justify-content: flex-start;
            // display: block;
        }

    }

    &__filters-actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0 12px;
        justify-content: flex-start;
        width: 100%;
        margin-top: 10px;

        @media (min-width: $tablet) {
            justify-content: flex-start;
            width: auto;
        }

        @media (min-width: $laptop) {
            justify-content: flex-start;
            margin-top: 0;
        }
    }

    &__toggle-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        padding-left: 12px;

        @media (min-width: $tablet) {
            padding: 0 33px;
            border-left: 1px solid $soft-grey-03;
            border-right: 1px solid $soft-grey-03;
        }

        span {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            color: $slate;
        }

        .toggle {
            margin: 0 13px;

            @media (min-width: $tablet) {
                margin: 0 54px;
            }
        }
    }

    &__section {

        .professionals__container {
            min-height: unset;

            @media (min-width: $tablet) {}
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 16px;
            height: 30px;
            margin: 0px 0px 0px 0px;
            border-radius: 5px;
            background-color: $soft-blue-02;

            &--no-radius {
                border-radius: 0px;
            }
        }

        &-title {
            font-size: 12px;
            line-height: 1.1;
            letter-spacing: 0.12px;
            text-align: left;
            text-transform: uppercase;
            color: $omm-blue;
        }

        &-view {
            display: none;
            font-size: 12px;
            letter-spacing: -0.36px;
            text-align: right;
            color: $omm-blue;
            text-decoration: underline;

            @media (min-width: $tablet) {
                display: block;
            }

            a {
                font-size: 12px;
                letter-spacing: -0.36px;
                text-align: right;
                color: $omm-blue;
                text-decoration: underline;
            }

            &--mobile {
                display: flex;
                justify-content: center;
                width: 100%;
                padding: $space-xs 0 $space-md;

                .button {
                    display: inline-flex;
                    align-self: center;
                    min-width: 113px;
                }

                @media (min-width: $tablet) {
                    display: none;
                }
            }
        }

        &-body {
            &--professionals {
                display: flex;
                flex-direction: row;
                width: auto;
                overflow-x: scroll;
                margin-top: 11px;
                gap: 16px;
                padding: 0px 0px 0px 0px;

                /* width */
                &::-webkit-scrollbar {
                    width: 2px;
                    display: none;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background-color: $omm-blue-soft-1;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    width: 2px;
                    height: 2px;
                    opacity: 0.64;
                    background-color: $slate;
                }

                @media (min-width: $laptop) {
                    margin-top: 27px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    overflow-x: hidden;
                    width: 100%;
                }
            }

            &--articles,
            &--insights {
                padding: 18px 0px 0px 0px;

                // @media (min-width: $tablet) {
                //     padding: 19px 0px 0px 16px;
                // }

                &:last-child.search-result__article {
                    border: none;
                }
            }

            &--articles-row {
                padding: 11px 0px 24px 0px;

                @media (min-width: $tablet) {
                    width: auto;
                    @include flexbox();
                    @include align-items(flex-start);
                    flex-wrap: wrap;
                    gap: 16px;
                    padding: 15px 0px 24px 0px;

                    .search-result__article {
                        width: 25%;
                        min-width: 260px;
                        max-width: 308px;
                        border-bottom: 0;
                        flex: 1;
                        padding-right: 45px;
                        text-align: left;
                    }
                }
            }

            &--services {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 0 8px;
                width: 100%;
                margin-top: 19px;
                padding: 0px 0px 0px 0px;


                @media (min-width: $tablet) {
                    gap: 0 10px;
                }

                @media (min-width: $desktop) {
                    gap: 0 10px;
                }

                &>div {
                    margin: 0;
                }
            }

            &--locations {
                display: flex;
                flex-direction: row;
                width: auto;
                overflow-x: scroll;
                margin-top: 11px;
                margin-bottom: 24px;
                padding: 0px 0px 0px 0px;
                gap: 0 16px;

                /* width */
                &::-webkit-scrollbar {
                    width: 2px;
                    display: none;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background-color: $omm-blue-soft-1;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    width: 2px;
                    height: 2px;
                    opacity: 0.64;
                    background-color: $slate;
                }

                @media (min-width: $tablet) {
                    margin-top: 27px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    overflow-x: none;
                    gap: 0 16px;
                }

                .locations-card {
                    // width: 100%;
                    // min-width: 100%;

                    // @media (min-width: $tablet) {
                    //     flex: 0.5;
                    // }
                }
            }

            &--pages {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;

        .search-results__section {
            width: 100%;
            margin-bottom: 32px;
        }

        @media (min-width: $laptop) {
            .search-results__section {
                flex: 1;
                max-width: 34%;
                margin-bottom: 72px;
                padding: 0;

                &:first-child {
                    padding-left: 16px;
                }

                &:last-child {
                    padding-right: 16px;
                }
            }
        }

        @media (min-width: $desktop) {
            .search-results__section {
                padding: 0;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 $space-xxs;
        gap: 16px;

        .search-results__section {
            padding: 0 !important;
            width: 100%;
        }

        @media (min-width: $tablet) {
            .search-results__section {
                flex: 1;
                margin-bottom: 0;
            }
        }

        @media (min-width: $desktop) {
            padding: 0;
        }
    }

    &__no-results {
        font-size: 40px;
        font-weight: 300;
        line-height: 1.15;
        letter-spacing: -1px;
        text-align: center;
        color: $slate;
    }
}

.search-result {
    &__professional {
        width: 308px;
        min-width: 308px;
        min-height: 326px;
        display: flex;
        flex-direction: column;
        margin: 0;
        border-radius: 5px;
        border: solid 1px $soft-grey-03;
        background-color: white;
        overflow: hidden;

        @media (min-width: $laptop) {
            min-width: unset;
            width: calc(25% - 12px);
        }

        &:last-child {
            margin-right: 0;
            margin-bottom: 0;
        }

        &--heading {
            @media (min-width: $laptop) {
                flex: 1;
            }
        }

        &-details {
            padding: 19px 21px;
            flex: 1;
            @include flexbox();
            @include justify-content(space-between);
            @include flex-direction(column);
        }

        &-link {
            margin-bottom: 0;

            a {
                font-size: 14px;
                line-height: 1.29;
                letter-spacing: -0.5px;
                color: $omm-blue;
                font-weight: 400;
                text-decoration: underline;
                cursor: pointer;
                transition: all 200ms ease-in-out;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }

        &-left {
            display: none;
            width: 90px;
            padding: 8px 16px 8px 8px;

            @media (min-width: $tablet) {
                display: none;
            }
        }

        &-avatar {
            border-radius: 50%;
            width: 70px;
            height: 70px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin-bottom: 16px;
            display: block;
        }

        &-name {
            font-size: 24px;
            font-weight: 300;
            letter-spacing: -0.72px;
            margin-bottom: 8px;
            color: $omm-blue;
            text-decoration: none;
            transition: all 200ms ease-in-out;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        &-title {
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: -0.28px;
            color: $slate;
            margin-bottom: 16px;
        }

        &-actions {
            @include flexbox();
            @include align-items(center);
            @include justify-content(space-between);
            @include flex-direction(row);
        }

        &-socials {
            @include flexbox();
            @include align-items(center);
            @include justify-content(flex-end);
            width: auto;

            a {
                display: inline-block;
                color: $omm-blue;
                font-size: 16px;
                text-decoration: none;

                // &:not(:last-child) {
                margin-left: 5px;
                margin-right: 5px;
                margin-top: 5px;
                // }

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &-image {
            display: none;
            height: 180px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @media(min-width: $tablet) {
                display: block;
            }
        }

        &-image--mobile {
            height: 180px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @media(min-width: $tablet) {
                display: none;
            }
        }
    }

    &__article,
    &__insight {
        border-top: 1px solid $soft-grey-03;
        display: flex;
        flex-direction: column;
        padding: $space-xxs;
        margin-bottom: 0px;
        text-decoration: none;

        &:last-child {
            border-bottom: none;
        }

        &:hover,
        &:focus {

            .search-result__article-title,
            .search-result__insight-title {
                text-decoration: underline;
                color: $omm-blue;
            }
        }

        &-meta {
            display: flex;
            gap: 0 22px;
            font-size: 12px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            color: $slate;
            margin-bottom: 7px;
        }

        &-title {
            flex: 1;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.2px;
            text-align: left;
            transition: all 200ms ease-in-out;
            text-decoration: none;
            margin-bottom: 0px;
            color: black;

            @media (min-width: $tablet) {
                font-size: 20px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.6px;
            }
        }

        &--small {
            min-height: 50px;
        }
    }

    &__service {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        border-radius: 32px;
        background-color: $omm-blue-soft-1;
        color: $omm-blue;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.24px;
        text-decoration: none;
        padding: 0 15px;
        margin-bottom: 12px;
        transition: background-color 300ms ease-out;

        &:hover,
        &:focus {
            background-color: $omm-blue;
            color: white;
        }

        @media (min-width: $tablet) {
            padding: 0 18px;
        }
    }

    &__page {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 12px 12px;
        text-decoration: none;
        border-bottom: 1px solid $soft-grey-03;

        @media (min-width: $tablet) {
            padding: 14px 14px;
        }

        &:hover,
        &:focus {
            .search-result__page-title {
                text-decoration: underline;
                color: $omm-blue;
            }
        }

        &-title {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.3px;
            text-align: left;
            color: black;
            margin-bottom: 0;
            line-height: 1, 4;
        }

        &-icon {
            font-size: 20px;
            color: $omm-blue;
            margin-right: 16px;
            margin-bottom: 0;

            @media (min-width: $tablet) {
                display: block;
            }
        }
    }

}

.container .search-results__section {
    @include gutters();
}

.container-fluid .search-results__section {
    @include guttersFullWidth();
}