.team-cards {
    @include gutters();
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    &__heading {
        max-width: 600px;
        text-align: center;
        margin: 0px auto 40px;

        &-title {
            color: $omm-blue;
            margin-bottom: 16px;
            text-align: center;
        }

        &-subtitle {
            color: black;
            text-align: center;
        }
    }

    &__button {
        max-width: 260px;
        margin: 35px auto 50px;
    }

    &__wrapper {
        width: 100%;

        @media (min-width: $tablet) {}


    }
}

.team-card {
    margin-bottom: 16px;
    background-color: $soft-blue-02;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    min-width: 320px;
    padding: 30px 30px;
    height: auto;

    @media (min-width: $tablet) {
        @include flexbox();
        max-width: 1060px;
        margin: 16px auto;
    }

    &__container {
        // padding: 22px 30px;

        @media (min-width: $tablet) {
            flex: 1;
            // padding: 22px 40px;
        }
    }

    &__heading {
        padding-top: 13px;

        @media (min-width: $tablet) {
            padding-top: 0px;
            // padding-left: 70px;
            padding-left: 0;
        }
    }

    &__name {
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.83px;
        text-align: left;
        color: $slate-dark;
        margin-bottom: 6px;
        line-height: 1.2;

        @media (min-width: $tablet) {
            color: $omm-blue;
            font-size: 28px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
            margin-bottom: 4px;
        }
    }

    &__image {
        width: 70px;
        height: 70px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50%;
        margin-right: 30px;
        // position: absolute;
        // top: 30px;
        // left: 30px;

        @media (min-width: $tablet) {
            width: 80px;
            height: 80px;
            // width: 230px;
            // height: auto;
            // position: relative;
            // border-radius: 0;
            // left: 0;
            // top: 0;
        }
    }

    &__title {
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        color: black;

        @media (min-width: $tablet) {
            color: $slate;
        }
    }

    &__content {
        @extend p;

        @media (min-width: $tablet) {
            font-size: 16px;
            line-height: 1.3;
        }
    }

    &__link {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.42px;
        color: $omm-blue;

        &:hover {
            text-decoration: none;
        }
    }

    &__read-more-content {
        display: none;

        &--open {
            display: block;
        }
    }
}