.info-card {
    @include flexbox();
    @include justify-content(space-between);
    @include flex-direction(column);
    @include align-items(flex-start);
    padding: 22px 30px 30px 22px;
    margin-bottom: 16px;
    border-radius: 10px;
    width: 100%;
    color: $slate;
    text-decoration: none;

    @media (min-width: $tablet) {
        flex: 1;
    }

    &__content {
        flex: 1;
        margin-bottom: 12px;
    }

    &:last-child {
        margin-bottom: 0;
        border-bottom: 1px;
    }

    &:nth-child(1n) {
        background-color: $omm-blue-soft-1;
    }

    &:nth-child(2n) {
        background-color: $omm-blue-soft-2;
    }

    &:nth-child(3n) {
        background-color: $omm-blue-soft-3;
    }

    &:nth-child(4n) {
        background-color: $omm-blue-soft-3;
    }

    &__super-title {
        color: $omm-darkblue-1;
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: 0.5px;
        margin-bottom: $space-xxxs;
        text-align: left;
        text-transform: uppercase;
    }

    &__subtitle {
        color: $omm-darkblue-1;
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: 0.5px;
        text-align: left;
        text-transform: uppercase;

        @media (min-width: $tablet) {
            margin-bottom: 16px;
        }
    }

    &__title {
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.71px;
        text-align: left;
        color: $omm-dark-blue-01;
        margin-bottom: 8px;

        @media (min-width: $desktop) {
            font-size: 28px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
        }

    }

    &--blue {
        .info-card__title {
            color: $omm-dark-blue-01;
        }
    }

    &--small {
        min-height: 120px;

        @media (min-width: $desktop) {
            min-height: 120px;
            padding-top: 30px;
            padding-left: 30px;

            .info-card__title {
                font-size: 20px;
                font-weight: 300;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: -0.6px;
            }
        }
    }
}