.navigation-professionals {
    width: 100%;
    padding: 0px 23px;

    @media (min-width: 1024px) {
        padding: 25px;
    }

    &__filter {
        min-width: 250px;
        margin-bottom: $space-xxs;

        .news-block__cards-filter {
            max-width: none;
        }
    }

    &__cards-filter {
        min-width: 158px;
        max-width: 30%;
        position: relative;
        border-radius: 45px;
        background-color: white;
        border: solid 1px $soft-grey-03;
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: -0.28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        cursor: pointer;

        i {
            color: $omm-blue;
            padding: 0px 14px;
            position: absolute;
            right: 0;
            top: 0;
            height: 33px;
            line-height: 33px;
            vertical-align: middle;
            pointer-events: none;
        }

        select {
            cursor: pointer;
            border-radius: 45px;
            padding: 7px 24px 7px 14px;
            background-color: white;
            border-color: white;
            font-size: 14px;
            font-weight: 300;
            border: none;
            flex: 1;
            appearance: none;
            -webkit-appearance: none;
            width: 100%;
            text-overflow: ellipsis;
            color: $slate;

            &:hover,
            &:focus {
                cursor: pointer;
                outline: none;
            }
        }
    }

    &__input-wrapper {
        cursor: pointer;
        overflow: hidden;
        position: relative;
        width: 100%;

        input[type="text"] {
            background-color: $soft-blue-01;
            border: solid 1px $soft-grey-03;
            border-radius: 50px;
            color: $omm-dark-blue-01;
            font-size: 14px;
            font-weight: 300;
            line-height: 1.3;
            // needed height due to FireFox quirk with input height
            height: 36px;            
            padding: $space-xxxs $space-sm $space-xxxs $space-xxs;
            transition: background-color 200ms ease-in-out;
            width: 100%;

            &::placeholder {
                color: $omm-dark-blue-01;
                opacity: 1;
            }

            &:focus {
                outline: none;
            }
        }

        button {
            background-color: transparent;
            border: none;
            color: $omm-blue;
            cursor: pointer;
            font-size: 18px;
            line-height: 0;
            padding: 0 $space-xxs;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: color 200ms ease-in-out;

            &:hover,
            &:focus {
                color: $omm-blue;
            }
        }
    }
}