.events-slider {

    @media (max-width: $tablet) {
        border-bottom: 1px solid $soft-grey-03;
    }

    &__header {
        padding: 34px 24px 22px 24px;
        text-align: center;
        max-width: 679px;
        margin: 0 auto;

        h2 {
            font-size: 28px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: -0.84px;
            color: $omm-blue;
            text-align: center;
            margin-bottom: 16px;

            @media (min-width: $tablet) {
                font-size: 40px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -1.2px;
                margin-bottom: 16px;
            }
        }

        p {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: center;
        }
    }

    &__slider {

        .swiper-button-next,
        .swiper-button-prev {
            height: 50%;
            top: 25%;
            width: 48px;

            &:after {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 19px;
                background-color: rgba(0, 0, 0, 0.25);
                width: 40px;
                height: 40px;
                border-radius: 40px;
            }
        }

        .swiper-button-next {
            display: none;
            right: 12px;

            @media (min-width: $tablet) {
                display: flex;
            }
        }

        .swiper-button-prev {
            display: none;
            left: 12px;

            @media (min-width: $tablet) {
                display: flex;
            }
        }

        .swiper-pagination-bullet {
            background: transparent;
            background-color: $soft-grey-03;
            opacity: 1;
            width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
            height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));

            &-active {
                border: 1px solid $omm-blue;
                background: $omm-blue;
            }
        }

        .swiper-pagination {

            @media (min-width: $tablet) {
                text-align: right;
                padding: 0 87px;
                bottom: 47px !important;
                display: none;
            }
        }
    }

    &__slide {
        background-color: $slate;
        background-position: center;
        background-size: cover;

        &-gradient {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
        }

        .container {
            height: 450px;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
        }

        @media (min-width: $tablet) {
            .container {
                align-items: flex-start;
                height: 680px;
                padding: 47px;
            }
        }

        @media (min-width: $laptop) {
            .container {
                height: 680px;
                padding: 47px;
            }
        }

        @media (min-width: $desktop) {
            .container {
                height: 680px;
                padding: 47px;
            }
        }

        &-supertitle {
            font-size: 12px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: 0.5px;
            text-align: left;
            text-transform: uppercase;
            color: $omm-dark-blue-01;
            margin-bottom: 10px;

            @media (min-width: $tablet) {
                color: white;
                font-size: 12px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: 0px;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 8px;
            }
        }

        &-title {
            font-size: 20px;
            line-height: 1.2;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.71px;
            text-align: left;
            color: $omm-blue;
            margin-bottom: 8px;

            @media (min-width: $tablet) {
                color: white;
                font-size: 28px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -1px;
                text-align: left;
                margin-bottom: 16px;
            }
        }

        &-caption {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            color: black;
            margin-bottom: 16px;

            @media (min-width: $tablet) {
                color: white;
                font-size: 14px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -0.5px;
                text-align: left;
                max-width: 738px;
                margin-bottom: 0;
            }

            &-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                @media (min-width: $tablet) {
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;
                }

                @media (min-width: $desktop) {
                    align-items: flex-end;
                }
            }
        }

        &-title,
        button {
            z-index: 7000;
        }

        .button {
            min-width: 130px;
        }
    }

    &__mobile-image {
        min-height: 177px;
        flex: 1;
        width: 100%;
        background-position: center;
        background-size: cover;

        @media (min-width: $tablet) {
            display: none;
        }
    }

    &__slide-content {
        width: 100%;
        background-color: white;
        padding: 22px;

        @media (min-width: $tablet) {
            background-color: transparent;
            padding: 0px;
        }
    }

    .video-button__icon {
        top: calc(25% - 34px);

        @media (min-width: $tablet) {
            top: calc(50% - 64px);
        }
    }

    .swiper {
        border-radius: 0;

        @media (min-width: $desktop) {
            border-radius: 10px;
        }
    }
}

.container-fluid .events-slider .swiper {
    border-radius: 0;
}