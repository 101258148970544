.news-block {
    min-height: 238px;
    @include flexbox();
    @include justify-content(space-between);
    @include flex-direction(column);
    @include align-items(flex-start);
    margin-bottom: 16px;
    border-radius: 10px;
    width: 100%;
    // flex: 1;
    color: $omm-dark-blue-02;
    text-decoration: none;
    background-position: top center;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__no-results {
        font-size: 40px;
        font-weight: 300;
        line-height: 1.15;
        letter-spacing: -1px;
        text-align: center;
        color: $slate;
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    &__inner {
        border-radius: 10px;
        // min-height: 100% !important;
        // height: 100%;
        width: 100%;
        align-self: stretch;
        padding: 22px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex: 1;

        @media (min-width: $desktop) {
            padding: 26px;
        }

        &-with-title {
            .news-block__title {
                @media (min-width: $tablet) {
                    max-height: 95px;
                }
            }
        }
    }

    &__content {
        flex: 1;
    }

    &__category {
        font-weight: bold;
    }

    &__title {
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.71px;
        text-align: left;
        line-height: 1.3;
        margin-bottom: 11px;
        flex: 1;
        min-width: 40%;
        color: $omm-dark-blue-01;
        overflow: hidden;

        @media (min-width: $desktop) {
            color: $omm-dark-blue-02;
            font-size: 28px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
            margin-bottom: 16px;
        }

        &--blue {
            font-size: 28px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: -0.84px;
            color: $omm-blue;
        }
    }

    &__meta {
        color: $omm-dark-blue-01;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        width: 100%;
        display: flex;
        align-items: flex-end;

        span:not(:last-child) {
            margin-right: 16px;
        }
    }

    &__meta-text {
        color: $omm-dark-blue-01;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        flex: 1;

        @media (min-width: $desktop) {
            color: $omm-dark-blue-02;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            margin-bottom: 0px;
        }
    }

    &__meta-icon {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: rgba(1, 109, 229, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 200ms ease-in-out;

        i {
            color: $omm-blue;
            font-size: 23px;
        }

        &:hover,
        &:focus {
            background-color: rgba(1, 109, 229, 0.2);
        }
    }

    &__container {
        @include flexbox();
        @include justify-content(stretch);
        @include align-items(stretch);
        @include flex-direction(row);
        flex-wrap: wrap;
        width: 100%;

        &--events {
            @include flex-direction(column);
            gap: $space-xxs;

            @media (min-width: $tablet) {
                @include flex-direction(row);
            }

            .news-block__section {
                max-width: none;
                width: auto;

                &-inner {
                    padding-top: $space-xs;

                    @media (min-width: $tablet) {
                        padding-top: $space-sm;
                    }

                    @media (min-width: $desktop) {
                        padding-top: $space-md;
                    }
                }

                &:last-of-type {
                    background-color: $omm-blue-soft-1;
                    border-radius: 30px;
                    margin: 0;
                    max-width: none;

                    .news-block__section-inner {
                        padding: $space-xs;

                        @media (min-width: $tablet) {
                            padding: $space-sm;
                        }

                        @media (min-width: $desktop) {
                            padding: $space-md;
                        }
                    }

                    h2 {
                        @extend .insights__section-title;
                    }

                    h3 {
                        color: $omm-blue;
                        font-size: 24px;
                        font-weight: 300;
                        font-style: normal;
                        letter-spacing: -0.86px;
                        text-align: left;
                        margin-bottom: 9px;

                        @media (min-width: $desktop) {
                            font-size: 28px;
                            font-weight: 300;
                            letter-spacing: -0.84px;
                        }
                    }

                    p {
                        &+h3 {
                            margin-top: $space-lg;
                        }
                    }
                }
            }

            .news-block__no-results {
                min-height: 30vh;
            }

            .news-block__button {
                @media (min-width: $tablet) {
                    margin-bottom: 0;
                }
            }

            .search-result__article-title {
                font-size: 16px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -0.2px;
                margin-bottom: 16px;
                text-align: left;
            }
        }
    }

    &__section {
        width: 100%;

        @media (min-width: $tablet) {
            flex: 1;
            @include flexbox();
            @include flex-direction(column);
            @include align-items(stretch);
            @include justify-content(stretch);
            max-width: 60%;
        }

        &:last-child {
            @media (min-width: $tablet) {
                max-width: 40%;
                margin-left: 16px;
            }

            .news-block {

                &:nth-child(odd) {
                    background-color: $omm-blue-soft-3;
                }

                &:nth-child(even) {
                    background-color: $omm-blue-soft-1;
                }

                @media (min-width: $desktop) {

                    &:nth-child(odd) {
                        background-color: $omm-blue-soft-1;
                    }

                    &:nth-child(even) {
                        background-color: $omm-blue-soft-3;
                    }
                }
            }
        }

        .news-block {
            transition: all 200ms ease-in-out;

            &:hover,
            &:focus {
                background-color: darken($omm-blue-soft-3, 5%) !important;
            }
        }

        &--left {
            .news-block {
                &:nth-child(odd) {
                    background-color: $omm-blue-soft-1;
                }

                &:nth-child(even) {
                    background-color: $omm-blue-soft-3;
                }

                @media (min-width: $desktop) {

                    &:nth-child(1),
                    &:nth-child(2) {
                        @media (min-width: $tablet) {
                            min-height: 300px;
                            flex: 1;
                        }
                    }

                    &:nth-child(2) {
                        @media (min-width: $tablet) {
                            // margin-bottom: 0;
                        }
                    }
                }
            }
        }

        &--right {
            .news-block {
                &:nth-child(odd) {
                    background-color: $omm-blue-soft-2;
                }

                &:nth-child(even) {
                    background-color: $omm-blue-soft-3;
                }

                @media (min-width: $desktop) {
                    min-height: 200px;
                }
            }
        }
    }

    &__button {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 0 $space-xxxl;
        gap: $gutter-sm;

        a {
            display: inline-flex;
        }
    }

    &__grid-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: $space-xs;

        @media (min-width: $tablet) {
            margin-right: 0px;
            gap: 0 20px;
            margin-top: $space-sm;
        }
    }

    &__grid-item {
        padding: 16px 16px 16px 16px;
        border-top: 1px solid $soft-grey-03;
        text-decoration: none;
        width: 100%;
        display: block;

        @media (min-width: $desktop) {
            padding: 16px 16px 16px 0px;
            width: 23.8%;
        }

        &:hover,
        &:focus {
            .news-block__grid-title {
                color: $omm-blue;
                text-decoration: underline;
            }
        }
    }

    &__grid-meta {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.36px;
        margin-bottom: 11px;
        color: $slate;

        span {
            &:not(:last-child) {
                padding-right: 16px;
            }
        }
    }

    &__grid-title {
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: -0.4px;
        margin-bottom: 16px;
        color: black;

        @media (min-width: $desktop) {
            padding-right: 42px;
        }
    }

    &__cards-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: $space-xs;

        @media (min-width: $laptop) {
            margin-bottom: $space-sm;
        }

        .news-block__title {
            flex: 1;
        }
    }

    &__cards-filters {
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        display: none;

        @media (min-width: $tablet) {
            display: flex;
        }
    }

    &__cards-filter {
        cursor: pointer;
        max-width: 160px;
        width: 100%;
        overflow: hidden;
        position: relative;

        @media (min-width: $laptop) {
            max-width: 200px;
        }

        @media (min-width: $laptop) {
            max-width: 240px;
        }

        i {
            color: $omm-blue;
            font-size: 18px;
            padding: 0px $space-xxs;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        select {
            appearance: none;
            -webkit-appearance: none;
            background-color: white;
            border: solid 1px $soft-grey-03;
            border-radius: 50px;
            color: $slate;
            cursor: pointer;
            font-size: 14px;
            font-weight: 300;
            line-height: 1.3;
            height: 100%;
            padding: $space-xxxs $space-sm $space-xxxs $space-xxs;
            text-overflow: ellipsis;
            width: 100%;

            &:hover,
            &:focus {
                cursor: pointer;
                outline: none;
            }
        }
    }

    &--image {
        color: white;

        .news-block__inner {
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
        }

        .news-block__title {
            color: white;
        }

        .news-block__meta-text {
            color: white;
        }

        .news-block__meta-icon {
            background-color: rgba(255, 255, 255, 0.14);

            i {
                color: white;
            }

            &:hover,
            &:focus {
                background-color: rgba(255, 255, 255, 0.24);
            }
        }
    }

    &__avatar-image {
        width: 86px;
        height: 86px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: $omm-blue;
        border-radius: 50%;
        margin-bottom: 18px;
    }

    &__avatars {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;
        flex: 1;
    }

    &__avatars {
        .news-block__avatar-image {
            width: 55px;
            height: 55px;
            margin-bottom: 0px;
        }
    }
}

.news-block-item {
    &:not(:last-child) {
        margin-bottom: 33px;

        @media (min-width: $desktop) {
            margin-bottom: 50px;
        }
    }

    &:first-of-type {
        margin-top: 24px;

        @media (min-width: $desktop) {
            margin-bottom: 50px;
        }
    }

    &__title {
        font-size: 24px;
        line-height: 1.17;
        letter-spacing: -0.72px;
        margin-bottom: 10px;
        color: $omm-blue;
        font-stretch: normal;
        font-style: normal;
        font-weight: 300;

        @media (min-width: $desktop) {
            font-size: 28px;
            line-height: 1.14;
            letter-spacing: -0.84px;
        }
    }

    p {
        color: $slate-dark;

        a {
            color: $slate-dark;

            &:hover {
                color: $omm-blue;
            }
        }
    }
}