.swiper {
    &__container {
        @extend .container;
        position: relative;

        @media (min-width: $laptop) {
            margin: 0 $gutter-md;
        }

        @media (min-width: $desktop) {
            margin: 0 auto;
        }
    }

    &__button {
        background: white;
        border: 1px solid $soft-grey-03;
        border-radius: 50px;
        box-shadow: 0 4px 4px 0 rgba($soft-grey-03, 0.6);
        cursor: pointer;
        display: none;
        height: 40px;
        margin: 0;
        padding: 0;
        position: absolute;
        top: calc(50% - ((2 * $space-sm + 1px + $space-xxs) - 2px));
        transform: translateY(50%);
        width: 40px;

        &--next {
            right: 0;
            text-indent: 2px;

            &::after {
                content:"next";
            }
        }

        &--prev {
            left: 0;
            text-indent: -2px;

            &::after {
                content:"prev";
            }
        }

        &::after {
            color: $omm-blue;
            font-family: swiper-icons;
            font-size: 18px;
            line-height: 38px;
            height: 100%;
            left: 0;
            position: absolute;
            text-align: center;
            top: 0;
            width: 100%;
        }

        span {
            clip: rect(1px, 1px, 1px, 1px);
            height: 0;
            overflow: hidden;
            position: absolute;
            top: auto;
            white-space: nowrap;
            width: 0;
        }

        @media (min-width: $laptop) {
            display: block;
        }
    }

    &__button.swiper-button-disabled {
        cursor: auto;
        opacity: 0.35;
        pointer-events: none;;
    }
}


