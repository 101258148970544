.loading-indicator,
.error-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 100%;
    gap: 5px;

    span {
        color: $slate;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -0.28px;
    }
}

.spinner {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
}

.spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 2px;
    border: 2px solid $slate;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $slate transparent transparent transparent;
}

.spinner div:nth-child(1) {
    animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
    animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}