.search-autocomplete {
    position: absolute;
    top: 42px;
    left: 21px;
    right: 21px;
    width: calc(100% - 42px);
    background: white;
    border-radius: 10px;
    border: 1px solid $omm-blue-soft-3;
    border-top-width: 0;
    box-shadow: 1px 3px 11px 0 rgba(0, 0, 0, 0.1);
    z-index: 99999;

    @media (min-width: $tablet) {
        left: 28px;
        right: 28px;
        width: calc(100% - 56px);
        top: 60px;
    }

    &__suggestion {
        cursor: pointer;
        padding: 8px 8px;
        color: $slate;
        text-decoration: none;
        border-bottom: 1px solid $omm-blue-soft-3;

        &:last-child {
            border-bottom: 0px solid $omm-blue-soft-3;
        }

        &:hover,
        &:focus {
            color: $omm-blue;
            text-decoration: none;
        }
    }
}

.navigation-search {
    .search-autocomplete {
        left: 18px;
        right: 18px;
        width: calc(100%);

        @media (min-width: $tablet) {
            left: 18px;
            right: 18px;
            width: calc(100%);
            top: 42px;
        }
    }
}