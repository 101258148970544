.footer {
    background-color: black;
    border-top: 1px solid #2b2e31;
    
    &__main-wrapper {
        border-bottom: 1px solid #2b2e31;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }

        @media (min-width: $tablet) {
            border-bottom: 1px solid #2b2e31;

            .container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: row;
            }
        }
    }

    &__secondary-wrapper {
        @media (min-width: $desktop) {
            border-bottom: 1px solid #2b2e31;
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: row-reverse;
        }
    }

    &__logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        padding: 0 $space-xxs;

        @media (min-width: $tablet) {
            border-right: 1px solid #2b2e31;
        }
    }

    &__logo {
        width: 123px;
        height: 32px;
        background-image: url("/img/footer-logo.webp");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @media (min-width: $tablet) {
            background-position: center;
        }
    }

    &__social-links {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-left: 1px solid #2b2e31;
        padding: 19px $space-xxs;

        @media (min-width: $tablet) {
            order: 3;
        }

        a {
            font-size: 22px;
            color: white;
            text-decoration: none;
            margin-left: 16px;
            &:hover {
                color: $omm-accent-blue;
            }
        }
    }

    &__subscribe {
        padding: 26px 0px;
        border-top: 1px solid #2b2e31;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (min-width: $tablet) {
            padding: 0px 0px;
            flex: 1;
            width: auto;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            border-bottom: 0px solid #2b2e31;
            border-top: 0px solid #2b2e31;
            padding-right: 24px;
        }

        p {
            color: $omm-grey-soft-3;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: -0.36px;
            margin-bottom: 12px;

            @media (min-width: $tablet) {
                margin-bottom: 0px;
                margin-right: 25px;
                font-size: 14px;
                letter-spacing: -0.36px;
            }
        }

        a {
            height: 36px;
            min-width: 135px;
            min-width: 135px;
            @media (min-width: $tablet) {
               min-width: 122px;
            }
        }
    }

    &__copyright {
        padding: $space-xxs;
        color: $omm-grey-soft-3;
        font-size: 12px;
        letter-spacing: 0.12px;
        width: 100%;
        text-transform: uppercase;
        border-bottom: 1px solid #2b2e31;

        @media (min-width: $tablet) {
            padding: 7px $space-xxs;
        }

        @media (min-width: $desktop) {
            width: auto;
            border-bottom: 0px solid #2b2e31;

        }
    }

    &__links {
        padding: $space-xxs $space-xxs 1px $space-xxs;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        flex: 1;

        @media (min-width: $tablet) {
            padding: 7px $space-xxs;
        }

        a {
            color: $omm-grey-soft-3;
            font-size: 12px;
            letter-spacing: 0.12px;
            text-decoration: none;
            text-transform: uppercase;
            margin-bottom: 15px;
            margin-right: 22px;

            @media (min-width: $tablet) {
                margin-bottom: 0px;
            }

            &:hover,
            &:focus {
                color: white;
                text-decoration: underline;
            }
        }
    }
}