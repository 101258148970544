.subscribe-insights {
    &__stack {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &--gap-sm {
            gap: 4px;
        }

        &--gap-lg {
            gap: 48px;
        }
    }

    &__form {
        padding: 24px 0;
    }

    &__label {
        color: $slate;
        display: inline-block;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.3;
        margin-bottom: 4px;

        @media (min-width: $tablet) {
            font-size: 16px;
        }
    }

    &__text {
        background-color: white;
        border: 1px solid $soft-grey-03;
        border-radius: 4px;
        color: rgba(101, 119, 134, 1);
        display: block;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        height: 44px;
        letter-spacing: -0.5px;
        padding: 0px 16px;
        width: 100%;

        &:focus {
            border: solid 1px $soft-grey-03;
            outline: none;
        }
    }

    &__hr {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
    }

    &__select {
        position: relative;
        
        select {
            appearance: none;
            background-color: white;
            border: 1px solid $soft-grey-03;
            border-radius: 4px;
            color: rgba(101, 119, 134, 1);
            cursor: pointer;
            display: block;
            display: block;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            height: 44px;
            letter-spacing: -0.5px;
            padding: 0px 56px 0px 16px;
            width: 100%;
        }

        i {
            color: $omm-blue;
            font-size: 18px;
            pointer-events: none;
            position: absolute;
            right: 22px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__checkbox {
        @extend .checkbox;
        align-items: flex-start;
        padding: 0;

        .checkbox__check {
            margin-top: 3px;
        }

        span {
            color: rgba(101, 119, 134, 1);
            font-weight: 300;

            a {
                color: inherit;

                &:hover {
                    text-decoration: none;;
                }
            }

            @media (min-width: $tablet) {
                font-size: 16px;
            }
        }
    }

    &__select-multi {
        background-color: $soft-blue-01;
        border-radius: 4px;

        summary {
            background-color: $soft-blue-01;
            border-radius: 4px;
            color: $omm-blue;
            cursor: pointer;
            font-size: 20px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.86px;
            list-style: none;
            padding: 16px; 
            position: relative;

            &::-webkit-details-marker {
                display: none;
            }

            i {
                color: $omm-blue;
                font-size: 18px;
                pointer-events: none;
                position: absolute;
                right: 22px;
                top: 50%;
                transform: translateY(-50%);
            }
        
            @media(min-width: $desktop) {
                font-size: 22px;
                letter-spacing: -1.02px;
            }
        }

        div {
            padding: 0 16px 16px;
        }
    }

    &__button {
        @extend .button;

        &--center {
            margin: 0 auto;
        }
    }

    &__link {
        background: transparent;
        border: 0;
        color: rgba(101, 119, 134, 1);
        cursor: pointer;
        font-size: 14px;
        font-weight: 300;
        margin: 0;
        order: 1;
        padding: 0;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

        @media(min-width: $tablet) {
            order: 0;
        }
    }

    &__title {
        color: $omm-blue;
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.86px;
        
        @media(min-width: $desktop) {
            font-size: 22px;
            letter-spacing: -1.02px;
        }
    }

    &__columns {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(4, 1fr);
    }

    &__column {
        background-color: $soft-blue-01;
        border-radius: 4px;
        padding: 16px;
    }

    &__footer {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 0 20px;

        @media(min-width: $tablet) {
            flex-direction: row;
            gap: 64px;
        }
    }

    &__footer-buttons {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 32px;

        @media(min-width: $tablet) {
            
            flex-direction: row;
            gap: 16px;
        }
    }

    &__desktop {
        display: none;

        @media(min-width: $tablet) {
            display: block;
        }
    }

    &__mobile {
        display: block;
        
        @media(min-width: $tablet) {
            display: none;
        }
    }
}