.hub {
    padding: 0px 0px;

    &--dark {
        background-color: $omm-dark-blue-02;

        .hr {
            color: $soft-grey-03;
            border-color: $soft-grey-03;
            opacity: 0.25;
        }

        .alumni-spotlights__card,
        .infographics__card {
            background-color: $omm-dark-blue-01;
            color: white !important;
        }

        .alumni-spotlights__card *,
        .infographics__card * {
            color: white !important;
        }

        .card-carousel__legend,
        .card-carousel__legend-bar {
            background-color: $omm-blue !important;
        }

        .tagged-news {
            color: white !important;

            &__title {
                color: white !important;
            }

            &:nth-child(1) {
                background-color: $omm-blue !important;
            }

            &:nth-child(2) {
                background-color: $omm-dark-blue-01 !important;
            }

            &:nth-child(3) {
                background-color: $omm-blue !important;
            }

            &:nth-child(4) {
                background-color: $omm-dark-blue-01 !important;
            }

            &:nth-child(5) {
                background-color: $omm-blue !important;
            }
        }
    }

}