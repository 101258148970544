.press-room {
    &__content {
        color: $omm-blue;
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.71px;
        text-align: left;
        padding: 25px 16px;

        @media (min-width: $tablet) {
            padding: 35px 16px;
            font-size: 28px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
        }

        @media (min-width: $desktop) {
            padding: 35px 0px;
        }
    }

    &__heading {
        border-bottom: 1px solid $omm-grey-soft-3;
        margin-bottom: 16px;

        &-container {
            padding: 30px 16px 10px;

            @media (min-width: $tablet) {
                padding: 30px 16px 20px;
                @include flexbox();
                @include align-items(flex-end);
                @include justify-content(space-between);
            }

            @media (min-width: $desktop) {
                padding: 30px 0px 20px;
            }
        }
    }

    &__title {
        color: $omm-blue;
        font-size: 28px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        text-align: left;
        line-height: 1;
        margin-bottom: 8px;
        padding: 0 6px;

        @media (min-width: $tablet) {
            font-size: 40px;
            margin-bottom: 0;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
            padding: 0 0px;
        }
    }

    &__contacts {

        @media (min-width: $tablet) {
            display: flex;
            gap: 16px;
        }

        @media (min-width: $desktop) {
            display: flex;
            gap: 16px;
        }
    }

    &__contact {
        background-color: $omm-blue-soft-1;
        border-radius: 10px;
        padding: 20px 24px;
        @include flexbox();
        @include align-items(flex-end);
        width: 100%;
        @include justify-content(space-between);
        margin-top: 12px;

        @media (min-width: $tablet) {
            min-width: 350px;
            max-width: 350px;
            margin-top: 0;
        }

        &-name {
            color: $omm-blue;
            display: block;
            margin-bottom: 2px;
            line-height: 1.2;
            font-size: 16px;
            letter-spacing: -0.48px;
        }

        &-position {
            color: $slate;
            line-height: 20px;
            margin-bottom: 0;
        }

        &-socials {
            @include flexbox();
            @include align-items(center);
            @include justify-content(flex-end);

            a {
                display: block;
                margin-left: 8px;
                color: $omm-blue;
                text-decoration: none;
                line-height: 20px;

                i {
                    font-size: 16px;
                    color: $omm-blue;
                }

                &:hover {
                    text-decoration: none;

                    i {
                        color: $omm-dark-blue-02;
                    }
                }
            }
        }
    }
}
