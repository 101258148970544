.job-slider {
    background-color: $soft-grey-01;
    padding: 60px 0;
    @media (min-width: $tablet) {
        padding: 80px 0;
    }
    &__title {
        margin-bottom: 24px; 
        color: $omm-blue;
        text-align: center;
        margin-bottom: 35px;
    }
    &__wrapper {
        @include flexbox();
        @include align-items(space-between);
        gap: 0 16px;
        overflow: auto;
        width: 100%;
        margin-bottom: 60px;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        @media (min-width: $tablet) {
            gap: 0 24px;
        }
    }
}
.job-slide {
    max-width: 95%;
    min-width: 300px;
    border-radius: 10px;
    overflow: hidden;
    min-height: 243px;
    background-image: none;
    position: relative;
    @include flexbox();
    @include align-items(space-between);
    @include flex-direction(column);

    @media (min-width: $tablet) {
        min-width: 632px;
        @include flex-direction(row);
        max-width: 632px;
        height: 243px;
    }
    &__image {
        background-image: unset;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 243px;
        width: 100%;
        border-radius: 10px;
        background-color: $soft-grey-03;
        @media (min-width: $tablet) {
            position: absolute;
            min-width: 333px;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    &__container {
        flex: 1;
        z-index: 1;
        @include flexbox();
        padding: 16px 12px;
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        text-align: center;
        @media (min-width: $tablet) {
            text-align: left;
            @include align-items(flex-start);
            padding: 32px 35px;
            @include justify-content(space-between);
        }
    }
    &__actions {
        width: 100%;
        @include flexbox();
        @include align-items(center);
    }
    &__title{
        font-size: 20px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.6px;
        color: $omm-blue;
        text-align: left;
        margin-bottom: 16px;
        @media (min-width: $tablet) {
            color: white;
            font-size: 34px;
            letter-spacing: -1.02px;
        }
    }
    &__link {
        color: $omm-blue; 
        font-size: 14px;
        letter-spacing: -0.42px;
        text-align: left;
        @media (min-width: $tablet) {
            color: white;
            font-size: 14px;
            letter-spacing: -0.42px;
        }
    }
    &__button {
        margin-bottom: 16px;
        margin-right: 0;
        width: auto;
        @media (min-width: $tablet) {
            margin-bottom: 0;
            margin-right: 24px;
        }
    }
}