$colors: (
    "black": #000000,
    "midnight": #012739,
    "darkblue-1": #004487,
    "darkblue-2": #153267,
    "dark-blue-1": #004487,
    "dark-blue-2": #153267,
    "blue": #005BB5,
    "accent-blue": #0071E1,
    "blue-soft-1": #F3F7FC,
    "blue-soft-2": #EBF1F9,
    "blue-soft-3": #E0EBF7,
    "gray-soft-1": #F7F9FB,
    "gray-soft-2": #EEF1F3,
    "gray-soft-3": #DDE3E7,
    "grey-soft-1": #F7F9FB,
    "grey-soft-2": #EEF1F3,
    "grey-soft-3": #DDE3E7,
    "slate": #657786,
    "slate-dark": #2a2e31,
    "dark-green-blue": #28724f,
    "light-olive-green": #a9c23f,
    "bluegreen": #007680,
    "grey-blue": #59bec9,
    "gray-blue": #59bec9,
    "teal-blue": #008eaa,
    "cerulean": #008bce,
    "reddish-orange": #ff671f,
    "golden": #f2cd00,
    "pale-orange": #ffb25b,
    "ocher": #d29f13,
    "strawberry": #ef3340,
    "berry": #8a1538,
    "darkblue": #04135f,
    "cerise": #ce0f69,
    "grape": #6e2b62,
    "cool-gray": #a4a5a7,
    "cool-grey": #a4a5a7,
    "light-peach": #e4d5d3,
    "dusky-purple": #86647a,
    "white": #fff,
);

@each $class,
$color in $colors {
    .background--#{$class} {
        background-color: $color;

        .card-carousel__gradient-overlay--left {
            left: 0;
            background-image: linear-gradient(to right, rgba($color, 1), rgba($color, 0));
        }

        .card-carousel__gradient-overlay--right {
            right: 0;
            background-image: linear-gradient(to left, rgba($color, 1), rgba($color, 0));
        }
    }

    .text--#{$class} {
        color: $color !important;

        &:hover,
        &:focus {
            color: $color;
        }
    }
}