.navigation-services {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__filters {}

    &__filter-button {
        width: 110px;
        height: 29px;
        border-radius: 120px;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.5px;
        border: none;
        border-color: transparent;
        background-color: $omm-blue-soft-1;
        color: $omm-dark-blue-01;
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: $omm-blue-soft-3;
        }

        &--active {
            background-color: $omm-blue-soft-3;
        }
    }

    &__results {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        max-height: 100%;
        flex: 1;
    }

    &__parents {
        padding: 16px 23px;
        border-right: 1px solid $omm-grey-soft-3;
        min-width: 300px;
        align-self: stretch;
        max-height: 100%;
        overflow-y: scroll;
        @include hiddenScrollbar();
    }

    &__parents-inner {}

    &__children {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        flex: 1;
        max-height: 100%;
        overflow-y: scroll;
        @include hiddenScrollbar();
        padding: 16px 0 13px 0;
    }

    &__children-inner {
        flex: 1;
        width: 100%;
        align-self: stretch;

        &:not(:last-of-type) {
            border-right: 1px solid #DDE3E7;
        }

        @media (min-width: $tablet) {
            padding: 2px;
        }
    }

    &__parent-link {
        display: block;
        color: $omm-midnight;
        padding: 0px;
        cursor: pointer;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.4px;
        text-align: left;
        color: $slate;
        margin-bottom: 16px;

        &--active {
            font-weight: bold;
            color: $omm-blue;
            text-decoration: underline;
        }
    }

    &__child-link {
        display: block;
        color: $omm-midnight;
        padding: 0px 16px 16px 30px;
        cursor: pointer;
        text-decoration: none;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        // margin-bottom: 16px;
        // width: 22%;

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        &--active {
            font-weight: bold;
        }
    }

    .page-subnavigation {
        @include verticalSpacingCustom(0, 0);
    }
}

.navigation-services-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__filters {
        padding: 0 23px 10px 23px;
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        align-items: center;
    }

    &__filter-button {
        width: 110px;
        height: 29px;
        border-radius: 120px;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.5px;
        border: none;
        border-color: transparent;
        background-color: $omm-blue-soft-1;
        color: $omm-dark-blue-01;
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: $omm-blue;
            color: white;
        }

        &--active {
            background-color: $omm-blue;
            color: white;
        }
    }

    &__links {
        width: 100%;
    }

    &__link {
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: black;
        cursor: pointer;
        opacity: 1;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.64px;
        text-align: left;
        padding: 14px 23px;
        border-bottom: 1px solid $soft-grey-03;
        text-decoration: none;

        i {
            color: $omm-blue;
            font-size: 22px;
            margin-right: 0px;
        }

        &:hover,
        &:focus {
            color: $omm-blue;
        }

        &--first-level {
            padding-left: 46px
        }

        &--second-level {
            padding-left: 69px
        }
    }
}