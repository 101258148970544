.cookie-policy {
    z-index: 9999;
    position: fixed;
    bottom: 10px;
    width: auto;
    padding: 15px;
    color: white;

    @media (min-width: $tablet) {
        right: 10px;
    }

    &__inner {
        background-color: rgba(42, 46, 49, 1);
        border-radius: 10px;
        padding: 22px;
        margin: 0 auto;

        @media (min-width: $tablet) {
            padding: 22px 32px;
            margin-left: auto;
            margin-right: 0;
            width: 484px;
        }
    }

    a {
        color: white;
        text-decoration: underline;
    }

    p {
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.3px;
        line-height: 1.4;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 18px;

        @media (min-width: $tablet) {
            text-align: left;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.3px;
            text-align: left;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $space-xxs;

        @media (min-width: $tablet) {
            flex-direction: row;
            justify-content: flex-start;
        }
    }

    &__action {
        @extend .button;

        &--white {
            @extend .button--white;
        }
    }

}