.professional-slider-card {
    background-color: white;
    border: solid 1px $soft-grey-03;
    border-radius: 10px;
    height: auto !important;
    margin-bottom: $space-xxs;
    min-height: 462px;
    overflow: hidden;

    &__wrapper {
        margin: 60px 0;
        width: auto;
        @include flexbox();
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    &__title {
        color: $slate;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        margin-bottom: 13px;

        @media (min-width: $tablet) {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            margin-bottom: 13px;
        }
    }

    &__name {
        color: $slate-dark;
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.71px;
        text-align: left;
        margin-bottom: 4px;
        display: inline-block;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            cursor: pointer;
        }

        @media (min-width: $tablet) {
            font-size: 28px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
            margin-bottom: 5px;
        }
    }

    &__content {
        color: $slate-dark;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        margin-bottom: 16px;
        flex: 1;

        @media (min-width: $tablet) {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            margin-bottom: 16px;
        }

    }

    &__image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        position: relative;
        height: 243px;
    }

    &__container {
        padding: 24px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__link {
        color: $omm-blue;
        text-decoration: underline;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;

        &:hover {
            text-decoration: none;
        }
    }
}

.professionals-slider {
    &__header {
        @include gutters();
        margin-bottom: $space-xs;
        text-align: center;

        h2 {
            font-size: 28px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: -0.84px;
            color: $omm-blue;
            text-align: center;
            margin-bottom: 0px;

            @media (min-width: $tablet) {
                font-size: 40px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -1.2px;
            }
        }
    }

    .search-results__section {
        margin-bottom: $space-xs;
    }

    .swiper {
        border-radius: 10px;

        // this is to compensate for the next and prev buttons
        @media (min-width: $laptop) {
            width: calc(100% - (2 * 44px));
        }
    }
}

.container .professional-slider-card__wrapper {
    @include gutters();
}

.container-fluid .professional-slider-card__wrapper {
    @include guttersFullWidth();
}