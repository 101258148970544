.professional {

    hr {
        margin: 32px 0;

        @media (min-width: $desktop) {
            margin: 72px 0;
        }
    }

    #credentials hr {
        margin: 0px 0;

        @media (min-width: $desktop) {
            margin: 0px 0;
        }
    }

    &__tab-content {
        @include gutters();
        display: none;

        &--active {
            display: block;
        }
    }

    &__content {
        padding: 0px 12px;
        margin-bottom: 62px;

        @media (min-width: $tablet) {
            padding: 0px 12px;
        }

        @media (min-width: $desktop) {
            padding: 0px 0;
            margin-bottom: 62px;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            color: $omm-blue;
            line-height: 1.3;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.86px;
            text-align: left;
            margin-bottom: 16px;
        }

        h2 {
            @media (min-width: $tablet) {
                font-size: 28px;
            }
        }

        p {
            color: $slate-dark;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: -0.3px;
            text-align: left;
        }

        ul,
        ol {
            padding-left: 16px;
            margin-bottom: 30px;
        }

        li {
            line-height: 1.4;
            margin-bottom: 16px;
            padding-left: 16px;
            position: relative;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.3px;
            text-align: left;
        }

        // &--experience {
        //     ul {
        //         &:not(:last-child) {
        //             margin-bottom: 0;
        //         }
        //     }
        // }
    }

    &__more-link {
        margin-top: 24px;
        font-size: 14px;
        font-weight: normal;
        color: $omm-blue;
        letter-spacing: -0.42px;
        text-decoration: underline;
        cursor: pointer;

        &:hover,
        &:active {
            text-decoration: none;
        }
    }

    &__section {
        margin-top: 50px;
        margin-bottom: 80px;
    }

    .page-hero__breadcrumbs--mobile {
        @media (min-width: $tablet) {
            display: flex;
        }
    }

    .page-hero__intro--mobile {
        @media (min-width: $tablet) {
            display: flex;
        }
    }

    &__read-more {
        color: $omm-blue;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.4;
        letter-spacing: -0.5px;
        margin-top: $gutter-sm;
        margin-bottom: $gutter-sm;
    }

    &__read-more-content {
        display: none;

        &--open {
            display: block;
        }
    }
}

.professional-details {
    margin-top: 24px;
    color: white;
    padding: 22px 0 20px 0;
    background-color: $omm-midnight;
    border-radius: 10px;
    @include flexbox();
    @include flex-direction(column);
    margin-bottom: 35px;
    font-weight: 300;
    position: relative;

    a {
        color: white;
        text-decoration: underline;

        &:hover,
        &:active {
            text-decoration: none;
        }
    }

    @media (min-width: $tablet) {
        padding: 22px 0 20px 0;
        margin-bottom: 0;
        margin-top: 16px;
        border: none;
        margin-bottom: 0;
    }

    @media (min-width: $laptop) {
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items(flex-start);
        flex-wrap: wrap;
        padding: 30px;
        min-height: 188px;
    }

    &--no-margin {
        margin-top: 0px;

        @media (min-width: $tablet) {
            margin-top: 0px;
        }
    }

    &__info {
        width: 100%;
        margin-bottom: 16px;
        // border-bottom: 1px solid rgba(221, 227, 231, 0.149716);
        padding-left: 16px;
        padding-right: 16px;

        @media (min-width: $laptop) {
            border-bottom: none;
            padding-left: 0;
            padding-right: 0;
        }

        @media (min-width: $desktop) {
            width: 45%;
        }
    }

    &__name {
        margin-bottom: 4px;
        font-size: 24px;

        @media (min-width: $laptop) {
            margin-bottom: 5px;
            font-size: 28px;
        }
    }

    &__title {
        font-size: 14px;
    }

    &__location {
        width: 100%;
        padding: 12px 18px;
        @include flexbox();
        @include flex-direction(row);
        order: 0;
        @include align-items(center);
        border-bottom: 1px solid rgba(221, 227, 231, 0.149716);


        a {
            // width: 50%;
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: -0.28px;

            &:first-of-type {
                margin-right: 32px;
            }

            // &:nth-of-type(2) {
            //     margin-right: 32px;
            // }

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        @media (min-width: $laptop) {
            padding-left: 0;
            padding-right: 0;
            border-bottom: none;
            margin-bottom: 0;

            a {
                font-size: 14px;
                width: auto;
                margin-right: 32px;
            }
        }

        @media (min-width: $desktop) {
            order: 1;
            @include justify-content(flex-end);
            width: 55%;
            padding-bottom: 0;
            margin-bottom: 0;

            a {
                margin-left: 32px;
                margin-right: 0;

                &:first-of-type {
                    margin-right: 0px;
                }
            }


        }
    }

    &__contact {
        flex: 1;
        width: 100%;
        margin-bottom: 16px;
        border-bottom: 1px solid rgba(221, 227, 231, 0.149716);
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0px;

        @media (min-width: $laptop) {
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            border-bottom: none;
            width: 60%;
            min-width: 440px;
            order: 2;
            flex: 1;
            @include flexbox();
            @include flex-direction(row);
            @include align-items(center);
            @include justify-content(flex-start);
            height: 30px;
            line-height: 1.38;

            p {
                margin: 0;
            }
        }

        @media (min-width: $laptop) {
            &-email {
                margin-right: 18px;
                border-right: 1px solid $omm-blue;
                height: 100%;
                @include flexbox();
                @include align-items(center);
            }
        }

        @media (min-width: $desktop) {
            margin-bottom: 0;

            &-email {
                margin-right: 18px;
                height: 100%;
                @include flexbox();
                @include align-items(center);
            }

            &-pronouns {
                margin-right: 18px;
                border-right: 1px solid $omm-blue;
                height: 100%;
                @include flexbox();
                @include align-items(center);
            }
        }

        a {
            margin-right: 20px;
            display: inline-block;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        &-email {
            font-size: 14px;
            padding: 12px 18px;
            border-bottom: 1px solid rgba(221, 227, 231, 0.149716);

            @media (min-width: $laptop) {
                padding: 0px;
                border-bottom: none;
            }
        }

        &-phone {
            font-size: 14px;
            display: flex;
            padding: 12px 18px;

            @media (min-width: $laptop) {
                padding: 0;
            }

            p {
                margin: 0;
            }
        }

        &-pronouns {
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: -0.28px;
            border-left: 0px solid $omm-blue;
            border-right: 0px solid $omm-blue;
            padding: 12px 18px 12px 18px;
            border-top: 1px solid rgba(221, 227, 231, 0.149716);

            @media (min-width: $laptop) {
                border-top: none;
                padding: 0px 18px;
                border-left: 1px solid $omm-blue;
            }

            @media (min-width: $desktop) {
                padding: 0 18px;
                margin-bottom: 0px;

            }
        }
    }

    &__footer {
        width: 100%;
        order: 3;
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
        @include justify-content(space-between);
        position: relative;

        @media (min-width: $tablet) {
            margin-bottom: 0;
            margin-top: 16px;
            border: none;
            margin-bottom: 0;
        }

        @media (min-width: $laptop) {
            position: absolute;
            top: 30px;
            right: 30px;
            margin-top: 0px;
        }

        @media (min-width: $desktop) {
            position: relative;
            top: 0px;
            right: 0px;
            height: 30px;
            line-height: 1.38;
            min-width: 200px;
            order: 3;
            flex: 0;
            padding-left: 18px;
            padding-right: 0px;
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 0px;
        }

        &-item {
            margin-bottom: 0;
            width: auto;
        }

        &-icons {
            flex: 1;
            @include flexbox();
            @include flex-direction(row);
            @include align-items(center);
            @include justify-content(center);
            width: 50%;

            @media (min-width: $laptop) {
                margin-left: 30px;
                flex: 1;
                @include justify-content(flex-end);
            }

            a {
                text-decoration: none;
                padding: 0 12px;
                line-height: 21px;

                i {
                    font-size: 24px;
                    color: white;
                }

                &:hover,
                &:active {
                    text-decoration: none;

                    i {
                        color: $omm-blue-soft-3;
                    }
                }
            }
        }
    }
}

.professional-sectors {
    width: 100%;
    margin-top: 0px;
    margin-bottom: $space-sm;

    .search-results__section-header {
        display: none;
    }

    .search-results__section-body {
        display: none;

        @media (min-width: $laptop) {
            display: block;
            border: 1px solid $soft-blue-02;
            border-radius: 0px 0px 5px 5px;

            .search-result__page {
                padding: 7px 10px;
                line-height: 1;
            }
        }

    }

    .search-results__section-body.active {
        display: block;

        i {
            display: none;

            @media (min-width: $laptop) {
                display: block;
            }
        }
    }

    @media (min-width: $tablet) {
        margin-bottom: 16px;
        margin-top: 35px;
    }

    @media (min-width: $laptop) {
        margin-bottom: 16px;
        margin-top: 0px;

        .search-results__section-header {
            @include flexbox();
            border-radius: 5px 5px 0px 0px;
        }

        .search-results__section-title {
            font-size: 12px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0.5px;
            text-align: left;
            text-transform: uppercase;
            color: $omm-blue;
        }

        .search-results__section-view {
            font-size: 12px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: right;
            text-decoration: underline;
            color: $omm-blue;
        }

        .search-result__page:last-of-type {
            border: none;
        }
    }

    &__toggle {
        border-top: 1px solid $soft-grey-03;
        border-bottom: 1px solid $soft-grey-03;
        width: 100%;
        padding: 12px 12px;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        color: $omm-blue;
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);

        i {
            font-size: 20px;

        }

        @media (min-width: $laptop) {
            display: none;
        }
    }
}