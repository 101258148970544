.omm {
    background: white;

    * {
        box-sizing: border-box;
    }
}

.overlay-active {
    @media (min-width: 1024px) {
        z-index: 1200;
        overflow: hidden;
    }
}

.overlay-active--mobile {
    @media (max-width: 1024px) {
        z-index: 1200;
        overflow: hidden;
    }
}

.overlay-backdrop {
    display: none;

    @media (min-width: 1024px) {
        display: block;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
    }
}

.overlay-backdrop--mobile {
    display: block;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    @media (min-width: 1024px) {
        display: none;
    }
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $soft-grey-03;
    padding: 0;
    margin: 32px 0;

    @media (min-width: $desktop) {
        margin: 72px 0;
    }

    &.no-margin-top {
        margin-top: 0px;
    }

    &.no-margin-bottom {
        margin-bottom: 0px;
    }
}

.no-scroll-mobile {
    overflow-y: hidden;

    @media (min-width: $laptop) {
        overflow-y: visible;
    }
}

.no-scroll {
    overflow-y: hidden;

    // // We set the z-index low here so overlays are not hidden
    // #navigation,
    // #navigation-mobile,
    // .page-subnavigation {
    //     z-index: 1;
    // }
}

.sticky {
    @include sticky;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: $omm-blue-soft-1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    width: 4px;
    height: 4px;
    opacity: 0.64;
    background-color: $slate;
}

.swiper {
    width: 100%;
}