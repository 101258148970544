.video-hero-full {
    width: 100%;
    height: 188px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include flexbox();
    color: white;
    padding: 25px $gutter-md;
    position: relative;

    @media (min-width: $tablet) {
        height: 700px;
        padding: 40px $gutter-md;
    }

    &--small {
        @media (min-width: $tablet) {
            height: 520px;
        }
    }

    .video-button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__content {
        display: none;


        @media (min-width: $tablet) {
            @include flexbox();
            width: 100%;
            @include flex-direction(column);
            @include justify-content(flex-start);
        }

        @media (min-width: 1300px) {
            padding: 0;
        }

        &--mobile {
            padding: 20px 12px 38px;
            border-bottom: 1px solid $omm-grey-soft-3;
            display: block;

            @media (min-width: $tablet) {
                display: none;
            }
        }
    }

    &__title {
        color: $omm-blue;
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.71px;
        text-align: left;

        @media (min-width: $tablet) {
            color: white;
            margin-bottom: 16px;
            font-size: 28px;
            max-width: 740px;
        }
    }

    &__description {
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        max-width: 740px;
    }

    @media (min-width: $tablet) {

        .overlay,
        .overlay-bg {
            position: absolute;
            z-index: 400;
        }
    }

}

.container .video-hero-full {
    padding: 25px;

    @media (min-width: $tablet) {
        padding: 40px;
    }
}