.standard-hero {
  overflow: hidden;

  &__wrapper {
    @include guttersSmall();
  }

  &__inner {
    isolation: isolate;
    overflow: hidden;
    margin-bottom: 8px;
    padding: $space-xxs;
    height: 400px;
    background-color: $slate;
    position: relative;
    background-size: cover;
    background-position: center;

    @media (min-width: $tablet) {
      margin-bottom: 8px;
      height: 572px;
      padding: $space-md;
    }

    .container {
      align-self: stretch;
      height: 100%;
      position: relative;
    }
  }

  &__overlay-gradient {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__action {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    display: none;

    @media (min-width: $tablet) {
      top: 0;
      right: 0;
      display: block;
    }

    a {
      @extend .button;
    }
  }

  &__action--mobile {
    @include guttersSmall();
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @include verticalSpacingCustom(14px, 14px);

    @media (min-width: $tablet) {
      display: none;
    }

    a {
      @extend .button;
    }
  }

  &__breadcrumb-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--end {
      justify-content: flex-end;
    }
  }

  &__overlay-action {
    display: none;

    @media (min-width: $tablet) {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 5;
    }

    &--breadcrumb-bar {
      position: relative;
      right: unset;
      top: unset;
      @include gutters;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  &__overlay-action--mobile {
    @include guttersSmall();
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @include verticalSpacingCustom(14px, 14px);

    @media (min-width: $tablet) {
      display: none;
    }

    a {
      @extend .button;
    }
  }

  &__content {
    position: relative;
    align-self: stretch;
    height: 100%;
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(flex-start);
    @include align-items(flex-start);
    z-index: 1200;

    @media (min-width: $tablet) {
      @include justify-content(space-between);
      @include align-items(flex-start);
      height: 100%;
      max-width: 80%;
    }

    @media (min-width: $laptop) {
      max-width: 65%;
    }
  }

  &__title {
    z-index: 1200;
    color: white;
    font-size: 28px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -0.5px;
    margin-bottom: $gutter-md;

    @media (min-width: $tablet) {
      font-size: 40px;
    }
  }

  &__description {
    z-index: 1200;
    color: $omm-blue;
    font-size: 28px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: -0.5;
    text-align: left;
    line-height: 1.2;

    @media (min-width: $tablet) {
      color: white;
      margin-bottom: 16px;
    }
  }

  &__disclaimer {
    z-index: 1200;
    color: $slate-dark;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.5px;

    @media (min-width: $tablet) {
      color: white;
    }
  }

  &__maincontent {
    z-index: 1200;
    display: none;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-self: stretch;

    @media (min-width: $tablet) {
      display: flex;
    }

    &--top {
      justify-content: flex-start;
    }

    &--logo {
      justify-content: space-between;
    }

    &--mobile {
      display: flex;
      padding: 15px 15px;

      @media (min-width: $tablet) {
        display: none;
      }
    }
  }

  &__video {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    width: 101%;
    height: 101%;
    position: absolute;
    top: 0;
    left: 0;
    max-width: none;
    z-index: 0;
  }

  &__video-player {
    z-index: 2;
    display: block;
    object-fit: cover;
    width: 101%;
    height: 101%;
    position: absolute;
    top: 0;
    left: 0;
    max-width: none;
  }

  &__breadcrumbs {
    @media (max-width: $tablet) {
      display: none;
    }

    padding-top: 18px;
    padding-bottom: 18px;

    li,
    li a {
      color: black;
      font-size: 12px;
      font-weight: 300;
      font-style: normal;
      text-align: left;
    }
  }

  &__logo {
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    width: 190px;
    height: 27px;
  }
}

.container .standard-hero__inner {
  border-radius: 10px;
}

.container-fluid .standard-hero__wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.container .standard-hero__action--mobile,
.container .standard-hero__overlay-action--mobile {
  margin-top: 0 !important;
}
