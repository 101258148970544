.services-list {

    &__section {
        padding: 0px 12px 0px 12px;

        @media (min-width: $tablet) {
            padding: 0px 12px 0px 12px;
        }

        @media (min-width: $desktop) {
            padding: 0px 0px 0px 0px;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 16px;
            height: 30px;
            margin: 0px 0px 0px 0px;
            border-radius: 5px;
            background-color: $soft-blue-02;
        }

        &-title {
            font-size: 12px;
            line-height: 1.1;
            letter-spacing: 0.12px;
            text-align: left;
            text-transform: uppercase;
            color: $omm-blue;
        }

        &-view {
            font-size: 12px;
            letter-spacing: -0.36px;
            text-align: right;
            color: $omm-blue;
            text-decoration: underline;

            a {
                font-size: 12px;
                letter-spacing: -0.36px;
                text-align: right;
                color: $omm-blue;
                text-decoration: underline;
            }
        }

        &-body {

            @media (min-width: $tablet) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 0 16px;

            }

            &:last-child.search-result__article {
                border: none;
            }

        }
    }

    &__page {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 12px 12px;
        text-decoration: none;
        border-bottom: 1px solid $soft-grey-03;

        @media (min-width: $tablet) {
            padding: 14px 0px;
            width: calc(33.33333% - 16px);
        }

        &:hover,
        &:focus {
            .search-result__page-title {
                text-decoration: underline;
                color: $omm-blue;
            }
        }

        &-title {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            color: black;
            margin-bottom: 0;
            line-height: 1;
        }

        &-icon {
            font-size: 20px;
            color: $omm-blue;
            margin-right: 16px;
            margin-bottom: 0;

            @media (min-width: $tablet) {
                display: block;
            }
        }
    }
}

.container .services-list {
    @include gutters();
}

.container-fluid .services-list {
    @include guttersFullWidth();
}