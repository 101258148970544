.tagged-news {
    min-height: 200px;
    @include flexbox();
    @include justify-content(space-between);
    @include flex-direction(column);
    @include align-items(flex-start);
    padding: 22px 22px 22px 22px;
    margin-bottom: 16px;
    border-radius: 10px;
    width: 100%;
    flex: 1;
    color: $omm-dark-blue-02;
    text-decoration: none;

    &--image {
        color: white;

        .tagged-news__inner {
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
        }

        .tagged-news__title {
            color: white;
        }

        .tagged-news__meta-text {
            color: white;
        }

        .tagged-news__meta-icon {
            background-color: rgba(255, 255, 255, 0.14);

            i {
                color: white;
            }

            &:hover,
            &:focus {
                background-color: rgba(255, 255, 255, 0.24);
            }
        }
    }

    &__filters {
        display: flex;
        gap: 5px;
        padding-left: 6px;
        margin-bottom: 16px;

        @media (min-width: $laptop) {
            margin-bottom: 26px;
            padding-left: 0px;
        }
    }

    &__filter-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        padding: 0px 31px;
        border-radius: 120px;
        background-color: $soft-blue-01;
        border: none;
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        color: $omm-blue;
        cursor: pointer;

        &--active {
            background-color: $soft-blue-03;
        }
    }

    &:nth-child(1) {
        background-color: $omm-blue-soft-1;
    }

    &:nth-child(2) {
        background-color: $omm-blue-soft-2;
    }

    @media (min-width: $laptop) {
        min-height: 200px;
        padding: 24px 30px 24px 30px;
        // &:nth-child(1),
        // &:nth-child(4) {
        //     min-height: 250px;
        // }
    }

    &__content {
        flex: 1;
    }

    &__category {
       font-weight: bold;        
    }

    &__title {
        text-align: left;
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.71px;
        text-align: left;
        line-height: 1.3;
        color: $omm-dark-blue-02;

        @media (min-width: $laptop) {
            font-size: 28px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
        }

        &--blue {
            color: $omm-blue;
            font-size: 28px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            line-height: 1.14;
            margin-bottom: $space-xs;
            padding: 0 12px;
            text-align: center;

            @media (min-width: $laptop) {
                text-align: left;
                font-size: 40px;
                margin-bottom: $space-sm;
                padding: 0;
            }
        }
    }

    &__meta {
        display: flex;
        align-items: flex-end;
        width: 100%;
        font-size: 14px;
        letter-spacing: -0.36px;

        span:not(:last-child) {
            margin-right: 16px;
        }
    }

    &__container {


        @media (min-width: $laptop) {
            @include flexbox();
            @include justify-content(stretch);
            @include align-items(stretch);
            @include flex-direction(row);
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 16px;
        }
    }

    &__section {
        @media (min-width: $laptop) {
            flex: 1;
            @include flexbox();
            @include flex-direction(column);
            @include align-items(stretch);
            @include justify-content(stretch);
            max-width: 60%;
        }

        &:last-child {
            @media (min-width: $laptop) {
                max-width: 40%;
                margin-left: 16px;
            }

            .tagged-news {
                padding: 20px 20px 20px 20px;

                &:nth-child(1) {
                    background-color: $omm-blue-soft-3;
                }

                &:nth-child(2) {
                    background-color: $omm-blue-soft-1;
                }

                &:nth-child(3) {
                    background-color: $omm-blue-soft-2;
                }

                @media (min-width: $laptop) {
                    padding: 20px 20px 20px 20px;

                    &:nth-child(1) {
                        background-color: $omm-blue-soft-2;
                    }

                    &:nth-child(2) {
                        background-color: $omm-blue-soft-3;
                    }

                    &:nth-child(3) {
                        background-color: $omm-blue-soft-1;
                    }
                }
            }

            .tagged-news__title {
                @media (min-width: $laptop) {
                    font-size: 24px;
                }
            }
        }

        .tagged-news {
            transition: all 200ms ease-in-out;

            &:hover,
            &:focus {
                background-color: $omm-blue-soft-3 !important;
            }
        }
    }
}

.container .related-news {
    @include gutters();
}

.container-fluid .related-news {
    @include guttersFullWidth();
}