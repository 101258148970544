.professionals {

    &__wrapper {
        width: 100%;
        @include gutters();

        @media (min-width: $tablet) {
            @include flexbox();
            gap: $space-sm $space-xxs;
            flex-wrap: wrap;
        }

        .search-result__professional {
            min-height: unset;
            width: 100%;
            @include flex-direction(row);
            margin-bottom: $space-xxs;
            width: 100%;

            @media (min-width: $tablet) {
                @include flex-direction(column);
                margin-bottom: 0;
                width: calc(50% - 8px);
            }

            @media (min-width: $laptop) {
                @include flex-direction(column);
                width: calc(33.33% - 11px);
            }

            @media (min-width: $desktop) {
                @include flex-direction(column);
                width: calc(25% - 12px);
            }

            &-socials {
                display: none;

                @media (min-width: $tablet) {
                    display: block;
                }
            }

            &-left {
                @include flexbox();
                @include justify-content(flex-start);
                @include align-items(center);
                flex-direction: column;
                padding: 15px;

                .search-result__professional-socials {
                    display: block;
                }

                @media (min-width: $tablet) {
                    display: none;

                    .search-result__professional-socials {
                        @include flexbox();
                        @include justify-content(center);
                    }
                }
            }

            &-image {
                display: none;
                background-color: $omm-blue-soft-1;
                background-size: cover;
                background-position: top center;

                @media (min-width: $tablet) {
                    display: block;
                }
            }

            &--heading {
                @media (min-width: $laptop) {
                    flex: 1;
                }
            }

            &-details {
                padding: 19px 21px;
                flex: 1;
                @include flexbox();
                @include justify-content(space-between);
                @include flex-direction(column);
            }

            &-name {
                line-height: 1;
                font-size: 24px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -1px;
                text-align: left;
                color: black;
                text-decoration: none;
                transition: all 200ms ease-in-out;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }

            &-title {
                font-size: 14px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -0.5px;
                text-align: left;
                color: $slate;
                margin-bottom: 16px;
            }
        }
    }

    &__container {
        @include flexbox();
        @include flex-direction(column);
        width: 100%;
        @include justify-content(flex-start);
        min-height: 220px;

        @media (min-width: $desktop) {
            min-height: 300px;
        }
    }

    &__empty {
        font-size: 20px;
        font-stretch: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.6px;
        text-align: center;
        color: $slate;
        padding: 10% 0;
    }

    &__page-header {
        @include gutters();
        @include verticalSpacingCustom($space-xxxl, 16px);

        .container {
            @media (min-width: $tablet) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                align-items: center;
            }
        }
    }

    &__sort-dropdown {
        @include gutters();
        display: flex;
        align-items: center;

        .news-block__cards-filter {
            min-width: 180px;
            margin-top: 8px;
            margin-bottom: 8px;
        }
        // select {
        //     -webkit-appearance: none;
        //     appearance: none;
        //     font-size: 12px;
        //     font-weight: 300;
        //     font-style: normal;
        //     letter-spacing: -0.5px;
        //     background-color: transparent;
        //     border: none;
        //     color: $slate;
        //     &:hover,
        //     &:focus {
        //         cursor: pointer;
        //         outline: 0;
        //     }
        // }
        // i {
        //     color: $slate;
        //     font-size: 12px;
        //     margin-right: 4px;
        // }
        // &:hover,
        // &:focus {
        //     cursor: pointer;
        //     color: $omm-blue;
        //     text-decoration: none;
        //     i,
        //     select {
        //         cursor: pointer;
        //         color: $omm-blue;
        //     }
        // }
    }

    &__page-search {
        form {
            flex-direction: column-reverse;
            align-items: flex-end;
            display: flex;
            gap: 8px;
        }

        @media (min-width: $laptop) {
            form {
                flex-direction: row-reverse;
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }

    &__page-title {
        color: $omm-blue;
        font-size: 28px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        text-align: left;
        margin-bottom: $space-xxxs;

        @media (min-width: $tablet) {
            margin-bottom: 0;
            font-size: 40px;
            font-weight: 300;
            line-height: 0.8;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
        }
    }

    &__page-filters {
        min-height: 51px;
        /* width */
        &::-webkit-scrollbar {
            width: 2px;
            display: none;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background-color: $omm-blue-soft-1;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            width: 2px;
            height: 2px;
            opacity: 0.64;
            background-color: $slate;
        }

        @media (min-width: $tablet) {
            flex: 1;
            display: flex;
            overflow: auto;
            justify-content: flex-start;
        }

        @media (min-width: $desktop) {
            display: block;
            overflow: none;
        }

        &--full {
            border-top: 1px solid $soft-grey-03;
            border-bottom: 1px solid $soft-grey-03;
            width: 100%;
            min-width: 100%;

            .news-block__cards-filter {
                flex: 1;
                min-width: 120px;
                max-width: 200px;

                @media (min-width: $tablet) {
                    min-width: 100px;
                }

                @media (min-width: $laptop) {
                    min-width: 120px;
                }

                @media (min-width: $desktop) {
                    min-width: 130px;
                }
            }
        }

        &--mobile {
            @media (min-width: $tablet) {
                display: none;
            }
        }

        &-container {
            display: none;
            gap: 8px;
            flex-direction: column;
            @include justify-content(flex-end);
            flex: 1;
            @include gutters();
            @include verticalSpacingCustom(8px, 8px);

            .news-block__cards-filter {
                width: 100%;
                max-width: 100%;
            }

            @media (min-width: $tablet) {
                .news-block__cards-filter {
                    width: 100%;
                    max-width: 100%;
                }

                flex-direction: row;
                display: flex;
            }

            &--active {
                display: flex;
            }
        }
    }

    &__filters-wrapper {
        align-self: stretch;

        @media (min-width: $tablet) {
            border-right: 1px solid $soft-grey-03;
            border-right: none;
        }
    }

    &__filters-toggle {
        padding: 0px 20px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        cursor: pointer;

        @media (min-width: $tablet) {
            justify-content: flex-start;
        }

        &--mobile {
            @media (min-width: $tablet) {
                display: none;
            }
        }
    }

    &__filters-title {
        color: $omm-blue;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
    }

    &__filters-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        font-size: 24px;
        letter-spacing: -0.36px;
        text-align: left;
        color: $omm-blue;

        @media (min-width: $tablet) {
            display: none;
        }
    }

    &__secondary-filters {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__clear-filters {
        margin-top: 5px;
        @include gutters();
        text-align: right;
        height: 32px;
        cursor: pointer;

        button {
            font-family: "proxima-nova";
            font-size: 12px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            background-color: transparent;
            border: none;
            color: $slate;
            cursor: pointer;

            &:hover,
            &:focus {
                color: $omm-blue;
                text-decoration: underline;
            }
        }
    }

    &__input-wrapper {
        cursor: pointer;
        overflow: hidden;
        position: relative;
        width: 100%;

        @media (min-width: $tablet) {
            min-width: 300px;
        }

        input[type="text"] {
            background-color: $soft-blue-01;
            border: solid 1px $soft-grey-03;
            border-radius: 50px;
            color: $omm-dark-blue-01;
            font-size: 14px;
            font-weight: 300;
            line-height: 1.3;
            // needed height due to FireFox quirk with input height
            height: 36px;
            padding: $space-xxxs $space-sm $space-xxxs $space-xxs;
            text-overflow: ellipsis;
            transition: background-color 200ms ease-in-out;
            width: 100%;

            &::placeholder {
                color: $omm-dark-blue-01;
                opacity: 1;
            }

            &:focus {
                outline: none;
            }
        }

        button {
            background-color: transparent;
            border: none;
            color: $omm-blue;
            cursor: pointer;
            font-size: 18px;
            line-height: 0;
            padding: 0 $space-xxs;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: color 200ms ease-in-out;

            &:hover,
            &:focus {
                color: $omm-blue;
            }
        }
    }

    &__hidden {
        display: none;
    }
}
