@mixin hiddenScrollbar() {

    /* width */
    &::-webkit-scrollbar {
        width: 2px;
        display: none;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background-color: $omm-blue-soft-1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        width: 2px;
        height: 2px;
        opacity: 0.64;
        background-color: $slate;
    }
}

// Flexbox display
@mixin sticky() {
    position: -webkit-sticky;
    position: sticky;
    z-index: 3000;
    top: 65px;

    @media (min-width: 1024px) {
        top: 54px;
    }
}

@mixin resetSticky() {
    position: relative;
    z-index: auto;
    top: inherit;

    @media (min-width: 1024px) {
        top: inherit;
    }
}

// Flexbox display
@mixin container() {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

@mixin container-medium() {
    max-width: 1350px;
    margin: 0 auto;
}

// Flexbox display
@mixin container-small() {
    max-width: 1000px;
    margin: 0 auto;
}

// Flexbox display
@mixin container-large() {
    max-width: 1440px;
    margin: 0 auto;
}

// Flexbox display
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

// flex-direction
@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

// align items
@mixin align-items($align) {
    -webkit-align-items: $align;
    -moz-align-items: $align;
    -ms-align-items: $align;
    align-items: $align;
}

// align items
@mixin justify-content($align) {
    -webkit-justify-content: $align;
    -moz-justify-content: $align;
    -ms-justify-content: $align;
    justify-content: $align;
}

// Flex grow factor
@mixin flex-grow($grow) {
    -webkit-flex-grow: $grow;
    -moz-flex-grow: $grow;
    -ms-flex-grow: $grow;
    flex-grow: $grow;
}

// Flex shrink
@mixin flex-shrink($shrink) {
    -webkit-flex-shrink: $shrink;
    -moz-flex-shrink: $shrink;
    -ms-flex-shrink: $shrink;
    flex-shrink: $shrink;
}

// Flex basis
@mixin flex-basis($width) {
    -webkit-flex-basis: $width;
    -moz-flex-basis: $width;
    -ms-flex-basis: $width;
    flex-basis: $width;
}