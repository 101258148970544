.vertical-cta-cards {
  &__title {
    font-size: 28px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -0.84px;
    color: $omm-blue;
    text-align: center;

    @media (min-width: $tablet) {
      font-size: 40px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -1.2px;
      margin-bottom: 16px;
    }
  }

  &__card-wrapper {
    border-radius: 5px;
    border: solid 1px $soft-grey-03;
    background-color: white;
  }

  &__card {
    padding: 30px 22px 30px 22px;
    border-bottom: 1px solid $soft-grey-03;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &:last-of-type {
      border-bottom: none;
    }

    @media (min-width: $tablet) {
      flex-direction: row;
      padding: 35px 47px 35px 47px;
    }
  }

  &__card-content {
    flex: 1;

    @media (min-width: $tablet) {
      padding-right: 20%;
    }

    h4 {
      font-size: 12px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: 0.5px;
      text-align: left;
      text-transform: uppercase;
      color: $omm-blue;
      margin-bottom: 8px;

      @media (min-width: $tablet) {
        text-transform: none;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        margin-bottom: 8px;
      }
    }

    p {
      font-size: 20px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: -0.71px;
      text-align: left;
      color: $slate-dark;
      margin-bottom: 17px;
      line-height: 1.2;

      @media (min-width: $tablet) {
        font-size: 20px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.6px;
      }
    }
  }

  &__card-action {
    width: 225px;
  }
}

.container .vertical-cta-cards {
  @include gutters();
}

.container-fluid .vertical-cta-cards {
  @include guttersFullWidth();
}
