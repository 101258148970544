.sitemap {

    ul {
        list-style: none;
    }

    a {
        color: black;
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }
}