.button,
input[type="submit"] {
    flex-shrink: 0;
    background-color: $omm-blue;
    border: 1px solid $omm-blue;
    color: white;
    padding: $space-xxxs $space-sm;
    font-family: 'proxima-nova', sans-serif;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    text-decoration: none;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.3px;
    border-radius: 32px;
    width: auto;
    text-align: center;
    text-transform: capitalize;
    transition: all 200ms ease-in-out;

    &:hover,
    &:focus {
        background-color: $omm-darkblue-1;
        border-color: $omm-darkblue-1;
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.button--share-pdf {
    border-radius: 0px;
    position: fixed;
    top: 350px;
    right: 0;
    border-radius: 5px 0 0 5px;
    padding: 8px;
    min-width: 40px;
    font-size: 24px;
    z-index: 99999;
}

.button--icon {
    @include justify-content(space-between);

    i {
        margin-left: 10px;
    }

    @media (min-width: $tablet) {
        min-width: 160px;
    }
}

.button--inline {
    display: inline-flex;
}

.button--small {
    font-size: 12px;
    min-width: 138px;
    padding: 0px 16px;
    min-height: 30px;

    @media (min-width: $tablet) {
        font-size: 14px;
        min-width: auto;
        min-height: 30px;
    }
}

.button--white {
    background-color: white;
    border-color: white;
    color: $omm-blue;

    &:hover,
    &:focus {
        background-color: $omm-blue-soft-1;
        border-color: $omm-blue-soft-1;
        cursor: pointer;
    }
}

.button--outline {
    background-color: transparent;
    color: $white;
    border: 2px solid white;

    &:hover,
    &:focus {
        background-color: white;
        color: $omm-blue;
        cursor: pointer;
    }
}

.button-block {

    a {
        display: inline-flex;
    }
}

.container .button-block {
    @include gutters();
}

.container-fluid .button-block {
    @include guttersFullWidth();
}

.dropdown-button {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    min-width: 180px;
    display: none;
    margin: 0;
    background-color: white;
    border-radius: 5px;
    border: 1px solid $omm-blue-soft-3;
    box-shadow: 0 0 11px 0 rgba(101, 119, 134, 0.04);
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    z-index: 120000;

    &--open {
        display: block;
    }
}

.dropdown-link {

    &:not(:last-child) {
        border-bottom: 1px solid $omm-grey-soft-3;
    }

    a {
        padding: 7px 12px;
        color: $slate-dark;
        font-size: 16px;
        line-height: 1.4;
        letter-spacing: -0.5px;
        font-weight: 300;
        display: block;
        text-decoration: none;
        text-decoration: none;
    }

    &:hover,
    &:focus {
        background-color: $omm-blue-soft-1;
        cursor: pointer;
    }
}