.careers {
    &-overlay {
        &__container {
            margin-top: 24px;

            @media (min-width: $tablet) {
                margin-top: 40px;
            }
        }



        &-item {
            margin-bottom: 16px;
            background-color: $soft-blue-01;
            padding: 16px 24px 24px 24px;
            @include flexbox();
            border-radius: 10px;
            @include flex-direction(column);

            @media (min-width: $tablet) {
                padding: 20px 24px 30px 24px;
                min-height: 124px;
            }

            &__content {
                @include flexbox();
                @include flex-direction(column);

                @media (min-width: $tablet) {
                    @include flex-direction(row);
                    @include justify-content(space-between);
                    @include align-items(center);
                }

                &--more {
                    margin-top: 24px;
                    padding-top: 24px;
                    border-top: 1px solid $soft-grey-03;
                    @include flexbox();

                    @media (min-width: $tablet) {
                        @include align-items(center);
                    }
                }

                .button {
                    min-width: 151px;
                    padding-left: 19px;
                    padding-right: 19px;
                    width: fit-content;
                    display: inline-flex;

                }

                .dropdown-menu {
                    left: 0;
                    right: unset;

                    @media (min-width: $tablet) {
                        right: 0;
                        left: unset;
                    }
                }

                &-wrap {
                    @include flexbox();
                    padding-right: 24px;
                    @include flex-direction(column);
                    @include justify-content(center);
                    @include align-items(flex-start);
                    margin-bottom: 24px;

                    @media (min-width: $tablet) {
                        padding-right: 30px;
                        margin-bottom: 0px;
                    }

                    h3 {
                        color: $omm-blue;
                        margin-bottom: 8px;
                        font-size: 20px;
                        font-weight: 300;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: -0.6px;
                        line-height: 1.3;
                    }

                    p {
                        color: $slate-dark;
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 1.3;
                        letter-spacing: -0.28px;
                        margin-bottom: 5px;
                    }

                    a {
                        color: $omm-blue;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}