.no-navigation-layout {
    background-color: black;
}

.holiday-card {
    text-align: center;
    padding: 30px;

    .container {
        max-width: 960px;
    }

    &__caption p {
        display: block;
        text-align: center;
        padding: 30px;
        color: #fcfcfc;
        font-size: 14px;

        a {
            color: white;
            font-size: 14px;
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: underline;
                color: white;
                cursor: pointer;
            }
        }
    }

    &__link {
        padding: 20px;
        display: block;
        color: white;
        font-size: 14px;
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: underline;
            color: white;
            cursor: pointer;
        }
    }
}