.insights {
    @include gutters;
    @include verticalSpacingCustom(16px, 16px, 56px, 56px);


    &--category {
        .news-block__cards {
            margin-bottom: 90px;
        }
    }

    &__section-title {
        color: $omm-blue;
        font-size: 28px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        line-height: 1.14;
        margin-bottom: $space-xs;
        min-width: 40%;

        @media (min-width: $laptop) {
            font-size: 40px;
            margin-bottom: $space-sm;
        }
    }
}

.news-block__cards-header .insights__section-title {
    margin: 0;
}