.service {
    padding-bottom: 0px;

    @media (min-width: $laptop) {
        padding-bottom: 0px;
    }

    &__stack {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    &__tab-content {
        @include gutters();
        @include verticalSpacingSmall();
        display: none;

        &--active {
            display: block;
        }
    }

    &__content {
        justify-content: space-between;
        flex-direction: column;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @media (min-width: $laptop) {
            flex-direction: row;
        }
    }

    &__overview {
        flex: 1;
        margin-bottom: 67px;

        @media (min-width: $laptop) {
            margin-bottom: 0;
            padding-right: 67px;
        }

        @media (min-width: $desktop) {
            max-width: 864px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $omm-blue;
            margin-bottom: 0;
        }

        p,
        ul,
        ol,
        blockquote,
        address {
            margin-bottom: 0;
        }

        ul,
        ol {
            padding-left: 17px;
        }

        ol li {
            padding-left: 5px;
        }

        h2 {

            &+h3,
            &+p,
            &+ul,
            &+ol,
            &+blockquote,
            &+address {
                margin-top: 0.8em;
            }
        }

        h3 {
            font-size: 24px;

            &+h4,
            &+p,
            &+ul,
            &+ol,
            &+blockquote,
            &+address {
                margin-top: 0.8em;
            }
        }

        h4 {
            font-size: 20px;

            &+h5,
            &+p,
            &+ul,
            &+ol,
            &+blockquote,
            &+address {
                margin-top: 0.8em;
            }
        }

        h5 {

            &+h6,
            &+p,
            &+ul,
            &+ol,
            &+blockquote,
            &+address {
                margin-top: 0.8em;
            }
        }

        h6 {

            &+p,
            &+ul,
            &+ol,
            &+blockquote,
            &+address {
                margin-top: 0.8em;
            }
        }

        p,
        ul,
        ol,
        blockquote,
        address {

            &+h2,
            &+h3,
            &+h4,
            &+h5,
            &+h6,
            &+p,
            &+ul,
            &+ol,
            &+blockquote,
            &+address {
                margin-top: 0.8em;
            }
        }

        ul,
        ol {
            & li:not(:first-child) {
                margin-top: 0.6em;
            }
        }
    }

    &__clients {
        align-self: flex-start;
        border-radius: 10px;
        background-color: $soft-blue-02;
        padding: 29px 33px;
        width: 100%;

        @media (min-width: $laptop) {
            max-width: 393px;
        }
    }

    &__clients-title {
        color: $omm-blue;
        font-size: 28px;
        font-style: normal;
        font-weight: 300;
        letter-spacing: -1px;
        margin-bottom: 16px;
        text-align: left;
    }

    &__clients-item {
        color: black;
        display: block;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        letter-spacing: -0.5px;
        margin-bottom: 8px;
        text-align: left;
        text-decoration: none;
    }

    &__subcategory {
        display: none;
        align-self: flex-start;
        width: 100%;
        border-radius: 10px;
        background-color: $soft-blue-02;
        max-width: 393px;
        padding: 29px 33px;

        @media (min-width: $laptop) {
            display: block;
        }
    }

    &__subcategory-title {
        color: $omm-blue;
        font-size: 28px;
        font-style: normal;
        font-weight: 300;
        letter-spacing: -1px;
        margin-bottom: 16px;
        text-align: left;
    }

    &__subcategory-item {
        color: black;
        display: block;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        letter-spacing: -0.5px;
        margin-bottom: 8px;
        text-align: left;
        text-decoration: none;
    }

    &__subcategory-item {
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        color: black;
        text-align: left;
        margin-bottom: 8px;
        display: block;
        text-decoration: none;
    }

    &__clients-dropdown {
        width: 100%;
        height: 56px;
        max-width: unset;
        position: relative;
        border-radius: 42px;
        background-color: white;
        border: solid 1px $soft-grey-03;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        cursor: pointer;

        i {
            color: $omm-blue;
            padding: 0px 18px;
            position: absolute;
            right: 0;
            top: 0;
            height: 56px;
            line-height: 56px;
            vertical-align: middle;
            pointer-events: none;
            font-size: 24px;
        }

        select {
            appearance: none;
            -webkit-appearance: none;
            color: rgba(101, 119, 134, 1);
            cursor: pointer;
            border-radius: 50px;
            background-color: white;
            border-color: white;
            border: none;
            flex: 1;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            padding: 0px 58px 0px 18px;
            text-overflow: ellipsis;
            width: 100%;

            &:hover,
            &:focus {
                cursor: pointer;
                outline: none;
            }
        }

        @media (min-width: $laptop) {
            display: none;
        }
    }

    &__experience-list {
        gap: 0;
        max-width: 740px;
        @include flexbox;
        @include flex-direction(column);
        @include align-items(flex-start);

        @media (min-width: $tablet) {
            gap: 0;
            padding: 0px 12px;
        }

        &+div {
            margin-top: $space-sm;
        }

        &-title {
            color: $omm-blue;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: -0.86px;
            line-height: 1.3;
            margin-bottom: 12px;

            @media (min-width: $tablet) {
                font-size: 28px;
            }
        }

        &-content {
            h3 {

                &+div h4,
                &+div h5,
                &+div h6,
                &+div p,
                &+div ul,
                &+div ol,
                &+div blockquote,
                &+div address {
                    margin-top: 0.8em;
                }
            }

            h4 {

                &+div h5,
                &+div h6,
                &+div p,
                &+div ul,
                &+div ol,
                &+div blockquote,
                &+div address {
                    margin-top: 0.8em;
                }
            }

            h5 {

                &+div h6,
                &+div p,
                &+div ul,
                &+div ol,
                &+div blockquote,
                &+div address {
                    margin-top: 0.8em;
                }
            }

            h6 {

                &+div p,
                &+div ul,
                &+div ol,
                &+div blockquote,
                &+div address {
                    margin-top: 0.8em;
                }
            }

            p,
            ul,
            ol,
            blockquote,
            address {

                &+div h2,
                &+div h3,
                &+div h4,
                &+div h5,
                &+div h6,
                &+div p,
                &+div ul,
                &+div ol,
                &+div blockquote,
                &+div address {
                    margin-top: 0.8em;
                }
            }

            @media (min-width: $tablet) {
                padding-right: 92px;
            }
        }

        .button {
            display: inline-flex;
        }
    }

    .page-hero__intro-content--mobile {
        background-color: white;
        padding: 0px 8px;
        font-size: 18px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.54px;
        text-align: left;
        color: $omm-dark-blue-01;
    }
}