.full-width-slider {
    &__header {
        padding: 0px 24px;
        text-align: center;
        max-width: 679px;
        margin: 0 auto;

        h2 {
            font-size: 28px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: -0.84px;
            color: $omm-blue;
            text-align: center;
        }

        p {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: -0.4px;
            text-align: center;
            margin-bottom: $gutter-md;
        }
    }

    &__slider {

        .swiper-button-next,
        .swiper-button-prev {
            height: 100%;
            top: 22px;
            width: 48px;

            &:after {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 19px;
                background-color: rgba(0, 0, 0, 0.25);
                width: 40px;
                height: 40px;
                border-radius: 40px;
            }
        }

        .swiper-button-next {
            right: 12px;
        }

        .swiper-button-prev {
            left: 12px;
        }

        .swiper-pagination-bullet {
            background: transparent;
            background-color: rgba(255, 255, 255, 0.25);
            opacity: 1;
            width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
            height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));

            &-active {
                border: 1px solid white;
                background: white;
            }
        }

        .swiper-pagination {

            @media (min-width: $tablet) {
                text-align: right;
                padding: 0 87px;
                bottom: 47px !important;
            }
        }
    }

    &__slide {
        background-color: $slate;
        background-position: center;
        background-size: cover;
        z-index: 1200;

        &-gradient {
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), black);
        }

        .container {
            height: 400px;
            padding: 0 87px 45px 87px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
        }

        @media (min-width: $tablet) {
            .container {
                align-items: flex-start;
                height: 680px;
                padding: 47px 47px 47px 47px;
            }
        }

        @media (min-width: $laptop) {
            .container {
                padding: 47px 47px 47px 47px;
            }
        }

        @media (min-width: $desktop) {
            .container {
                padding: 47px;
            }
        }

        &-supertitle {
            font-size: 14px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: -0.72px;
            color: white !important;
            margin-bottom: 7px;

            @media (min-width: $tablet) {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: 0.28px;
                text-align: left;
                color: white;
            }
        }

        &-title {
            font-size: 24px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: -0.72px;
            color: white;
            margin-bottom: 16px;
            text-align: center;

            @media (min-width: $tablet) {
                font-size: 40px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -1.2px;
                text-align: center;
            }
        }

        &-action {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;
            color: white;
            margin-bottom: 8px;
            text-decoration: underline;

            &:hover,
            &:focus,
            &:visited {
                cursor: pointer;
                color: white;
                text-decoration: underline;
            }

            @media (min-width: $tablet) {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.29;
                letter-spacing: -0.28px;
            }
        }

        &-title,
        button {
            z-index: 7000;
        }
    }

    .swiper {
        border-radius: 0;

        @media (min-width: $desktop) {
            border-radius: 10px;
        }
    }
}

.container-fluid .full-width-slider .swiper {
    border-radius: 0;
}