#language-selector {
    background-color: $omm-midnight;
}

.language-selector {
    display: none;
    background-color: $omm-midnight;
    z-index: 1000;

    @media (min-width: 1024px) {
        display: block;
    }

    &__selector-wrapper {
        @extend .container;
        @include gutters();

        @media (min-width: 1024px) {
            justify-content: flex-end;
            align-items: center;
            display: flex;
        }
    }

    &__selector {
        display: block;
        color: white;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.5px;
        text-align: right;
        cursor: pointer;
        padding: 3px 0px;
        text-transform: uppercase;
        transition: all ease-in-out 200ms;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__modal-bg {
        z-index: 5001;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.56);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__modal-wrapper {
        z-index: 5002;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__modal {
        background: white;
        border-radius: 10px;
        border: solid 1px #707070;
        padding: 15px;

        @media (min-width: 1024px) {
            width: 50%;
            min-height: 50%;
            max-height: 90vh;
            padding: 30px;
        }
    }

    &__modal-inner {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__logo {
        background-color: white;
        background-image: url('/img/logo.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 80px;
        width: 200px;
    }

    &__close {
        font-size: 22px;
        text-align: right;
        cursor: pointer;
    }

    &__list {
        margin: 20px 0px;
        width: 50%;
    }


    &__list-label {
        font-weight: bold;
    }

    &__list-selector {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: 1px solid black;
    }

    &__list-item {
        margin-bottom: 10px;
        border: 1px solid black;
        border-radius: 5px;
        padding: 10px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--selected {
            .language-selector__list-selector {
                background: black;
            }
        }
    }

}

.language-toggle__toggle-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    text-decoration: none;

    @media (min-width: $tablet) {
        padding: 0 0 0 16px;
    }

    @media (min-width: $desktop) {
        padding: 0 0 0 33px;
    }

    span {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        color: $slate;
    }

    .toggle {
        margin: 12px 12px;

        @media (min-width: $tablet) {
            margin: 12px 12px;
        }
    }
}

.page-subnavigation .language-toggle__toggle-wrapper {
    padding-right: 16px;

    @media (min-width: $desktop) {
        padding-right: 0px;
    }
}