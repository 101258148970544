.home-action-card {
    background-color: $omm-dark-blue-02;
    border-radius: 10px;
    flex: 1;
    min-height: 400px;
    overflow: hidden;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);

    @media (min-width: $desktop) {
        min-height: 454px;
    }

    &__wrapper {
        @include flexbox();
        @include flex-direction(column);
        gap: $space-xxs;

        @media (min-width: $laptop) {
            @include flex-direction(row);
        }
    }

    &__image {
        flex: 1;
        background-position: center;
        background-size: cover;
    }

    &__heading {
        width: 100%;

        @media (min-width: 550px) {
            width: 60%;
        }

        @media (min-width: $laptop) {
            width: 100%;
        }
    }

    &__content {
        gap: $space-xxs;
        text-align: left;
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        @media (min-width: $desktop) {
            padding: 28px 30px;
            width: 100%;
        }

        p {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;

            @media (min-width: $tablet) {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: -0.4px;
            }
        }

        .button {
            display: inline-flex;
        }
    }

    &__title {
        color: white;
        font-size: 24px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        text-align: left;
        margin-top: 0px;
        line-height: 1.1;

        @media (min-width: $desktop) {
            font-size: 28px;
        }
    }

    &__link {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: $omm-blue;
        text-decoration: underline;
        letter-spacing: -0.4px;
        margin-top: 16px;

        &:hover {
            text-decoration: none;
        }

        @media (min-width: $tablet) {
            font-size: 16px;
        }
    }

    &__text {
        flex: 1;
        color: white;
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        text-align: left;
        margin-top: 0px;
        line-height: 1.1;

        @media (min-width: $desktop) {
            font-size: 12px;
        }
    }

}

.container .home-action-card__wrapper {
    @include gutters();
}

.container-fluid .home-action-card__wrapper {
    @include guttersFullWidth();
}