.split-section {

    @media (min-width: $laptop) {
        @include flexbox();
        @include align-items(flex-start);
        @include justify-content(stretch);
    }

    &--no-padding {
        padding: 0;
    }

    &__heading {
        width: 100%;
        padding: 16px 0 0px 0;

        h2,
        h3 {
            color: $omm-blue !important;
            margin-bottom: 16px;
            font-weight: 300;
            line-height: 1.3;

            @media (min-width: $laptop) {
                margin-bottom: 20px;
            }

            @media (min-width: $desktop) {
                // font-size: 28px;
            }
        }

        @media (min-width: $laptop) {
            max-width: 300px;
            color: $omm-blue;
            flex: 1;
            padding: 0px 0 12px 0;
        }

        &--center {
            text-align: center;

            @media (min-width: $tablet) {
                text-align: left;
            }
        }
    }

    &__content {
        width: 100%;

        @media (min-width: $laptop) {
            flex: 1;
            max-width: 850px;
            margin-left: 60px;
        }

        @media (min-width: $desktop) {
            margin-left: 80px;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            color: $omm-dark-blue-01;
        }

        p {
            color: $slate-dark;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
        }

        ul,
        ol {
            padding-left: $gutter-md;
            margin-left: 0px;
            margin-top: 0;
        }

        li {
            line-height: 1.3;
            margin-bottom: 10px;
            padding-left: 0px;
            position: relative;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.3px;
            text-align: left;

            @media (min-width: $laptop) {
                padding-left: 12px;
            }
        }

    }

    &--border-bottom {
        &:not(:last-of-type) {
            border-bottom: 1px solid $soft-grey-03;
        }
    }

    &__wrapper {
        margin-bottom: 60px;
    }
}

.container .split-section {
    @include gutters();
}

.container-fluid .split-section {
    @include guttersFullWidth();
}