.breadcrumbs {
    padding-top: 12px;
    padding-bottom: 12px;
    @include gutters();

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        display: inline-block;
        font-size: 12px;
        color: $slate;
        justify-content: flex-start;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 600px;

        @media (min-width: $tablet) {
            font-size: 14px;
        }

        &:not(:last-child) {
            &:after {
                flex-shrink: 1;
                content: ">";
                color: $slate;
                width: 10px;
                padding-top: 2px;
                padding-left: 6px;
                padding-right: 6px;
            }
        }

        a {
            color: $slate;
            text-decoration: none;
        }
    }
}

.services__overview .breadcrumbs li {
    &:not(:last-child) {
        &:after {
            padding-right: 6px;
        }
    }
}