.call-to-action-card {
    background-color: $omm-blue-soft-2;
    border-radius: 10px;
    flex: 1;
    min-height: 300px;
    overflow: hidden;
    padding: 24px 20px;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);

    @media (min-width: $tablet) {
        padding: 24px;
    }

    @media (min-width: $laptop) {
        min-height: 335px;
    }

    @media (min-width: $desktop) {
        min-height: 360px;
        padding: 30px 32px;
    }

    &__wrapper {
        @include flexbox();
        @include flex-direction(column);
        gap: $space-xxs;

        @media (min-width: $laptop) {
            @include flex-direction(row);
        }
    }

    &--bottom {
        .call-to-action-card__text {
            flex: 0;
            margin-bottom: 16px;
        }
    }

    &__title {
        color: $omm-blue;
        margin-bottom: 12px;
        font-size: 20px;
        line-height: 1.2;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.71px;
        text-align: left;

        @media (min-width: $tablet) {
            font-size: 28px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
        }

        &--large {
            font-size: 24px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: -0.72px;

            @media (min-width: $tablet) {


                font-size: 40px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.15;
                letter-spacing: -1px;
            }
        }
    }

    &__link {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: $omm-blue;
        text-decoration: underline;
        letter-spacing: -0.4px;
        margin-top: 16px;

        &:hover {
            text-decoration: none;
        }

        @media (min-width: $tablet) {
            font-size: 16px;
        }
    }

    &__text {
        width: 100%;
        margin-bottom: 16px;
        flex: 1;
    }

    &__content {
        flex: 1;
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(flex-end);
        @include align-items(flex-start);
        text-align: left;

        @media (min-width: $laptop) {
            @include align-items(center);
        }
    }
}

.container .call-to-action-card__wrapper {
    @include gutters();
}

.container-fluid .call-to-action-card__wrapper {
    @include guttersFullWidth();
}