.page {
  &-header {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(stretch);
    width: 100%;

    &__container {
      margin-bottom: 0;
      width: 100%;
      margin-top: 24px;
      margin-top: 60px;

      @media (min-width: $tablet) {
        @include flexbox();
        flex-wrap: wrap;
        margin-bottom: 24px;
        @include justify-content(space-between);
      }
    }
  }

  &-title {
    @extend h1;
    color: $omm-blue;
    margin-bottom: 30px;
    text-align: center;

    @media (min-width: $tablet) {
      max-width: 40%;
      text-align: left;
      margin-bottom: 0;
      flex: 1;
    }
  }

  &-filters {
    min-height: 51px;

    &--full {
      border-top: 1px solid $soft-grey-03;
      width: 100%;
      min-width: 100%;
      border-bottom: 1px solid $soft-grey-03;

      .news-block__cards-filter {
        flex: 1;
        min-width: 140px;
        max-width: 200px;
      }
    }

    &--mobile {
      @media (min-width: $tablet) {
        display: none;
      }
    }

    &__container {
      display: none;
      gap: 8px;
      flex-direction: column;
      @include justify-content(flex-end);
      flex: 1;
      padding: 8px 12px;

      .news-block__cards-filter {
        width: 100%;
        max-width: 100%;
      }

      @media (min-width: $tablet) {
        .news-block__cards-filter {
          width: 100%;
          max-width: 100%;
        }

        flex-direction: row;
        display: flex;
        padding: 8px 0;
      }

      &--active {
        display: flex;
      }
    }
  }

  &-search {
    margin-bottom: 45px;

    @media (min-width: $tablet) {
      // max-width: 460px;
      display: flex;
      justify-content: flex-end;
      flex: 1;
      height: 44px;
      margin-bottom: 0;
    }
  }

  &__more-link {
    margin-top: 24px;
    font-size: 14px;
    font-weight: normal;
    color: $omm-blue;
    letter-spacing: -0.42px;
    text-decoration: underline;
    cursor: pointer;

    &:hover,
    &:active {
      text-decoration: none;
    }

    &--white {
      color: white;
    }
  }

  &__content {
    padding: 60px 16px;

    @media (min-width: $desktop) {
      padding: 60px 0;
    }

    h1,
    h2,
    h3,
    h4 {
      color: $omm-dark-blue-02;
      line-height: 1.3;
    }

    h2 {
      @media (min-width: $tablet) {
        font-size: 28px;
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 16px;

      @media (min-width: $tablet) {
        margin-bottom: 32px;
      }
    }

    p {
      color: $slate-dark;
    }

    ul {
      padding-left: 0;
    }

    li {
      line-height: 1.3;
      margin-bottom: 10px;
      list-style: none;
      padding-left: 24px;
      position: relative;

      &:before {
        content: "·";
        position: absolute;
        font-size: 30px;
        left: 0;
        top: 0;
        padding-bottom: 5px;
        height: 50%;
        @include flexbox();
        @include align-items(center);
        line-height: 5px;
      }
    }
  }

  &__breadcrumbs {
    margin: 0;
    padding: 0;
    height: auto;
    @include align-items(center);
    @include flexbox();
    display: none;

    @media (min-width: $laptop) {
      left: auto;
      position: relative;
      top: auto;
      display: flex;
      margin-top: 23px;
      margin-bottom: 0px;
      padding-left: 16px;
    }

    @media (min-width: $desktop) {
      padding-left: 0px;
    }

    li {
      @include flexbox();
      font-size: 12px;
      @include align-items(center);
      justify-content: flex-start;

      &:not(:last-child) {
        &:after {
          flex-shrink: 1;
          content: ">";
          color: black;
          width: 10px;
          padding-top: 2px;
          padding-left: 6px;
        }
      }

      @media (min-width: $laptop) {
        color: black;
        font-size: 12px;

        &:not(:last-child) {
          &:after {
            color: black;
          }
        }
      }

      a {
        color: black;
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;

        @media (min-width: $laptop) {
          color: black;
        }
      }
    }

    &--mobile {
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      @media (min-width: $laptop) {
        display: none;
      }
    }
  }
}

.page-hero {
  @include guttersSmall();

  @include flexbox();
  @include flex-direction(column);
  @include justify-content(space-between);
  overflow: hidden;

  @media (min-width: $laptop) {
    @include flex-direction(row);
    flex-wrap: wrap;
  }

  &--no-margin {
    @media (min-width: $laptop) {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
  }

  &__cards {
    width: 100%;
    margin-top: $space-xxs;
    margin-bottom: $space-xxs;
    padding: 0 $space-xxxs;

    @media (min-width: $tablet) {
      padding: 0 0;
      margin-bottom: 0;
      flex: 1;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(flex-start);
      @include align-items(stretch);
    }

    &--mt-none {
      margin-top: 0;
    }
  }

  &__sidebar {
    @include flexbox();
    flex-wrap: wrap;
    @include justify-content(center);
    @include align-items(flex-start);
    flex-direction: column;
    margin: 0;

    @media (min-width: $laptop) {
      min-width: 360px;
      margin-left: 16px;
      margin-top: 0;
      max-width: 360px;
      @include justify-content(flex-start);
    }

    @media (min-width: $desktop) {
      min-width: 415px;
      max-width: 415px;
    }
  }

  &__overlay-gradient {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__breadcrumbs {
    margin: 0;
    padding: 0;
    height: auto;
    @include align-items(center);
    @include flexbox();
    display: none;

    @media (min-width: $laptop) {
      margin: 16px 0;
      left: auto;
      position: relative;
      top: auto;
      display: flex;
    }

    li {
      @include flexbox();
      font-size: 12px;
      @include align-items(center);
      color: $slate-dark;
      justify-content: flex-start;

      &:not(:last-child) {
        &:after {
          flex-shrink: 1;
          content: ">";
          color: $slate-dark;
          width: 10px;
          padding-top: 2px;
          padding-left: 6px;
        }
      }

      @media (min-width: $tablet) {
        color: $slate-dark;
        font-size: 12px;

        &:not(:last-child) {
          &:after {
            color: $slate-dark;
          }
        }
      }

      a {
        color: $slate-dark;
        letter-spacing: initial;

        @media (min-width: $tablet) {
          color: $slate-dark;
        }
      }
    }

    &--mobile {
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      @media (min-width: $tablet) {
        display: none;
      }
    }
  }

  &__intro {
    @include align-items(stretch);
    color: white;
    @include flexbox();
    flex: 1;
    @include flex-direction(column);

    @media (min-width: $laptop) {
      width: 1px; /* quirk to resolve width busting issue */
    }

    &-content {
      z-index: 2;
      color: black;
      padding: $space-xs;
      font-size: 18px;
      background-color: white;
      font-weight: 300;

      &--location {
        padding: $space-xxs $space-xxs !important;

        @media (min-width: $tablet) {
          padding: $space-sm $space-sm !important;
        }
      }

      &--clear {
        background-color: transparent;
      }

      * {
        margin-bottom: 0px;
      }

      p {
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        line-height: 1.3;

        @media (min-width: $desktop) {
          font-size: 20px;
        }
      }

      a {
        color: black;
        text-decoration: underline;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        line-height: 1.3;

        &:hover {
          text-decoration: none;
          color: white;
        }
      }

      &-link {
        margin-top: 16px;
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.3;
        letter-spacing: -0.28px;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      &--dark {
        background-color: $omm-dark-blue-02;
        color: white;
      }

      @media (min-width: $tablet) {
        color: white;
        padding: 0 $space-sm $space-sm;

        a {
          color: white;
        }
      }

      @media (min-width: $laptop) {
        font-size: 20px;
      }

      &--desktop {
        display: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-left: 0px;

        @media (min-width: $tablet) {
          display: block;
        }
      }

      &--mobile {
        margin-top: 16px;
        border-radius: 10px;

        @media (min-width: $tablet) {
          display: none;
        }
      }
    }

    &--mobile {
      display: flex;
      padding: 13px 0px;
      justify-content: flex-end;
      align-items: flex-start;

      @media (min-width: $laptop) {
        display: none;
      }
    }

    &--top {
      @include verticalSpacingCustom(12px, 12px, 0, 0);
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (min-width: $laptop) {
        justify-content: space-between;
      }
    }
  }

  &__image {
    background: $omm-dark-blue-01;
    border-radius: 10px;
    flex: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 400px;
    width: 100%;
    position: relative;
    overflow: hidden;
    isolation: isolate;

    &--professional {
      min-height: 242px;
    }

    &--gradient {
      &:before {
        position: absolute;
        top: 0;
        content: "";
        border-radius: 10px;
        display: block;
        z-index: -1;
        background: linear-gradient(
          0deg,
          rgba(0, 1, 1, 0) 75%,
          rgba(0, 0, 0, 0.8) 100%
        );
        left: 0;
        width: 100%;
        z-index: 2;
        height: 100%;
      }
    }

    @media (min-width: $tablet) {
      margin-top: 0;
      min-height: 500px;
      @include flexbox();
      padding: 0;
      @include flex-direction(column);
      @include justify-content(space-between);
    }

    @media (min-width: $desktop) {
      min-height: 580px;
    }

    &--top {
      @media (min-width: $tablet) {
        @include justify-content(flex-start);

        .page-hero__heading h1 {
          line-height: 1.14;
          letter-spacing: -0.8px;
          margin-bottom: 14px;
        }

        .page-hero__intro-content {
          padding-top: 0px;
        }
      }
    }

    &--bottom {
      @media (min-width: $tablet) {
        @include justify-content(flex-end);

        .page-hero__heading h1 {
          margin-bottom: 14px;
        }

        .page-hero__intro-content {
          padding-top: 0px;
        }

        .feature-card,
        .feature-card--desktop {
          flex: 0;
        }
      }
    }

    &--top-bottom {
      .feature-card,
      .feature-card--desktop {
        flex: 0;
      }

      @media (min-width: $tablet) {
        @include justify-content(space-between);
      }
    }
  }

  &__video {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    width: 101%;
    height: 101%;
    position: absolute;
    top: 0;
    left: 0;
    max-width: none;
  }

  &__video-player {
    display: block;
    object-fit: cover;
    width: 101%;
    height: 101%;
    position: absolute;
    top: 0;
    left: 0;
    max-width: none;
  }

  &__heading {
    padding: $space-xxs $space-xxs 0;
    position: relative;
    z-index: 2;

    @media (min-width: $tablet) {
      padding: $space-sm $space-sm 0;
    }
  }
}

.page-contacts {
  display: none;
  padding: 0px;
  border-radius: 10px;
  width: 100%;

  @media (min-width: $laptop) {
    @include flexbox();
    @include flex-direction(column);
  }

  @media (min-width: $desktop) {
    margin-bottom: 0px;
  }

  &--no-padding {
    border: none;
    padding: 0px;
  }

  &__heading {
    @extend .caption-title;
    margin-bottom: 0px;
    padding: 8px 16px;
    border-radius: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    height: 30px;
    color: $omm-dark-blue-01;
    background-color: $omm-blue-soft-2;
  }

  &__content {
    padding: 0;
    @include flexbox();
    @include flex-direction(column);
    border-radius: 0 0 5px 5px;
    border: 1px solid $omm-blue-soft-2;
  }

  &--mobile {
    @include flexbox();
    @include flex-direction(column);
    width: auto;
    border: none;
    height: auto;
    @include justify-content(flex-end);
    margin-left: auto;
    margin-right: 0;

    .page-contacts__heading {
      margin-bottom: 16px;
      line-height: 1.2;
    }

    .page-contacts__toggle {
      @extend .caption-title;
      background-color: transparent;
      color: $omm-blue;
      text-transform: none;
      text-decoration: underline;
      padding-bottom: 2px;
      line-height: 1.2;
      height: auto;
      width: 100%;
      border: none;
      @include flexbox();
      @include justify-content(flex-end);
    }

    input {
      display: none;
    }

    input ~ .overlay {
      display: none;
    }

    input:checked ~ .overlay {
      display: block;
    }

    @media (min-width: $laptop) {
      display: none;
    }
  }

  &__all-link {
    display: block;
    padding: 16px 0px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.42px;
    text-align: center;
    color: $omm-blue;
  }
}

.page-contact {
  @include flexbox();
  align-items: flex-start;
  width: 100%;
  padding: $space-xxs;
  margin: 0;

  &:not(:last-child) {
    border-bottom: 1px solid $soft-grey-03;
  }

  &__avatar {
    width: 50px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #d8d8d8;
    border-radius: 50%;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  &__name {
    text-decoration: none;
    color: black;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: -0.5px;
    text-align: left;
    line-height: 1;
  }

  &__location {
    font-size: 12px;
    line-height: normal;
    color: $slate;
    font-weight: 300;

    @media (min-width: $tablet) {
      font-size: 14px;
    }
  }

  &__phone {
    @extend .caption-title;
    font-size: 14px;
    line-height: normal;
    margin-bottom: 0;
    color: $slate;

    a {
      color: $omm-blue;
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @media (min-width: $tablet) {
      font-size: 14px;
    }
  }

  &__info {
    margin-left: $space-xxs;
    @include flexbox();
    @include flex-direction(column);
    justify-content: flex-start;
    flex: 1;
    font-size: 12px;
  }

  &__socials {
    max-width: 30%;

    a {
      margin-left: 4px;
      font-size: 16px;
      text-decoration: none;

      &:hover {
        color: $omm-dark-blue-02;
        text-decoration: underline;
      }
    }

    i {
      font-size: 20px;
      vertical-align: top;
      color: $omm-blue;
    }
  }

  &__actions {
    margin-top: 4px;
    width: 100%;
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(flex-start);
  }

  &__icon {
    margin-left: 10px;
  }
}

// Remove top border on mobile overlay
// .overlay__content .page-contact {
//     border-top: none !important;
// }
