.accolade {
    max-width: 740px;
    padding: 37px 12px;

    border-bottom: 1px solid $omm-grey-soft-3;

    @media (min-width: $tablet) {
        padding: 24px 12px;
    }

    &:first-of-type {
        padding-top: 0px;
    }

    &__title {
        font-size: 20px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.6px;
        text-align: left;
        color: $omm-blue;
        margin-bottom: 12px;

        @media (min-width: $tablet) {
            font-size: 28px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.84px;
            text-align: left;
        }
    }

    &__content {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        text-align: left;
        color: $slate-dark;

        @media (min-width: $tablet) {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;
            text-align: left;
            padding-right: 92px;
        }

        ul {
            padding-left: $gutter-md;
        }
    }

    &__link {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.42px;
        text-align: left;
        color: $omm-blue;

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        @media (min-width: $tablet) {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.42px;
            text-align: left;
            padding-right: 92px;
        }
    }
}