.image-text-block {
    @include flexbox();
    flex-direction: column-reverse;
    gap: $space-sm;
    width: 100%;

    @media (min-width: $laptop) {
        @include flexbox();
        @include align-items(stretch);
        flex-direction: row;
        gap: $space-xxxl;
    }

    &__footer {
        display: flex;
        flex-direction: column;

        @media (min-width: $laptop) {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
        }
    }

    &__title,
    &__title p {
        color: $omm-blue;
        margin-bottom: $space-xxs;
        font-size: 28px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        line-height: 1.2;
        text-align: left;

        @media (min-width: $laptop) {
            font-size: 28px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            line-height: 1.2;
            text-align: left;
            margin-bottom: $space-xs;
        }
    }

    &__super-title {
        @extend .caption-title;
        color: $omm-dark-blue-01;
        display: none;
        font-weight: 400;
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 8px;

        @media (min-width: $laptop) {
            display: block;
        }

        &--mobile {
            @extend .caption-title;
            color: $omm-dark-blue-01;
            display: block;
            margin-top: 0px;
            margin-bottom: 12px;

            @media (min-width: $laptop) {
                display: none;
            }
        }
    }

    &__section {
        @media (min-width: $laptop) {
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(flex-start);
            flex: 1;
        }
    }

    .button {
        min-width: 127px;
        display: inline-flex;
        align-self: center;
        margin: 22px auto 22px;

        @media (min-width: $laptop) {
            min-width: 127px;
            display: inline-flex;
            align-self: flex-start;
            margin: 22px 0 22px;
        }
    }

    &__image {
        flex: 1;
        order: 1;
        width: 100%;

        p {
            text-align: center;
            padding-top: 10px;
        }

        div {
            background-color: $omm-grey-soft-2;
            border-radius: 10px;
            overflow: hidden;
            padding-top: 100%;
            position: relative;

            img {
                height: 100%;
                left: 0;
                object-fit: cover;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        @media (min-width: $laptop) {
            order: unset;
        }
    }

    &--rtl {
        @media (min-width: $laptop) {
            @include flex-direction(row-reverse);
        }
    }

    &__rich-text {
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;

        p,
        li {
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: -0.5px;
        }

        ul {
            margin-top: 0;
            padding-left: 17px;
        }
    }
}

.container .image-text-block__wrapper {
    @include gutters();
}

.container-fluid .image-text-block__wrapper {
    @include guttersFullWidth();
}

.container-fluid .image-text-block__image {
    border-radius: 0px;
}