.case-study-slider {
    margin: 60px 0;
    &__heading {
        color: $omm-blue;
        margin: 35px 0 16px;
        padding: 0 16px;
        @media (min-width: $desktop) {
            padding: 0;
        }
    }
    &__wrapper {
        @include flexbox();
        overflow: auto;
        margin-bottom: 60px;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}
.case-study-categories {
    border-radius: 10px;
    background-color: $omm-blue-soft-1;
    padding: 24px 30px;
    width: 100%;
    height: auto;
    columns: 1;
    @media (min-width: $tablet) {
        min-height: 200px;
        columns: 2;
        padding: 30px 35px;
    }
    @media (min-width: $desktop) {
        columns: 3;
    }
    &__link {
        color: $slate-dark;
        display: block;
        margin-bottom: 12px;
        display: inline-block;
        width: 100%;
        min-width: 300px;
        &-wrap {
            min-width: 300px;
            padding: 2px 20px 2px 0;
        }
        &.active , &:hover {
            color: $omm-blue;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    &__container {
        padding: 0 12px;
        @media (min-width: $desktop) {
           padding: 0;
        }
    }
}
.case-study {
    &__slide {
        padding: 20px 30px 20px 33px;
        min-width: 315px;
        min-height: 400px;
        border-radius: 10px;
        @include flexbox();
        @include flex-direction(column);
        @include align-items(space-between);
        &:not(:last-child) {
            margin-right: 16px;
        }
        @media (min-width: $tablet) {
            min-width: 410px;
            min-height: 450px;
            &:not(:last-child) {
                margin-right: 24px;
            }
        }
        &:nth-child(1n) {
            background-color: $omm-blue-soft-2;
        }
        &:nth-child(2n) {
            background-color: $omm-blue-soft-1;
        }
        &:nth-child(3n) {
            background-color: $omm-blue-soft-3;
        }
    }
    &__content {
        flex: 1;
        color: $omm-blue;
        font-size: 24px;
        font-weight: 300;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: -0.72px;
    }
    &__link {
        color: $omm-blue;
        font-size: 14px;
        text-decoration: underline;
        list-style: 1.29;
        &:hover {
            text-decoration: none;
        }
    }
}