.social-footer {
    &--background {
        background-color: $omm-blue-soft-1;
    }
}

.social-block {
    @include flexbox();
    @include align-items(space-between);
    @include justify-content(flex-start);
    @include flex-direction(column);
    background: $omm-dark-blue-01;
    border-radius: 10px;
    padding: 30px 22px 30px 22px;
    min-height: 280px;
    color: $white;
    width: 100%;
    position: relative;
    overflow: hidden;

    @media (min-width: $tablet) {
        flex: 1;
        min-width: 48%;
        width: 100%;
        padding: 30px;
    }

    @media (min-width: $desktop) {
        padding: 30px;
    }

    &__wrapper {
        @include flexbox();
        @include flex-direction(column);
        flex-wrap: wrap;
        gap: $space-xxs;

        @media (min-width: $laptop) {
            @include flex-direction(row);
        }
    }

    &__header {
        flex: 1;
        z-index: 1;
        line-height: 1.29;
        max-width: 460px;

        @media (min-width: $laptop) {
            max-width: 600px;
        }
    }

    &__title {
        margin-bottom: 8px;
        line-height: 1.29;
        font-size: 24px;
        font-weight: 100;

        @media (min-width: $tablet) {
            font-size: 28px;
            font-weight: 100;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            line-height: 1.29;
            letter-spacing: -0.84px;
            margin-bottom: 16px;
        }
    }

    &__tag {
        z-index: 1;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.12px;
        text-transform: uppercase;
        margin-bottom: 5px;
        color: white;

        @media (min-width: $tablet) {
            margin-bottom: 8px;
        }
    }

    &__content {
        flex: 1;
        font-size: 14px;
        font-weight: 100;
        line-height: 1.29;
        letter-spacing: -0.28px;
        z-index: 1;

        @media (min-width: $tablet) {
            font-size: 16px;
        }
    }

    &__actions {
        @include flexbox();
        @include justify-content(stretch);
        @include flex-direction(column);
        flex-wrap: wrap;
        z-index: 1;
        gap: 16px;

        @media (min-width: $tablet) {
            @include flex-direction(row);
            @include justify-content(flex-start);
        }

        .button {
            // max-width: 180px;
            min-width: 122px;
            margin: unset;

            @media (min-width: $tablet) {
                margin: unset;
            }
        }

        &-tag {
            color: white;
            text-transform: none;
            margin-bottom: 0;
            line-height: 1.29;
            text-align: center;
            letter-spacing: -0.28px;
        }
    }

    &__icon {
        text-decoration: none;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        padding: 5px;
        line-height: 1;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: white;

        i {
            color: $omm-blue;
            font-size: 18px;
            line-height: 1;
        }

        &:hover {
            background-color: $omm-blue-soft-2;
        }

        &-wrapper {
            @include flexbox();
            @include align-items(center);
            @include justify-content(flex-start);
            margin-top: 16px;
            width: 100%;
            gap: 16px;

            @media (min-width: $tablet) {
                @include justify-content(flex-start);
                width: 100%;
            }
        }
    }

    &--small {
        flex: 1;
        width: 100%;

        @media (min-width: $laptop) {
            margin-top: 0;
            min-width: 320px;
            max-width: 415px;
            min-height: 335px;
        }

        @media (min-width: $desktop) {
            padding: 34px;
        }
    }

    &--red {
        background-image: linear-gradient(226deg, #dc4547, $berry 55%);
        background-repeat: no-repeat;
        background-size: cover;
    }

    &--full-width {
        width: 100%;
        min-width: 100%;
    }

    &__image {
        background-position: bottom;
        background-size: cover;

        @media (min-width: $tablet) {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 0;
            opacity: 0.6;
        }
    }

    &__disclaimer {
        width: 100%;
        padding: 0 12px;
        margin-top: $space-xxs;
        margin-bottom: $space-sm;
    }

    &__disclaimer p {
        width: 100%;
        font-size: 12px;
        color: $slate;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.36px;

    }

    &__ranking {
        &-item {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;
            margin-bottom: 30px;

            @media (min-width: $tablet) {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
            }
        }

        &-image {
            width: 100%;
            height: 77px;
            margin-bottom: 16px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            background-color: white;
            flex-shrink: 0;

            @media (min-width: $tablet) {
                margin-bottom: 0;
                margin-right: 24px;
                width: 170px;
                height: 60px;
            }

            @media (min-width: $desktop) {
                margin-right: 32px;
            }
        }
    }

    &--row {
        min-width: 100%;
        width: 100%;
        background-position: right center;
        background-size: 60%;
        background-repeat: no-repeat;
        min-height: 335px;

        .social-block__header {
            flex: 1;
        }

        @media (min-width: $tablet) {
            padding: 32px 32px 28px;
            @include flex-direction(row);
            @include justify-content(space-between);
            min-height: 250px;

            .social-block__actions {
                @include flex-direction(column);
                @include justify-content(space-between);
                @include align-items(flex-end);
            }

            .social-block__icon-wrapper {
                @include justify-content(flex-end);
            }
        }

        @media (min-width: $laptop) {
            padding: 40px 44px 36px;
        }
    }

    &--stay-in-touch {
        width: 100%;
        @include align-items(flex-start);
        @include justify-content(space-between);

        @media (min-width: $tablet) {
            // padding: 45px;
        }

        @media (min-width: $laptop) {
            min-width: 320px;
            max-width: 415px;
        }

        .social-block__title {
            font-size: 22px;
            font-weight: 300;
            font-stretch: normal;
            letter-spacing: -0.84px;
            margin-bottom: 12px;

            @media (min-width: $tablet) {
                font-size: 28px;
                margin-bottom: 24px;
            }
        }

        .social-block__header {
            text-align: center;
            flex: 0;
        }

        .social-block__content {
            text-align: center;
            margin-bottom: 24px;
        }

        .social-block__icon {
            background-color: white;

            i {
                color: $omm-blue;
            }

            &:hover {
                background-color: $omm-accent;

                i {
                    color: white;
                }
            }
        }
    }

    &--twitter {
        padding-bottom: 0;
        padding-right: 0;
        max-height: 350px;
        background-color: $soft-blue-01;
        border-radius: 10px;

        .social-block__header {
            flex: 0;
            margin-bottom: 14px;
        }

        .social-block__content {
            overflow-y: auto;

            /* width */
            &::-webkit-scrollbar {
                width: 4px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background-color: $omm-blue-soft-1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                width: 4px;
                height: 16px;
                opacity: 0.64;
                background-color: $slate-dark;
            }
        }

        .social-block__title {
            z-index: 1;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.12px;
            text-transform: uppercase;
            margin-bottom: 5px;
            color: $slate-dark;

            @media (min-width: $tablet) {
                margin-bottom: 8px;
            }
        }

        @media (min-width: $laptop) {
            padding-right: 0px;
        }
    }

    &--rankings {

        .social-block__header {
            flex: 0;
        }

        min-height: 335px;

        &-wrapper {
            position: relative;
            flex: 1;

            .social-block__disclaimer {
                @media (min-width: $tablet) {
                    position: absolute;
                }
            }
        }
    }

    &--center {
        text-align: center;
        @include justify-content(center);
        padding: 20px 32px 24px;

        .social-block__actions {
            @include justify-content(center);

            .button {
                margin: auto;
            }
        }

        .social-block__header {
            flex: 0;
        }

        .social-block__content {
            flex: 0;
            margin: 8px 0 24px;
            text-align: center;

            @media (min-width: $tablet) {
                margin: 8px 0 32px;
            }
        }
    }

    &__tweet {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $soft-blue-03;
        margin-bottom: 16px;
        padding-right: 35px;

        &-image {
            background-color: white;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 50%;
            width: 39px;
            height: 39px;
            margin-right: 24px;
        }

        &-heading {
            display: flex;
            margin-bottom: 0;
            padding-right: 40px;
        }

        &-title {
            font-size: 20px;
            font-weight: 300;
            font-stretch: normal;
            line-height: 1.2;
            letter-spacing: -0.6px;
            margin-bottom: 4px;
            color: $slate-dark;
        }

        &-titlewrap {
            margin-bottom: 16px;
            flex: 1;

            span {
                display: block;
                margin-bottom: 4px;
                font-size: 14px;
                font-weight: 300;
                line-height: 1.4;
                letter-spacing: 0.36px;
                color: $slate-dark;
            }

            p {
                font-size: 14px;
                font-weight: 300;
                color: $slate-dark;
            }
        }

        &-icon {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 20px;
            height: 20px;
        }
    }
}

.article__components .social-footer {
    padding: 0;
}

.container .social-footer {
    @include gutters();
}

.container-fluid .social-footer {
    @include gutters();
}