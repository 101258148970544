.toggle {
    position: relative;
    width: 60px;
    height: 24px;
    border-radius: 12px;
    background-color: $soft-blue-02;
    border: none;
    cursor: pointer;

    &__indicator {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background-color: $omm-blue;
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &--active {
            transform: translateX(36px);
        }
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;
        clip: 0;
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;
        pointer-events: none;

        // &:checked+.toggle__indicator {
        //     transform: translateX(36px)
        // }
    }

    &.disabled {
        cursor: not-allowed;

        .toggle__indicator {
            background-color: $slate;
        }
    }
}