.actions-hero {
  overflow: hidden;

  &__inner {
    background-color: $slate;
    background-size: cover;
    background-position: center;
    min-height: 400px;
    overflow: hidden;
    padding: 56px 13px 33px 13px;
    position: relative;

    @include flexbox;
    @include align-items(center);
    @include justify-content(center);

    @media (min-width: $tablet) {
      min-height: 680px;
      padding: 35px 37px 30px 37px;
    }
  }

  &__overlay-gradient {
    background: rgba(0, 0, 0, 0.35);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  &__video {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    width: 101%;
    height: 101%;
    position: absolute;
    top: 0;
    left: 0;
    max-width: none;
    z-index: 1;
  }

  &__video-player {
    z-index: 2;
    display: block;
    object-fit: cover;
    width: 101%;
    height: 101%;
    position: absolute;
    top: 0;
    left: 0;
    max-width: none;
  }

  &__content {
    gap: $space-xs;
    height: 100%;
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 3;

    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);

    @media (min-width: $tablet) {
      height: 100%;
      width: 80%;
    }

    @media (min-width: $laptop) {
      height: 100%;
      width: 60%;
    }
  }

  &__title {
    color: white;
    font-size: 28px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.84px;
    line-height: 1.14;
    margin: 0;
    padding: 0 $space-xs;

    @media (min-width: $tablet) {
      font-size: 40px;
      letter-spacing: -1.2px;
      line-height: 1.29;
      padding: 0;
    }
  }

  &__maincontent {
    gap: $space-xxs;
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    padding: 0 $space-xs;

    @media (min-width: $tablet) {
      padding: 0;
    }
  }

  &__description {
    & p {
      color: white;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: -0.54px;
      text-align: inherit;
    }

    @media (min-width: $tablet) {
      & p {
        font-size: 24px;
        line-height: 1.29;
        letter-spacing: -0.84px;
      }
    }
  }

  &__disclaimer {
    & p {
      color: white;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.28px;
      line-height: 1.29;
      text-align: inherit;
    }
  }

  &__actions {
    display: none;

    @media (min-width: $tablet) {
      gap: $space-xxs;
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
    }
  }

  &__actions--mobile {
    gap: $space-xxs;
    @include verticalSpacingCustom($space-22, $space-22, $space-32, $space-32);
    @include flexbox;
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);

    .button {
      min-width: 170px;
    }

    @media (min-width: $tablet) {
      display: none;
    }
  }

  &__action {
    a {
      @extend .button;
    }
  }
}

.container .actions-hero__inner {
  border-radius: 10px;
}

.container .actions-hero__wrapper {
  @include gutters();
}
