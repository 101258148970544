.accordian-section {
    @include gutters;
    gap: 0px;

    @media (min-width: $laptop) {
        @include flexbox();
        @include align-items(flex-start);
        @include justify-content(stretch);
        gap: 60px;
    }

    &__heading {
        width: 100%;
        padding: 16px 0 20px 0;

        h2,
        h3 {
            color: $omm-blue !important;
            margin-bottom: 0px;
            font-weight: 300;
            line-height: 1.3;

            @media (min-width: $laptop) {
                margin-bottom: 0px;
            }

            @media (min-width: $desktop) {
                font-size: 28px;
            }
        }

        @media (min-width: $laptop) {
            min-width: 300px;
            max-width: 300px;
            color: $omm-blue;
            flex: 1;
            padding: 0px 0 12px 0;
        }

        &--center {
            text-align: center;

            @media (min-width: $laptop) {
                text-align: left;
            }
        }
    }

    &__avatar {
        border-radius: 50%;
        width: 70px;
        height: 70px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-bottom: 16px;
        display: block;
        margin: 0 auto 16px;

        @media (min-width: $laptop) {

            margin: 0 0 16px;
        }

    }

    &__title {
        margin-bottom: 0;
    }

    &__caption {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 1.4;
        color: $slate-dark;
    }

    &__quote {
        background-color: $soft-blue-03;
        padding: 40px 24px;
        color: $omm-dark-blue-01;
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.71px;
        text-align: center;
        margin-top: $gutter-md;
        margin-bottom: 0;

        @media (min-width: $laptop) {
            margin-top: $space-72;
            margin-bottom: $space-72;
        }
    }

    &__content {
        width: 100%;
        flex: 1;

        @media (min-width: $laptop) {
            flex: 1;
        }

        @media (min-width: $desktop) {}

        h1,
        h2,
        h3,
        h4,
        h5 {
            color: $omm-dark-blue-01;
            margin-bottom: 8px;
        }

        p {
            color: $slate-dark;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
        }

        ul {
            padding-left: 0;
            margin-left: 0px;
            margin-top: 0;
        }

        li {
            line-height: 1.3;
            margin-bottom: 10px;
            list-style: none;
            padding-left: 16px;
            position: relative;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;

            @media (min-width: $laptop) {
                padding-left: 12px;
            }

            &:before {
                content: "·";
                position: absolute;
                font-size: 34px;
                left: 0;
                top: 0;
                padding-bottom: 5px;
                height: 80%;
                @include flexbox();
                @include align-items(center);
                line-height: 5px;
                color: $omm-blue;

                @media (min-width: $laptop) {
                    color: $slate-dark;
                    font-size: 30px;
                }
            }
        }

    }

    &--border-bottom {
        &:not(:last-of-type) {
            border-bottom: 1px solid $soft-grey-03;
        }
    }

    &__wrapper {
        margin-bottom: 60px;
    }
}

.accordion {
    width: 100%;

    &__toggle {
        padding: 14px 18px 14px 14px;
        border-radius: 10px;
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        color: $omm-dark-blue-01;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        background-color: $omm-blue-soft-1;

        i {
            margin-left: 30px;
            font-size: 22px;
            color: $omm-blue;
            transform: rotate(180deg);
        }

        &:hover {
            cursor: pointer;
            background-color: $omm-blue-soft-1;
        }

        @media (min-width: $laptop) {
            padding: 20px 34px 20px 28px;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: -0.4px;

            i {
                margin-left: 34px;
                font-size: 20px;
            }
        }
    }

    &__container {
        background-color: $omm-blue-soft-1;
        height: 0;
        display: none;
        overflow: hidden;
        padding: 14px 18px 14px 14px;
        transition: height 0.3s ease-in;
        color: $omm-dark-blue-02;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        border-radius: 0 0 10px 10px;

        p {
            color: $omm-dark-blue-02;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
        }

        &.open {
            height: auto;
            display: block;
            color: $omm-dark-blue-02;
        }

        @media (min-width: $laptop) {
            font-size: 16px;
            padding: 20px 34px 20px 28px;
            line-height: 1.38;
            letter-spacing: -0.4px;
        }
    }

    &__item {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 12px;

            @media (min-width: $tablet) {
                margin-bottom: 22px;
            }
        }

        &.active {
            i {
                transform: rotate(0deg);
            }
        }
    }

    .accordion__item.active>div.accordion__toggle {
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid white;

        @media (min-width: $tablet) {
            border-bottom: none;
        }
    }
}