.cookie-policy-page {
    padding: 20px 0px;

    @media (min-width: $tablet) {
        padding: 72px 0px;
    }

    hr {
        margin: 0 0 32px 0;
        @media (min-width: $desktop) {
            margin: 0 0 60px 0;
        }
    }

    .container {
        padding: 0px 22px;

        @media (min-width: $tablet) {
            padding: 0px 30px;
        }
    
        @media (min-width: $desktop) {
            padding: 0px 0px;
        }
    }

    &__title {
        font-size: 28px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: -0.84px;
        margin-bottom: 15px;
        color: $omm-blue;

        @media (min-width: $tablet) {
            margin-bottom: 15px;
            font-size: 40px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.15;
            letter-spacing: -1px;

        }
    }

    &__content {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        color: $slate-dark;

        @media (min-width: $tablet) {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;
        }

        h3 {
            color: $omm-blue;

            @media (min-width: $tablet) {
                font-size: 34px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -1px;
                text-align: left;
                margin-bottom: 26px;
            }
        }

        ul {
            padding-left: 12px;
        }

        li {
            margin-bottom: 14px;

            &::marker {
                font-size: 10px;
                vertical-align: middle;
                line-height: 1;
            }
        }

        margin-bottom: 50px;

        @media (min-width: $tablet) {
            margin-bottom: 70px;
        }
    }

    &__required-header {
        max-width: 849px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        color: $slate-dark;

        @media (min-width: $tablet) {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;
        }

        ul {
            padding-left: 12px;
        }

        li {
            margin-bottom: 14px;

            &::marker {
                font-size: 10px;
                vertical-align: middle;
                line-height: 1;
            }
        }

        margin-bottom: 50px;

        @media (min-width: $tablet) {
            margin-bottom: 70px;
        }

        h3 {
            color: $omm-blue;

            @media (min-width: $tablet) {
                font-size: 34px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -1px;
                text-align: left;
                margin-bottom: 26px;
            }
        }
    }

    &__disclaimer {
        color: $slate;
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
    }

    &__settings {
        padding-top: 32px;
        border-top: 1px solid $omm-grey-soft-3;
        @media (min-width: $tablet) {
            padding-top: 70px;
        }
    }

    &__settings-section {
        margin-bottom: 50px;

        @media (min-width: $tablet) {
            margin-bottom: 70px;
        }

        &--last {
            margin-bottom: 16px;
        }
    }

    &__settings-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
    }

    &__settings-title {
        font-size: 20px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.6px;
        flex: 1;
    }

    &__toggle-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;


        span {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.36px;
            color: $slate;
        }

        .toggle {
            margin: 0 13px;

            @media (min-width: $tablet) {
                margin: 0 15px;
            }
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;

        th {
            padding: 10px 15px;
            border: 1px solid $omm-grey-soft-3;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: -0.4px;
            color: $omm-blue;
            text-align: left;

            @media (min-width: $tablet) {
                padding: 15px 20px;
            }
        }

        td {
            padding: 20px 15px;
            border: 1px solid $omm-grey-soft-3;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;
            color: $slate-dark;

            @media (min-width: $tablet) {
                padding: 25px 20px;
            }
        }

        tr td:first-child {
            width: 180px;

            @media (min-width: $tablet) {
                width: 200px;
            }
        }
    }
}