.navigation-about {
    width: 100%;

    @media (min-width: 1024px) {
        display: flex;
        flex-direction: row;
    }

    &__primary {
        @media (min-width: $laptop) {
            padding: 28px 34px;
            min-width: 261px;
            border-right: 1px solid $omm-grey-soft-3;
        }
    }

    &__primary-title {
        display: block;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: black;
        opacity: 1;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.64px;
        text-align: left;
        padding: 14px 23px;
        border-bottom: 1px solid $soft-grey-03;
        text-decoration: none;

        @media (min-width: 1024px) {
            border: none;
            display: block;
            color: $slate-dark;
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: -0.28px;
            margin-bottom: 12px;
            text-decoration: none;
        }

        &:hover,
        &:focus {
            color: $omm-blue;
        }

        @media (min-width: $laptop) {
            color: $omm-blue;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            margin-bottom: 16px;
            padding: 0;
        }
    }

    &__primary-actions {
        @media (min-width: $laptop) {}
    }

    &__primary-action {
        display: block;
        text-decoration: none;

        display: block;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: black;
        cursor: pointer;
        opacity: 1;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.64px;
        text-align: left;
        padding: 14px 23px;
        border-bottom: 1px solid $soft-grey-03;
        text-decoration: none;

        // &:last-child {
        //     border-bottom: none;
        // }

        @media (min-width: 1024px) {
            border: none;
            display: block;
            color: $slate-dark;
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: -0.28px;
            margin-bottom: 12px;
            text-decoration: none;
        }

        &:hover,
        &:focus {
            color: $omm-blue;
        }


        @media (min-width: $laptop) {
            color: $slate;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            margin-bottom: 16px;
            padding: 0;
        }
    }

    &__secondary {
        @media (min-width: $laptop) {
            padding: 28px 34px;
        }
    }

    &__secondary-actions {
        @media (min-width: $laptop) {}
    }

    &__secondary-action {
        display: block;
        text-decoration: none;

        display: block;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: black;
        cursor: pointer;
        opacity: 1;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.64px;
        text-align: left;
        padding: 14px 23px;
        border-bottom: 1px solid $soft-grey-03;
        text-decoration: none;

        // &:last-child {
        //     border-bottom: none;
        // }

        @media (min-width: 1024px) {
            border: none;
            display: block;
            color: $slate-dark;
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: -0.28px;
            margin-bottom: 12px;
            text-decoration: none;
            padding: 0;
        }

        &:hover,
        &:focus {
            color: $omm-blue;
        }


        @media (min-width: $laptop) {
            color: $slate-dark;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            margin-bottom: 16px;
        }
    }
}