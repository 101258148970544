.news {
    @include gutters();
    @include verticalSpacingCustom(22px, 22px, 56px, 56px);

    &--category {
        padding-bottom: $space-sm;
    }
}

.news-filters {
    background-color: white;
    z-index: 2000;

    @media (min-width: $tablet) {
        display: none;
    }

    .container {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(center);
        height: 42px;
    }

    border-top: 1px solid white;
    border-bottom: 1px solid $soft-grey-03;

    &__filter {
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        align-self: stretch;
        flex: 1;

        @media (min-width: $tablet) {
            display: none;
        }

        span {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: -0.24px;
            text-align: left;
            color: $omm-blue;
        }

        i {
            font-size: 16px;
            color: $omm-blue;
            margin-right: 17px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__filter-dropdown {
        @include flexbox;
        @include gutters;
        @include justify-content(space-between);
        @include align-items(center);
        width: 100%;

        span {
            margin: 0;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
        }

        i {
            font-size: 24px;
            margin-right: 0;
        }

        @media (min-width: $tablet) {
            display: none;
        }
    }

    &__filter-dropdown-content {
        @include flexbox;
        @include justify-content(flex-start);
        @include align-items(center);
        flex-direction: column;
        gap: 12px;
        z-index: 9400;
        border-bottom: 1px solid $soft-grey-03;
        background-color: white;
        padding: 12px;
        width: 100%;
        margin-bottom: 16px;

        @media (min-width: $tablet) {
            display: none;
        }

        .news-block__cards-filter {
            width: 100%;
            max-width: 100%;
        }
    }
}