.navigation-insights {
    flex: 1;

    &__filters {
        width: 100%;
        overflow-x: scroll;
        display: flex;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 23px;
        justify-content: flex-start;
        gap: 5px;
        align-items: center;


        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        @media (min-width: 1024px) {
            padding: 22px 25px;
            border-bottom: 1px solid $omm-grey-soft-3;
        }

        &-title {
            display: none;

            @media (min-width: 1024px) {
                display: inline-block;
                color: $slate-dark;
                font-size: 14px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -0.5px;
                text-align: left;
                margin-right: 18px;
            }
        }
    }

    &__view-all {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 23px 0;
    }

    &__view-all-button {
        display: inline-flex;
        text-transform: none;
    }

    &__filter-button {
        border-radius: 120px;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.5px;
        border: none;
        border-color: transparent;
        background-color: $omm-blue-soft-1;
        color: $omm-dark-blue-01;
        cursor: pointer;
        padding: $space-xxxs $space-xxs;

        &:hover,
        &:focus {
            background-color: $omm-blue;
            color: white;

            @media (min-width: 1024px) {
                color: $omm-blue;
                background-color: $omm-blue-soft-3;
            }
        }

        &--active {
            background-color: $omm-blue;
            color: white;

            @media (min-width: 1024px) {
                color: $omm-blue;
                background-color: $omm-blue-soft-3;
            }
        }
    }

    &__results {
        padding: 23px 23px 0 23px;

        @media (min-width: 1024px) {
            padding: 0px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__result-section {


        @media (min-width: 1024px) {
            padding: 23px 23px 0 23px;
            width: 50%;

            &:first-of-type {
                // border-right: 1px solid $omm-grey-soft-3;
            }
        }



    }

    &__item {
        text-decoration: none;

        &:hover,
        &:focus {
            .navigation-insights__item-title {
                color: $omm-blue;
                text-decoration: underline;
            }
        }
    }

    &__item-meta {
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.3px;
        color: $slate;
        margin-bottom: 9px;

        span {
            margin-right: 24px;
        }

        i {
            margin-left: 4px;
            color: $soft-grey-03;
            display: none;
        }
    }

    &__item-title {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -0.28px;
        color: $slate-dark;
        margin-bottom: 23px;
        max-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (min-width: 1024px) {
            height: 40px;
            margin-bottom: 23px;
        }
    }

    &__link {
        display: block;
        color: $omm-midnight;
        padding: 10px 0px;
        cursor: pointer;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
    }

    &__hubs {
        display: block;

        @media (min-width: 1024px) {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 28px 24px;
            gap: 16px;
        }
    }

    &__hub {
        text-decoration: none;
        color: black;
        padding: 14px 22px;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.64px;
        text-align: left;
        border-bottom: 1px solid $omm-grey-soft-3;
        display: block;

        @media (max-width: 1024px) {
            background-image: none !important;
        }

        @media (min-width: 1024px) {
            span {
                display: none;
            }

            flex: 1;
            max-width: 315px;
            background-color: $slate;
            height: 191px;
            border-radius: 5px;
            background-position: center;
            background-size: cover;
        }
    }
}