.pagination {
    @include topSpacingSmall();

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;

    &__link {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23px;
        height: 23px;
        border-radius: 23px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -0.28px;
        text-align: left;
        color: $slate;

        &:hover,
        &:focus {
            background-color: #f4f7fc;
        }

        &--active {
            color: $omm-blue;
            background-color: #f4f7fc;
        }
    }
}