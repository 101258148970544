.feature-card {
  flex-direction: column;
  gap: $space-xxs;
  z-index: 4000;

  &--desktop {
    display: none;

    @media (min-width: $tablet) {
      display: flex;
      justify-content: flex-end;
      margin-top: auto;
      padding: $space-sm;
    }

    .feature-card__item {
      max-width: 430px;

      &--full {
        max-width: none;
      }
    }
  }

  &--mobile {
    display: flex;
    padding: 0 $space-xxxs;

    @media (min-width: $tablet) {
      display: none;
    }

    .feature-card__item {
      max-width: none;
    }
  }

  &__item {
    align-items: center;
    display: flex;
    border-radius: $radius-sm;
    box-shadow: 1px 3px 11px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    height: 92px;
    overflow: hidden;
    width: 100%;
  }

  &__image {
    background-color: $slate-dark;
    background-position: center;
    background-size: cover;
    height: 100%;
    min-width: 106px;
    width: 106px;
  }

  &__content {
    padding: $space-xxs;
    width: 100%;
  }

  &__title {
    color: black;
    display: -webkit-box;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.28px;
    overflow: hidden;
    text-align: left;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    & + .feature-card__action {
      margin-top: 4px;
    }

    &--with-action {
      -webkit-line-clamp: 2;
    }
  }

  &__action {
    color: $omm-blue !important;
    display: block;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.42px;
    overflow: hidden;
    text-align: left;
    text-decoration: underline;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
