.container .home-page-news {
    @include gutters();
}

.container-fluid .home-page-news {
    @include guttersFullWidth();
}

.home-page-news .tagged-news__section .news-block--image {
    min-height: 200px;
    padding: 0 !important;
}

.home-page-news .tagged-news__section .tagged-news {
    min-height: 200px;
    padding: 0 !important;
    background-position: top center;
    background-size: cover;
}

.home-page-news .tagged-news__section:first-of-type .news-block--image {
    flex: 1;
}