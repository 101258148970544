.simple-cta-card {
    border-radius: 10px;
    background-color: $omm-dark-blue-02;
    color: white;
    max-width: 100%;
    padding: 22px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    min-height: 400px;
    width: 100%;

    @media (min-width: $laptop) {
        padding: 36px 26px 32px 44px;
    }

    &__wrapper {}

    &__title {
        color: white;
        @extend h1;
    }

    &__content {
        flex: 1;
    }

    &__actions {
        @include flexbox();
        @include align-items(center);
        @include flex-direction(column);
        width: 100%;

        a {
            margin-top: 12px;
        }

        @media (min-width: $tablet) {
            @include justify-content(flex-end);
            @include flex-direction(row);

            a {
                margin-left: 12px;
                margin-top: 0;
            }
        }
    }
}

.container .simple-cta-card__wrapper {
    @include gutters();
}

.container-fluid .simple-cta-card__wrapper {
    @include guttersFullWidth();
}