.services {
    padding: 50px 0px;

    &__page-title {
        font-size: 40px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.15;
        letter-spacing: -1px;
        text-align: left;
        color: $omm-blue;
        margin: 0;
    }

    &__header {
        padding: 15px 0;
        border-bottom: 1px solid $soft-grey-03;

        .container {
            @include flexbox;
            @include justify-content(space-between);
            @include align-items(center);
        }
    }

    &__filters {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(center);
        gap: 5px;
    }

    &__filter-button {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.5px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        background-color: white;
        border-radius: 120px;
        border: 2px solid white;
        padding: 0 15px;
        cursor: pointer;
        margin-right: 5px;
        min-width: 110px;

        &:hover,
        &:focus {
            border-color: $omm-blue;
        }

        &.active {
            color: $omm-blue;
            border-color: $omm-blue;

            &:hover,
            &:focus {
                border-color: $omm-blue;
            }
        }
    }

    &__input-wrapper {
        cursor: pointer;
        overflow: hidden;
        position: relative;
        width: 100%;

        @media (min-width: $tablet) {
            min-width: 300px;
        }

        input[type="text"] {
            background-color: $soft-blue-01;
            border: solid 1px $soft-grey-03;
            border-radius: 50px;
            color: $omm-dark-blue-01;
            font-size: 14px;
            font-weight: 300;
            line-height: 1.3;
            // needed height due to FireFox quirk with input height
            height: 36px;
            padding: $space-xxxs $space-sm $space-xxxs $space-xxs;
            text-overflow: ellipsis;
            transition: background-color 200ms ease-in-out;
            width: 100%;

            &::placeholder {
                color: $omm-dark-blue-01;
                opacity: 1;
            }

            &:focus {
                outline: none;
            }
        }

        button {
            background-color: transparent;
            border: none;
            color: $omm-blue;
            cursor: pointer;
            font-size: 18px;
            line-height: 0;
            padding: 0 $space-xxs;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: color 200ms ease-in-out;

            &:hover,
            &:focus {
                color: $omm-blue;
            }
        }
    }

    &__categories {
        &:not(:first-of-type) {
            border-bottom: 1px solid $omm-grey-soft-3;
        }

        .container {
            @include flexbox;
            @include justify-content(flex-start);
            @include align-items(flex-start);
        }
    }

    &__category-list {
        padding: 29px 0;

        &--first-level {
            width: 35%;
            border-right: 1px solid $soft-grey-03;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;

                li {
                    cursor: pointer;
                    color: $slate;
                    font-size: 20px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: -0.6px;
                    text-align: left;
                    margin-bottom: 29px;
                    width: 100%;

                    @media (min-width: $tablet) {
                        width: 50%;
                    }

                    @media (min-width: $laptop) {
                        width: 25%;
                    }

                    &.active {
                        color: $omm-blue;
                        text-decoration: underline;
                    }
                }
            }
        }

        &--second-level {
            flex: 1;
            padding-left: 0px;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;

                li {
                    width: auto;
                    cursor: pointer;
                    color: $slate-dark;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.29;
                    letter-spacing: -0.28px;
                    text-align: left;
                    padding: 7px 14px;
                    width: 100%;

                    @media (min-width: $tablet) {
                        width: 50%;
                    }

                    @media (min-width: $laptop) {
                        width: 25%;
                    }

                    &.active {
                        background-color: $omm-blue-soft-2;
                        color: $omm-blue;
                        text-decoration: none;
                        border-radius: 14px;
                    }
                }
            }
        }
    }

    &__section {
        padding: 0px 12px 0px 12px;

        @media (min-width: $tablet) {
            padding: 0px 12px 0px 12px;
        }

        @media (min-width: $tablet) {
            padding: 0px 0px 0px 0px;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 16px;
            height: 30px;
            margin: 0px 0px 0px 0px;
            border-radius: 5px;
            background-color: $soft-blue-02;
        }

        &-title {
            font-size: 12px;
            line-height: 1.1;
            letter-spacing: 0.12px;
            text-align: left;
            text-transform: uppercase;
            color: $omm-dark-blue-02;
        }

        &-view {
            font-size: 12px;
            letter-spacing: -0.36px;
            text-align: right;
            color: $omm-blue;
            text-decoration: underline;

            a {
                font-size: 12px;
                letter-spacing: -0.36px;
                text-align: right;
                color: $omm-blue;
                text-decoration: underline;
            }
        }
    }

    &__overview-title {
        padding: 32px 16px;
        font-size: 24px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.72px;
        color: $omm-dark-blue-01;
        margin-bottom: 82px;
    }

    &__overview {
        @include gutters();
        padding: $space-xxs 0px;

        .container {
            @include flexbox;
            @include justify-content(flex-start);
            @include align-items(flex-start);
            gap: 51px;
        }
    }

    &__overview-details {
        flex: 1;

        .button {
            margin-left: 16px;
        }
    }

    &__overview-contacts {
        width: 416px;
    }

    &__directory {
        margin: 14px 0;
        background-color: $omm-blue-soft-1;
        border-radius: 5px;
        overflow: hidden;
    }

    &__alpha-toggle {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.12px;
        text-align: center;
        width: 32px;
        height: 32px;
        border: solid 1px $soft-grey-03;
        border-radius: 80px;
        background-color: $soft-blue-02;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 200ms ease-in-out;
        color: $omm-blue;

        &--active {
            background-color: $omm-blue;
            color: white;
        }
    }

    &__directory-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 60px 0px;
        border-bottom: 1px solid $soft-grey-03;
    }

    &__directory-alpha {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    &__directory-alpha-item {
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        color: black;
        padding: 8px 0px;
        flex: 1;
        text-align: center;

        &:hover,
        &:focus {
            cursor: pointer;
            color: $omm-blue;
            background-color: $omm-blue-soft-3;
        }

        &--active {
            color: $omm-blue;
            font-weight: bold;
            background-color: $omm-blue-soft-3;
        }
    }

    &__directory-list {
        flex: 1;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $slate-dark;

        ul {
            margin: 0;
        }

        li {
            list-style: none;
        }

        a {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            text-decoration: none;
            color: $slate-dark;
        }
    }

    &__contact-image {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background-color: $omm-blue;
        background-size: cover;
        background-position: center;
    }
}

.services-landing {
    &__header {
        @include gutters();
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;

        @media (min-width: $tablet) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
        }

        .button {
            margin-bottom: -13px;
            margin-top: $space-xxs;

            @media (min-width: $tablet) {
                margin-bottom: $space-xxs;
            }
        }
    }

    &__empty {
        font-size: 20px;
        font-stretch: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.6px;
        text-align: center;
        color: $slate;
        padding: 30px 0;
        flex: 1;
    }

    &--mobile {
        display: block;

        @media (min-width: $laptop) {
            display: none;
        }
    }

    &--desktop {
        display: none;

        @media (min-width: $laptop) {
            display: block;
        }

        .breadcrumbs {
            margin-bottom: 56px;
            padding: 0;

            li a {
                color: black;
                font-size: 12px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -0.3px;
                text-align: left;
            }
        }
    }

    .loading-indicator {
        margin: 50px 0;
    }

    &__page-title {
        color: $omm-blue;
        font-size: 28px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        text-align: left;
        margin-top: 31px;
        margin-bottom: 7px;

        @media (min-width: $laptop) {
            font-size: 40px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
            margin-top: 79px;
            margin-bottom: 15px;
        }
    }

    .page-subnavigation {
        border-top: 1px solid $soft-grey-03;
    }

    .services-landing__accordian .page-subnavigation {
        border-top: none;
    }

    .services-landing__accordian--regions .services-landing__second-level-desktop-wrapper>ul {
        column-count: 1;
    }

    &__input-wrapper {
        padding: 0px 8px;
        position: relative;
        width: 100%;
        margin-bottom: 27px;

        input[type="text"] {
            height: 42px;
            border-radius: 30px;
            border: 1px solid $soft-blue-03;
            padding: 0px 20px;
            display: block;
            width: 100%;
            background-color: $soft-blue-02;
            color: $omm-blue;
            margin-bottom: 0px;
            outline: none;
            transition: background-color 200ms ease-in-out;
            font-size: 12px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;

            &::placeholder {
                color: $omm-blue;
            }

            @media (min-width: $laptop) {
                padding: 0px 20px;
            }

            &:focus {
                background-color: $soft-blue-02;
                border: 1px solid $soft-blue-03;
                outline: none;
            }
        }

        button {
            background-color: transparent;
            border: none;
            color: $omm-blue;
            font-size: 16px;
            height: 42px;
            width: 25px;
            right: 24px;
            top: 0;
            position: absolute;
            cursor: pointer;
            transition: color 200ms ease-in-out;

            &:hover,
            &:focus {
                color: $omm-blue;
            }
        }

        &--small {
            @media (min-width: $laptop) {
                input[type="text"] {
                    height: 42px;
                }

                button {
                    height: 44px;
                    font-size: 18px;
                }
            }
        }
    }

    &__filters {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        align-items: center;
        padding: 0 15px 0 15px;
        margin-bottom: 37px;
    }

    &__filter-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 29px;
        border-radius: 120px;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.5px;
        border: none;
        border-color: transparent;
        background-color: $omm-blue-soft-1;
        color: $omm-dark-blue-01;
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: $omm-blue;
            color: white;
        }

        &--active {
            background-color: $omm-blue;
            color: white;
        }
    }

    &__accordian-children {
        padding-top: 23px;
    }

    &__first-level-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 14px 22px;
        border-bottom: 1px solid $omm-grey-soft-3;
        cursor: pointer;

        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.64px;
        text-align: left;

        i {
            font-size: 22px;
            color: $omm-blue;
        }
    }

    &__first-level-desktop-wrapper {
        padding: 24px 14px;
        border-bottom: 1px solid $soft-grey-03;
    }

    &__second-level-item {
        position: relative;
        width: 100%;
        padding: 8px 20px 8px 52px;
        color: black;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        cursor: pointer;

        a {}

        i {
            position: absolute;
            left: 22px;
            top: 10px;
            font-size: 14px;
            margin-right: 18px;
            color: $omm-blue;
        }

        &--mobile {
            padding: 8px 20px 8px 36px;
        }
    }

    &__second-level-desktop-wrapper {
        border-bottom: 1px solid $soft-grey-03;
        padding: $space-xs $gutter-md;

        ul {
            column-count: 2;
            column-gap: $space-lg;
            list-style: none;
            margin: 0 auto;
            max-width: 1280px;
            padding: 0;

            li:not(:first-child) {
                margin-top: $space-xxxs;
            }

            @media (min-width: $desktop) {
                column-count: 3;
            }
        }
    }

    &__second-level-desktop-item {
        display: flex;
        align-items: center;

        &-inner {
            cursor: pointer;
            display: inline-block;
            align-items: center;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.3px;
            padding: calc($space-xxxs / 2) $space-xxs;

            &--active {
                background-color: $omm-blue-soft-3;
                border-radius: 42px;
                color: $omm-blue;
            }

            &:hover {
                span {
                    text-decoration: underline;
                }

                .services-landing__translations {
                    text-decoration: none;
                }
            }
        }
    }

    &__second-level-wrapper {
        margin-top: 31px;
        margin-bottom: 31px;

        .button {
            margin: 0 52px 41px 52px;
            display: inline-flex;
        }

        hr {
            margin: 0 0 28px 0;
        }
    }

    &__second-level-inner {
        border-bottom: 1px solid $omm-grey-soft-3;
    }

    &__third-level-item {
        position: relative;
        width: 100%;
        padding: 8px 52px;
        color: $slate;
        cursor: pointer;

        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        display: block;
        text-decoration: none;

        i {
            position: absolute;
            left: 22px;
            top: 8px;
            font-size: 18px;
            margin-right: 18px;
            color: $omm-blue;
        }
    }

    &__third-level-wrapper {
        margin-bottom: 24px;
    }

    &__third-level-desktop-wrapper {
        border-bottom: 1px solid $soft-grey-03;
        padding: $space-xs $gutter-md;

        ul {
            column-count: 2;
            column-gap: $space-lg;
            list-style: none;
            margin: 0 auto;
            max-width: 1280px;
            padding: 0;

            li:not(:first-child) {
                margin-top: $space-xxxs;
            }

            @media (min-width: $desktop) {
                column-count: 3;
            }
        }
    }

    &__third-level-desktop-item {
        &-inner {
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            padding: calc($space-xxxs / 2) $space-xxs;

            &--active {
                background-color: $omm-blue-soft-3;
                border-radius: 42px;
                color: $omm-blue;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__alpha-desktop-wrapper {
        padding: 24px 14px;
        border-bottom: 1px solid $soft-grey-03;
    }

    &__group {
        padding: 24px 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $soft-grey-03;
        }

        &-header {
            color: $omm-blue;
            font-size: 28px;
            font-weight: 300;
            line-height: 1.29;
            padding: 0 16px;
        }

        &-items {
            column-count: 2;
            column-gap: $space-lg;
            list-style: none;
            margin: 0 auto;
            max-width: 1280px;
            padding: 0;
        }
    }

    &__alpha-dropdown {
        &--wrapper {
            padding: 0 20px;
        }

        margin-top: 32px;
        width: 100%;
        height: 46px;
        max-width: unset;
        position: relative;
        border-radius: 42px;
        background-color: white;
        border: solid 1px $soft-grey-03;

        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        cursor: pointer;

        i {
            color: $omm-blue;
            padding: 0px 18px;
            position: absolute;
            right: 0;
            top: 0;
            height: 46px;
            line-height: 46px;
            vertical-align: middle;
            pointer-events: none;
            font-size: 24px;
        }

        select {
            appearance: none;
            -webkit-appearance: none;
            color: rgba(101, 119, 134, 1);
            cursor: pointer;
            border-radius: 50px;
            background-color: white;
            border-color: white;
            border: none;
            flex: 1;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            padding: 0px 58px 0px 18px;
            text-overflow: ellipsis;
            width: 100%;
            height: 46px;

            &:hover,
            &:focus {
                cursor: pointer;
                outline: none;
            }
        }

        @media (min-width: $laptop) {
            display: none;
        }
    }

    &__translations {
        vertical-align: middle;
        margin-left: 14px;
        color: $slate;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
    }
}