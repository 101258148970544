.rich-text-page {
    padding: 0 12px;
    margin-top: 36px;

    @media (min-width: $tablet) {
        padding: 0 12px;
        margin-top: 72px;
    }

    &__title {
        font-size: 28px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: -0.84px;
        margin-bottom: 52px;
        color: $omm-blue;

        @media (min-width: $tablet) {
            font-size: 40px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.15;
            letter-spacing: -1px;

        }
    }

    &__left {}

    &__block {

        @media (min-width: $tablet) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        margin-right: auto;

        &--23 {
            @media (min-width: $tablet) {
                width: 66%;
            }
        }

        &--13 {
            @media (min-width: $tablet) {
                width: 33%;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $omm-blue;
        }

        a {
            color: $omm-accent;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        ul {
            padding-left: 16px;
        }

        .call-to-action-card {

            margin-left: 0;

            @media (min-width: $tablet) {
                margin-left: 124px;
            }

            min-width: 100%;
            max-width: 416px;
            margin-top: 37px;

            @media (min-width: $tablet) {
                min-width: 416px;
                margin-top: 0px;
            }

            .button {
                color: white;
                display: inline-flex;
                align-self: center
            }
        }
    }


}

.container .rich-text-page__block {
    @include gutters();
}

.container-fluid .rich-text-page__block {
    @include guttersFullWidth();
}