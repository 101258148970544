.link-list {
    &__title {
        color: $omm-blue;
        font-size: 24px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.86px;
        text-align: left;
        margin-bottom: 18px;

        @media (min-width: $desktop) {
            padding: 0px;
            font-size: 40px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -1.2px;
            text-align: left;
            color: $omm-blue;
            margin-bottom: 18px;
        }
    }

    &__caption {
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        margin-bottom: 25px;

        @media (min-width: $desktop) {
            max-width: 849px;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            margin-bottom: 25px;
        }

    }

    &__list {}

    &__list-item {
        color: black;
        display: flex;
        align-items: flex-start;
        gap: 16px;
        width: 100%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        text-decoration: none;
        line-height: 1.43;
        letter-spacing: -0.28px;
        text-align: left;
        padding: 18px 16px;
        border-bottom: 1px solid $soft-grey-03;

        @media (min-width: $desktop) {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            padding: 18px 0px;
        }

        span {
            flex: 1;
        }

        i {
            color: $omm-blue;
            font-size: 19px;
        }

        &:hover,
        &:focus {
            span {
                color: $omm-blue;
                text-decoration: underline;
            }
        }
    }

    &__action {
        margin-left: 15px;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        text-decoration: underline;
        display: block;
        color: $omm-blue;
        margin-top: 23px;

        @media (min-width: $desktop) {
            margin-left: 0px;
            margin-top: 39px;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: -0.4px;
            text-align: left;
        }
    }
}

.list-grid {
    max-width: 100%;

    &__heading {
        color: $omm-blue;
        font-size: 24px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.86px;
        text-align: left;
        margin-bottom: 19px;

        @media (min-width: $tablet) {
            font-size: 40px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
        }
    }

    &__content {
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.3px;
        text-align: left;
        margin-bottom: $gutter-lg;

        @media (min-width: $tablet) {
            padding: 0 0px;
        }
    }

    &__wrapper {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 0 40px;

        ul,
        ol {
            margin-bottom: 0;
            margin-top: 0;
            padding-left: 16px;

            li {
                width: 100%;

                @media (min-width: $tablet) {
                    width: 100%;
                }
            }

            @media (min-width: $laptop) {
                max-width: calc(33.333% - 40px);
            }
        }
    }

    &__action {
        margin-top: 35px;
        width: 100%;

        a {
            max-width: 300px;
        }
    }

    &__link {
        max-width: 100%;
        display: flex;
        align-items: center;
        text-decoration: none;
        border-bottom: 1px solid $omm-grey-soft-3;
        padding: 8px 16px;

        a {
            color: $omm-blue;
            line-height: 1.3;
            display: inline-block;
            text-decoration: none;
        }

        &-title {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            color: black;
            margin-bottom: 0;
            line-height: 1;
        }

        &-icon {
            font-size: 20px;
            color: $omm-blue;
            margin-right: 16px;
            margin-bottom: 0;

            @media (min-width: $tablet) {
                display: block;
            }
        }

        @media (min-width: $tablet) {
            // max-width: 50%;
            padding: 4px 0;
        }

        @media (min-width: $desktop) {
            // max-width: 33%;
            min-width: 400px;
        }
    }

}

.container .link-list,
.container .list-grid {
    @include gutters();
}

.container-fluid .link-list,
.container-fluid .list-grid {
    @include guttersFullWidth();
}