.image-caption-slider {
    &__heading {
        color: $omm-blue;
        font-size: 28px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        line-height: 1.14;
        margin-bottom: $space-xs;
        padding: 0 $gutter-sm;

        @media (min-width: $laptop) {
            font-size: 40px;
            margin-bottom: $space-sm;
        }

        @media (min-width: $desktop) {
            padding: 0;
        }
    }

    &__no-scroll-wrapper {
        display: grid;
        gap: $space-lg;
        grid-template-columns: 1fr;
        padding: 0 $gutter-sm;

        @media (min-width: $tablet) {
            gap: $space-xxs;
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: $laptop) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media (min-width: $desktop) {
            padding: 0;
        }

        .image-caption {
            margin: 0;
            width: auto;

            @media (min-width: $tablet) {
                min-width: 0;
                width: auto;
            }

            @media (min-width: $laptop) {
                min-width: 0;
            }

            &:not(:last-child) {
                @media (min-width: $tablet) {
                    margin: 0;
                }
            }

            &__container {
                padding: $space-xxs 0 0;
            }
        }
    }
}

.image-caption {
    margin: 0 auto;
    width: 100%;

    @media (min-width: $tablet) {
        width: calc(50% - $space-sm);
        // min-width: 245px;
    }

    @media (min-width: $laptop) {
        width: calc(50% - $space-sm);
        // min-width: 245px;
    }

    @media (min-width: $desktop) {
        width: calc(100% - $space-sm);
        min-width: 308px;
    }

    &:not(:last-child) {
        @media (min-width: $tablet) {
            margin-right: $space-xxs;
        }
    }

    &__image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: $soft-grey-03;
        border-radius: 10px;
        padding-top: 56.25%;
        /* 16:9 */
        width: 100%;
    }

    &__wrapper {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        @include gutters;

        @media (min-width: $tablet) {
            overflow: auto;
            @include flex-direction(row);
            @include align-items(flex-start);
            margin-bottom: 60px;

            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }
    }

    &__container {
        padding: $space-xxs 0 $space-lg;
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(stretch);
        width: 100%;

        @media (min-width: $laptop) {
            padding: $space-xxs 0;
        }
    }

    &__title {
        color: $slate;
        font-size: 14px;
        font-weight: normal;
        width: 100%;
        line-height: 1.43;
        text-align: center;
        letter-spacing: -0.28px;
    }

    &--left {
        .image-caption__title {
            text-align: left;
        }
    }
}

.container .image-caption-slider__heading {
    @include gutters();
}

.container-fluid .image-caption-slider__heading {
    @include guttersFullWidth();
}

.container .image-caption-slider--static {
    @include gutters();
}

.container-fluid .image-caption-slider--static {
    @include guttersFullWidth();
}