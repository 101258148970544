.navigation-search {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    h4 {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4;
        letter-spacing: -0.5px;
        color: $omm-blue;
        margin-bottom: 8px;
        margin-top: 0;
        margin-bottom: 8px;
    }

    &__searched {
        align-self: stretch;
        padding: 17px 35px;
        width: 300px;
        min-width: 300px;
        border-right: 1px solid $omm-grey-soft-3;
        display: flex;
        flex-direction: column;
    }

    &__form-wrapper {
        align-self: stretch;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;
        // border-right: 1px solid $omm-grey-soft-3;
    }

    &__form {
        padding: 8px 23px;
    }

    &__input-wrapper {
        padding: 0px 0px;
        position: relative;
        max-width: 956px;
        margin: 0 auto;

        input[type="text"] {
            height: 42px;
            border-radius: 42px;
            padding: 0px 18px;
            display: block;
            width: 100%;
            background-color: $soft-blue-01;
            border-radius: 42px;
            border: solid 1px #e2ebf6;
            color: $omm-midnight;
            margin-bottom: 0px;
            outline: none;
            transition: background-color 200ms ease-in-out;
            font-size: 14px;
            letter-spacing: -0.48px;
            color: $slate-dark;

            @media (min-width: $tablet) {
                padding: 0px 18px;
            }

            &:focus {
                outline: none;
            }
        }

        button {
            background-color: transparent;
            border: none;
            color: $omm-blue;
            font-size: 22px;
            height: 42px;
            line-height: 42px;
            width: 40px;
            right: 10px;
            top: 0;
            position: absolute;
            cursor: pointer;
            transition: color 200ms ease-in-out;

            &:hover,
            &:focus {
                color: $omm-blue;
            }
        }
    }

    &__empty {

        text-decoration: none;
        color: $slate;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -0.28px;
        margin-bottom: 6px;
    }

    &__visited {
        flex: 1;
        padding: 14px 23px 40px 23px;
        border-top: 1px solid $omm-grey-soft-3;

        &-title-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &-title {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.4;
            letter-spacing: -0.5px;
            color: $omm-blue;
            margin-bottom: 12px;
        }

        &-clear {
            font-size: 14px;
            letter-spacing: -0.5px;
            color: $omm-blue;
            border: none;
            background-color: none;
            text-decoration: underline;
            margin-bottom: 21px;
            margin-right: 12px;

            &:hover,
            &:focus {
                color: $omm-accent;
                cursor: pointer;
                transition: all ease-in-out 200ms;
            }
        }
    }

    &__visited-results {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 12px;
    }

    &__frequent-terms {
        ul {
            margin-bottom: 0px;
        }
    }

    &__recent-terms {
        padding: 26px 0px;
    }

    &__frequent-terms,
    &__recent-terms {
        a {
            text-decoration: none;
            color: $slate;
            font-size: 14px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: -0.5px;
            margin-bottom: 8px;

            &:hover,
            &:focus {
                color: $omm-dark-blue-01;
            }
        }
    }

    &__clear-wrapper {
        flex: 1;
        align-self: stretch;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }
}

.search-card {
    display: flex;
    gap: 21px;
    align-items: center;
    width: calc(33.3333% - 12px);
    margin-bottom: 12px;
    height: 130px;
    padding: 14px;
    border-radius: 10px;
    background-color: $soft-blue-01;
    text-decoration: none;

    &:hover,
    &:focus {
        background-color: $soft-blue-02;
        transition: all ease-in-out 200ms;
    }

    &__professional {
        align-items: flex-start;
    }

    &__article {
        align-items: flex-start;

        .search-card__details {
            padding-right: 36px;
        }
    }

    &__image {
        margin-top: 4px;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-bottom: 16px;
        display: none;

        @media (min-width: $desktop) {
            display: block;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        flex: 1;
    }

    &__details {
        flex: 1;
    }

    &__location-name {
        display: block;
        font-size: 16px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.5px;
        color: $omm-blue;
        text-decoration: underline;
        margin-bottom: 8px;

        &:hover,
        &:focus {
            color: $omm-blue;
            text-decoration: underline;
        }
    }

    &__name {
        display: block;
        font-size: 16px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.5px;
        color: black;
        text-decoration: none;
        margin-bottom: 8px;

        &:hover,
        &:focus {
            color: $omm-blue;
            text-decoration: underline;
        }
    }

    &__title {
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.5px;
        color: $slate;
    }

    &__actions {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 30px;
    }

    &__phone {}

    &__phone-link {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        text-align: left;
        color: $slate;

        a {
            text-decoration: underline;
            margin-bottom: 0 !important;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;
            text-align: left;
            color: $omm-blue;
        }

    }

    &__icons {
        display: flex;
        align-items: center;
        text-decoration: none;

        i {
            font-size: 20px;
            color: $omm-blue;
            text-decoration: none;
        }
    }

    &__icon {
        text-decoration: none;
        margin-bottom: 0 !important;
    }

    &__directions {
        a {
            margin-bottom: 0 !important;
            margin-top: 8px !important;
            display: block;
            text-decoration: underline;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;
            text-align: left;
            color: $omm-blue;
        }
    }

    &__page-title {
        font-size: 16px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.5px;
        text-align: left;
        color: $slate-dark;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 70px;
        text-decoration: none;
    }

    &__meta {
        display: flex;
        gap: 34px;
        margin-bottom: 9px;
    }

    &__meta-item {
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.5px;
        color: $slate;
    }
}