.experience {

    &__wrapper {
        @include flexbox();
        @include flex-direction(column-reverse);
        @include justify-content(space-between);

        @media (min-width: $tablet) {
            @include flex-direction(row);
            padding: 0 16px;
            margin-bottom: 55px;
        }

        @media (min-width: $laptop) {
            padding: 0 16px;
        }

        @media (min-width: $desktop) {
            padding: 0px 0px;
        }
    }

    &__body {
        flex: 1;
    }

    &__embed {
        margin-bottom: 16px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__sidebar {
        @include flexbox();
        flex-wrap: wrap;
        @include justify-content(flex-end);

        @media (min-width: $laptop) {
            min-width: 360px;
            margin-left: 16px;
            margin-top: 0;
            max-width: 360px;
            @include align-items(stretch);
            @include justify-content(flex-start);
        }

        @media (min-width: $desktop) {
            min-width: 415px;
            max-width: 415px;
        }
    }

    &__content {
        padding: 0 12px;

        @media (min-width: $tablet) {
            padding: 0 16px;
        }

        @media (min-width: $laptop) {
            padding: 0 16px;
        }

        @media (min-width: $desktop) {
            padding: 0 0px;
        }

        h1 {
            font-size: 24px;
            font-weight: 300;
            line-height: 1.17;
            letter-spacing: -0.72px;
        }

        h1,
        h6 {
            margin-bottom: 8px;
        }

        h6 {
            margin-bottom: 8px;
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: -0.28px;
        }

        h5 {
            margin-bottom: 24px;
        }

        h4 {
            margin-bottom: 16px;
        }

        p {
            // font-size: 14px;
            margin-bottom: 32px;

            a {
                color: $omm-accent-blue;
            }
        }

        img {
            width: 100%;
            height: auto;
        }

        address {
            color: $omm-blue;
            font-size: 14px;
            margin: 16px 0;
            white-space: pre-wrap;
            font-style: normal;
            line-height: 1.43;
            margin-bottom: 36px;

            @media (min-width: $tablet) {
                margin: 24px 0;
            }

            a {
                color: inherit;
                text-decoration: none;
            }
        }

        blockquote {
            background-color: $omm-blue-soft-1;
            color: $omm-dark-blue-02;
            font-size: 28px;
            @extend h2;
            padding: 30px 24px 100px 24px;
            border-radius: 10px;
            margin: 35px 0;
            width: 100%;
            font-weight: 300;

            @media (min-width: $tablet) {
                padding: 50px 55px 40px 55px;
                margin: 40px 0 42px;
                width: 100%;
                font-size: 34px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -1.02px;
            }
        }
    }

    .breadcrumbs {
        display: none;
        padding: 18px 0px;
        margin-bottom: 0px;

        li,
        li a {
            color: black;
            font-size: 12px;
            letter-spacing: -0.3px;
        }

        @media (min-width: $laptop) {
            display: block;
            margin-bottom: 18px;
        }
    }

    .search-results {
        @media (min-width: $tablet) {
            padding: 0px 0px;
        }
    }
}

.experience-hero {
    padding: 17px 12px 0 12px;
    @include flexbox();
    @include flex-direction(column-reverse);
    @include justify-content(space-between);
    overflow: hidden;

    @media (min-width: $tablet) {
        @include flex-direction(row);
        padding: 0 16px;
    }

    @media (min-width: $laptop) {
        padding: 0 16px;
        margin-bottom: 32px;
    }

    @media (min-width: $desktop) {
        padding: 0px 0px;
    }

    &__intro {

        &-title * {
            margin-bottom: 0px;
            color: $omm-blue;
            font-size: 24px !important;
            font-weight: 300 !important;
            line-height: 1.17 !important;
            letter-spacing: -0.72px !important;

            @media (min-width: $tablet) {
                margin-bottom: 0px;
                font-size: 40px !important;
                font-weight: 300 !important;
                letter-spacing: -1.2px !important;
                max-width: 620px;
            }

            @media (min-width: $desktop) {
                max-width: 750px;
            }
        }

        @media (min-width: $tablet) {
            flex: 1;
        }
    }

    &__date {
        font-size: 14px;
        line-height: 1.29;
        letter-spacing: -0.28px;
        margin-bottom: 18px;
        color: $slate;

        @media (min-width: $tablet) {
            margin-bottom: 18px;
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: -0.28px;
        }
    }
}

.experience-contacts {
    display: none;
    padding: 12px 0px;

    @media (min-width: $laptop) {
        @include flexbox();
        flex: 1;
        min-height: 240px;
        @include flex-direction(column);
        padding: 32px 0px 0px 34px;
    }

    &--mobile {
        display: block;
        padding-bottom: 50px;

        padding-top: 0px;

        @media (min-width: $tablet) {
            padding-top: 12px;
        }

        @media (min-width: $laptop) {
            display: none;
        }
    }

    &__heading {
        @extend .caption-title;
        margin-bottom: 0px;
        padding: 8px 16px;
        border-radius: 5px;
        font-weight: 300;
        font-size: 12px;
        line-height: 1.2;
        height: 30px;
        color: $omm-dark-blue-01;
        background-color: $omm-blue-soft-1;
    }

    &__content {
        // padding: 20px 0;
        @include flexbox();
        @include flex-direction(column);

        @media (min-width: $tablet) {
            padding: 20px;
        }
    }


    &__toggle {
        margin-top: 16px;
        margin-right: 16px;
    }

    input {
        display: none;
    }

    input:checked~.overlay {
        display: block;
    }
}