@import "base/mixins";
@import "base/variables";
@import "base/base";
@import "base/fonts";
@import "base/spacing";


body {
    margin: 0;
    padding: 0;
    border: none;

    min-height: 100vh;
    @include flexbox();
    @include flex-direction(column);
    @include align-items(stretch);
    @include justify-content(stretch);
}

.pdf-print {
    width: 1440px;
    margin: 0 auto;

    .uwy,
    .button.button--share-pdf,
    .page-hero__overlay-gradient,
    .page-hero__video {
        display: none !important;
    }
}


.page-break {
    page-break-inside: avoid;
}

.container {
    @include container();
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;

    &-fluid {}

    &--left {
        margin-left: 0;
        margin-right: 0;
        max-width: 750px;

        @media (min-width: $desktop) {
            max-width: 824px;
        }
    }

    &--hr {
        position: relative;
    }

    .video-hero-full {
        @media (min-width: $tablet) {
            border-radius: 5px;
        }
    }
}

.view-more-trigger {
    display: none;
}

.hidden-content {
    display: block !important;
}

.container-small {
    @include container-small();
}

.container-medium {
    @include container-medium();
}

.container-large {
    @include container-large();
}

.site-content {
    position: relative;
    margin: auto;
    width: 100%;
    flex: 1;
}

body .uwy.userway_p1 .uai {
    bottom: 13px !important;
    top: auto !important;
}

.hr {
    margin: 24px 0;
    width: 100%;
    color: $soft-grey-03;

    @media (min-width: $tablet) {
        margin: 70px 0;
    }
}

// Foundational Elements
@import "core/buttons";
@import "core/breadcrumbs";
@import "core/radio-button";
@import "core/checkbox";
@import "core/umbraco-forms";

// Vendor Overrides
@import "vendor/swiper";

// BlockList Components
@import "components/header";
@import "components/footer";
@import "components/navigation";
@import "components/cookie-policy";
@import "components/language-selector";
@import "components/search-autocomplete";
@import "components/sitemap";
@import "components/navigation-intro-text";
@import "components/toggle";
@import "components/loading-indicator";
@import "components/image-text-block";
@import "components/overlay";
@import "components/page-subnavigation";
@import "components/social-footer";
@import "components/call-to-action-card";
@import "components/home-action-card";
@import "components/home-page-news";
@import "components/video-and-text";
@import "components/table-of-contents";
@import "components/alumni-spotlights";
@import "components/events-slider";
@import "components/full-width-slider";
@import "components/vertical-cta-cards";
@import "components/standard-hero";
@import "components/actions-hero";
@import "components/home-page-hero";
@import "components/simple-cta-card";
@import "components/testimonial-slider";
@import "components/video-hero-full";
@import "components/video-button";
@import "components/testimonial-video-hero";
@import "components/accordion";
@import "components/split-section";
@import "components/card-carousel";
@import "components/professional-slider-card";
@import "components/image-caption";
@import "components/case-study-slider";
@import "components/team-cards";
@import "components/job-slider";
@import "components/infographics";
@import "components/professionals-services-search";
@import "components/pagination";
@import "components/case-studies";
@import "components/quote";
@import "components/link-list";
@import "components/feature-card";
@import "components/share-buttons";
@import "components/featured-slider";
@import "components/accolades";
@import "components/experience-list";
@import "components/careers-overlay";
@import "components/services-list";
@import "components/company-logo-slider";
@import "components/info-card";
@import "components/related-content";
@import "components/news-block";
@import "components/subscribe-insights";
@import "components/flockler";

// Page Templates
@import "pages/page";
@import "pages/home-page";
@import "pages/components-page";
@import "pages/text-page";
@import "pages/rich-text-page";
@import "pages/news";
@import "pages/insights";
@import "pages/article";
@import "pages/professionals";
@import "pages/professional";
@import "pages/locations";
@import "pages/location";
@import "pages/service";
@import "pages/services";
@import "pages/practices";
@import "pages/industries";
@import "pages/search-results";
@import "pages/careers";
@import "pages/history";
@import "pages/press-room";
@import "pages/experience";
@import "pages/alumni";
@import "pages/cookie-policy";
@import "pages/probono";
@import "pages/hub";
@import "pages/diversity";
@import "pages/contact-us";
@import "pages/error";
@import "pages/pdf";
@import "pages/no-navigation";


// Navigation Components
@import "components/navigation-about";
@import "components/navigation-services";
@import "components/navigation-locations";
@import "components/navigation-search";
@import "components/navigation-news";
@import "components/navigation-insights";
@import "components/navigation-professionals";

@import "base/utilities";