.article {

    &__wrapper {
        @include flexbox();
        @include flex-direction(column-reverse);
        @include justify-content(space-between);
        margin-bottom: 65px;
        padding: 0 16px;

        @media (min-width: $tablet) {
            margin-bottom: 72px;
        }

        @media (min-width: $laptop) {
            @include flex-direction(row);
            padding: 0 16px;
        }

        @media (min-width: $desktop) {
            padding: 0px 0px;
        }
    }

    &__components {
        margin-bottom: 48px;

        @media (min-width: $tablet) {
            margin-bottom: 72px;
        }
    }

    &__body {
        flex: 1;
    }

    &__embed {
        margin-bottom: 16px;
        width: 100%;
        // display: flex;
        // flex-direction: row;
        // align-items: flex-start;
        // justify-content: flex-start;

        &--video {
            height: 300px;

            @media (min-width: $tablet) {
                height: 500px;
            }

            .plyr__video-embed {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__sidebar {
        @include flexbox();
        flex-wrap: wrap;
        @include justify-content(flex-end);
        @include align-items(center);

        @media (min-width: $laptop) {
            min-width: 360px;
            margin-left: 16px;
            margin-top: 0;
            max-width: 360px;
            flex-direction: column;
            @include align-items(stretch);
            @include justify-content(flex-start);
        }

        @media (min-width: $desktop) {
            min-width: 415px;
            max-width: 415px;
        }
    }

    &__content {
        // padding: 0 16px;

        @media (min-width: $tablet) {
            // padding: 0 16px;
        }

        @media (min-width: $laptop) {
            // padding: 0 16px;
        }

        @media (min-width: $desktop) {
            padding: 0 0px;
        }

        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.3px;
        text-align: left;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $omm-blue;
            line-height: 1.4;
        }

        h1,
        h6 {
            margin-bottom: 8px;
        }

        h6 {
            margin-bottom: 8px;
        }

        h5 {
            margin-bottom: 24px;
        }

        h4 {
            margin-bottom: 16px;
        }

        p {
            font-size: 16px;
            font-weight: 300;
            letter-spacing: -0.3px;
            text-align: left;
            margin-bottom: 16px;
            line-height: 1.4;
        }

        a {
            color: $omm-blue;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        ul,
        ol {
            margin-left: 0;
            padding-left: 16px;
            margin-bottom: 36px;
        }

        li {

            a {
                color: $omm-accent-blue;
            }
        }

        figure {
            margin: 0;
        }

        figcaption {
            font-size: 12px;
            font-weight: 300;
            letter-spacing: -0.5px;
            text-align: left;
            margin-bottom: 16px;
            color: $slate;
        }

        img {
            max-width: 100%;
            height: auto !important;
        }

        address {
            color: $omm-blue;
            font-size: 14px;
            margin: 16px 0;
            white-space: pre-wrap;
            font-style: normal;
            line-height: 1.43;
            margin-bottom: 36px;

            @media (min-width: $tablet) {
                margin: 24px 0;
            }

            a {
                color: inherit;
                text-decoration: none;
            }
        }

        blockquote {
            background-color: $omm-blue-soft-1;
            color: $omm-dark-blue-02;
            font-size: 28px;
            @extend h2;
            padding: 30px 24px 100px 24px;
            border-radius: 10px;
            margin: 35px 0;
            width: 100%;
            font-weight: 300;

            @media (min-width: $tablet) {
                padding: 50px 55px 40px 55px;
                margin: 40px 0 42px;
                width: 100%;
                font-size: 34px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -1.02px;
            }
        }

        hr {
            margin: 20px 0;
        }

        p+h1,
        p+h2,
        p+h3,
        p+h4,
        p+h5,
        p+h6 {
            margin-top: 32px;
        }

        &>div.container>div h2 {
            display: none;
        }

        &>div.container>div h2+span {
            display: none;
        }
    }

    .breadcrumbs {
        display: none;
        padding: 18px 0px;
        margin-bottom: 0px;

        li,
        li a {
            color: black;
            font-size: 12px;
            letter-spacing: -0.3px;
        }

        @media (min-width: $laptop) {
            display: block;
            margin-bottom: 18px;
        }
    }

    .search-results {
        padding-bottom: 0;

        @media (min-width: $tablet) {
            padding-bottom: 0;
        }
    }

    .search-results__grid .search-results__section {
        margin-bottom: 65px;

        // @media (min-width: $tablet) {
        //     padding-bottom: 0;
        // }
    }

    &__hr {
        margin: 0 0 65px 0;

        @media (min-width: $tablet) {
            margin: 0 0 72px 0;
        }
    }
}

.article-hero {
    padding: 0px 0px 0 0px;
    @include flexbox();
    @include flex-direction(column-reverse);
    @include justify-content(space-between);
    overflow: hidden;

    @media (min-width: $tablet) {
        @include flex-direction(row);
        // padding: 0 16px;
    }

    @media (min-width: $laptop) {
        // padding: 0 16px;
    }

    @media (min-width: $desktop) {
        padding: 0px 0px;
    }

    &__intro {

        h1 {
            margin-bottom: 15px;
            color: $omm-blue;
            font-size: 28px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;

            @media (min-width: $tablet) {
                margin-bottom: 18px;
                font-size: 40px;
                font-weight: 300;
                letter-spacing: -1.2px;
                max-width: 620px;
            }

            @media (min-width: $tablet) {
                max-width: 750px;
            }
        }

        @media (min-width: $tablet) {
            flex: 1;
        }
    }

    &__date {
        font-size: 14px;
        line-height: 1.29;
        letter-spacing: -0.28px;
        margin-bottom: 28px;
        color: $slate;

        @media (min-width: $tablet) {
            margin-bottom: 32px;
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: -0.28px;
        }
    }
}

.article-language-toggle {
    min-height: 0px;
    padding-bottom: 15px;
    flex: 1;

    @media (min-width: $laptop) {
        min-height: 77px;
        padding-bottom: 0px;
        flex: 0;
    }

    .language-toggle__toggle-wrapper {
        justify-content: flex-start;

        @media (min-width: $laptop) {
            justify-content: flex-end;
        }
    }
}


.article-contacts {
    display: none;
    padding: 12px 0px;

    @media (min-width: $laptop) {
        @include flexbox();
        flex: 1;
        min-height: 240px;
        @include flex-direction(column);
        padding: 12px 0px 0px 34px;
    }

    &--mobile {
        display: block;
        padding-bottom: 30px;

        padding-top: 0px;

        @media (min-width: $tablet) {
            padding-top: 12px;
        }

        @media (min-width: $laptop) {
            display: none;
        }
    }

    &__heading {
        @extend .caption-title;
        margin-bottom: 0px;
        padding: 8px 16px;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        height: 30px;
        color: $omm-dark-blue-01;
        background-color: $omm-blue-soft-2;
    }

    &__content {
        @include flexbox();
        @include flex-direction(column);

        @media (min-width: $tablet) {
            border-radius: 0 0 5px 5px;
            border: 1px solid $omm-blue-soft-2;
        }
    }


    &__toggle {
        margin-top: 16px;
        margin-right: 0px;
    }

    input {
        display: none;
    }

    input:checked~.overlay {
        display: block;
    }
}