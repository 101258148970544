.case-studies {
    max-width: 100%;
    display: block;


    hr {
        display: none;

        @media (min-width: $desktop) {
            display: block;
        }
    }

    &__categories {
        border-radius: 5px;
        display: none;
        flex-wrap: wrap;
        gap: 0px;
        @include gutters();


        @media (min-width: $desktop) {
            gap: 141px;
            margin-left: 0px;
            margin-right: 0px;
            display: flex;
        }
    }

    &__categories-dropdown {
        display: block;
        @include gutters();
        padding-bottom: 22px;

        .news-block__cards-filter {
            max-width: 100%;
            height: 42px;

            i {
                height: 42px;
                line-height: 42px;
            }
        }

        @media (min-width: $desktop) {
            display: none;
        }
    }

    &__category-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 30px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.5px;
        text-align: left;
        color: $slate-dark;
        cursor: pointer;
        margin-top: 7px;
        margin-bottom: 7px;
        padding: 0 17px;

        &--active {
            background-color: $omm-blue-soft-2;
            text-decoration: none;
        }
    }

    &__cards {
        display: flex;
        gap: 14px;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (min-width: $desktop) {
            gap: 24px;
        }

        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        &--navigation {
            @media (min-width: $laptop) {
                padding: 0 50px;
            }
        }
    }

    &__card {
        height: 454px;
        padding: 33px 32px 35px 32px;
        border-radius: 10px;
        background-color: $soft-blue-02;
        display: flex;
        flex-direction: column;

        &.swiper-slide {
            height: auto;
            min-height: 420px;
        }
    }

    &__title,
    &__title p {
        font-size: 20px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.72px;
        color: $slate-dark;
        flex: 1;
    }

    &__action {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        text-align: left;
        text-decoration: underline;
        color: $omm-blue;
    }

    &__cards-title {
        font-size: 28px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.84px;
        text-align: left;
        color: $omm-blue;
        display: none;
        padding: 0px 12px;

        @media (min-width: $desktop) {
            display: block;
            padding: 0px 0px;
        }
    }
}