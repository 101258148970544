.probono {

    &__content {

        h1,
        h2,
        h3,
        h4 {
            color: $omm-dark-blue-02;
            line-height: 1.3;
        }

        h2 {
            @media (min-width: $tablet) {
                font-size: 28px;
            }
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 16px;

            @media (min-width: $tablet) {
                margin-bottom: 32px;
            }
        }

        p {
            color: $slate-dark;
        }
    }

    .page-hero__intro-content {
        @media (min-width: $tablet) {
            background-color: transparent;
        }

        h1,
        h2,
        h3,
        h4 {
            font-size: 20px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            margin-bottom: 12px;
            line-height: 1.29;
            letter-spacing: -0.72px;

            @media (min-width: $desktop) {
                font-size: 24px;
                font-weight: 300;
                line-height: 1.29;
            }
        }

        p {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;

            @media (min-width: $desktop) {
                font-size: 14px;
                font-weight: normal;
                line-height: 1.29;
            }
        }
    }
}

.probono-partners {

    &__heading {
        color: $omm-blue;
        font-size: 28px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        line-height: 1.14;
        margin-bottom: $space-xs;

        @media (min-width: $laptop) {
            font-size: 40px;
            margin-bottom: $space-sm;
        }
    }

    &__content {
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.3px;
        text-align: left;
    }

    &__wrapper {
        columns: 1;
        max-width: 100%;
        margin-top: $space-sm;

        @media (min-width: $tablet) {
            columns: 2;
        }

        @media (min-width: $laptop) {
            columns: 3;
        }
    }

    &__action {
        margin-top: 35px;
        width: 100%;

        a {
            max-width: 300px;
        }
    }

    &__link {
        max-width: 100%;
        display: flex;
        align-items: center;
        text-decoration: none;
        border-bottom: 1px solid $omm-grey-soft-3;
        padding: 8px 16px;

        a {
            color: $omm-blue;
            line-height: 1.3;
            display: inline-block;
            text-decoration: none;
        }

        &-title {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            color: black;
            margin-bottom: 0;
            line-height: 1;
        }

        &-icon {
            font-size: 20px;
            color: $omm-blue;
            margin-right: 16px;
            margin-bottom: 0;

            @media (min-width: $tablet) {
                display: block;
            }
        }

        @media (min-width: $tablet) {
            // max-width: 50%;
            padding: 4px 0;
        }

        @media (min-width: $desktop) {
            // max-width: 33%;
            min-width: 400px;
        }
    }

}

.container .probono-partners {
    @include gutters();
}

.container-fluid .probono-partners {
    @include guttersFullWidth();
}