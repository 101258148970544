.company-logo-slider {
    max-width: 100%;
    display: block;
    @include gutters;

    &__container {
        z-index: 1;
        width: 100%;
    }

    &__wrapper {
        @include flexbox();
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: $gutter-md;
    }

    &__button {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 20px 0 $space-xxxl;
        gap: $gutter-sm;

        a {
            display: inline-flex;
        }
    }

    &__slide {
        min-height: 175px;
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(221, 227, 231, 1);
        background-color: white;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 35px 32px;
        @include flexbox();
        @include justify-content(flex-end);
        @include flex-direction(column);
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 230px auto;

        @media (min-width: $tablet) {
            width: calc(33.333% - 12px);
            min-height: 175px;
            padding: 35px 60px 32px 30px;
        }

        @media (min-width: $laptop) {
            width: calc(25% - 12px);
            min-height: 175px;
            padding: 35px 60px 32px 30px;
        }
    }

    &__title {
        color: $omm-blue;
        font-size: 28px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        text-align: center;
        margin-bottom: 30px;

        @media (min-width: $tablet) {
            color: $omm-blue;
            font-size: 40px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: center;
            margin-bottom: 30px;
        }
    }


    &__legend {
        // display: none;
        width: 700px;
        margin: 30px auto;
        height: 1px;
        max-width: 95%;
        background-color: $soft-grey-03;
        position: relative;
        margin-bottom: 67px;

        @media (min-width: $desktop) {
            margin: 50px auto;
            max-width: 700px;
            display: block;
        }

        &-bar {
            background-color: $omm-blue;
            height: 7px;
            position: absolute;
            top: -3px;
            left: 0;
        }
    }
}