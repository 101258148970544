.table-of-contents {
    &__title {
        font-size: 20px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.6px;
        margin-bottom: 7px;
        color: $omm-blue;

        @media (min-width: $tablet) {
            font-size: 28px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.84px;
        }
    }

    &__anchor {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-top: 1px solid $soft-grey-03;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        color: black;
        padding: 15px 0px;
        text-decoration: none;
        cursor: pointer;

        &:hover,
        &:focus {
            color: $omm-blue;
            text-decoration: none;
        }

        span {
            flex: 1;
        }

        i {
            color: $omm-blue;
        }

        &:last-of-type {
            border-bottom: 1px solid $soft-grey-03;
        }

        @media (min-width: $tablet) {
            font-size: 20px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.6px;
        }
    }
}

.container .table-of-contents {
    @include gutters();
}

.container-fluid .table-of-contents {
    @include guttersFullWidth();
}