// Sizes should always be divisible by 4 so we have consistant visual spacing
// by using numbers divisible by 4 it also makes it much easier to see in code what spacing is applied
$sizes: 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80, 84, 88, 86, 90;

@each $size in $sizes {
    .m-#{$size} {
        margin: $size;
    }

    .mx-#{$size} {
        margin-left: $size;
        margin-right: $size;
    }

    .my-#{$size} {
        margin-top: $size;
        margin-bottom: $size;
    }

    .mb-#{$size} {
        margin-bottom: $size;
    }

    .mt-#{$size} {
        margin-top: $size;
    }

    .ml-#{$size} {
        margin-left: $size;
    }

    .mr-#{$size} {
        margin-right: $size;
    }
}

@mixin gutters() {
    padding-left: $gutter-md;
    padding-right: $gutter-md;

    @media (min-width: $tablet) {
        padding-left: $gutter-md;
        padding-right: $gutter-md;

    }

    @media (min-width: $laptop) {
        padding-left: $gutter-md;
        padding-right: $gutter-md;
    }

    @media (min-width: ($desktop + $gutter-md)) {
        padding-left: 0;
        padding-right: 0;

    }
}

@mixin guttersFullWidth() {
    padding-left: $gutter-md;
    padding-right: $gutter-md;

    @media (min-width: $tablet) {
        padding-left: $gutter-md;
        padding-right: $gutter-md;

    }

    @media (min-width: $laptop) {
        padding-left: $gutter-md;
        padding-right: $gutter-md;
    }

    @media (min-width: ($desktop + $gutter-md)) {
        padding-left: $gutter-md;
        padding-right: $gutter-md;

    }
}

@mixin guttersSmall() {
    padding: 0 $space-8;

    @media (min-width: $tablet) {
        padding: 0 $gutter-md;
    }

    @media (min-width: $laptop) {
        padding: 0 $gutter-md;
    }

    @media (min-width: ($desktop + $gutter-md)) {
        padding: 0;
    }
}

@mixin resetGutters() {
    margin-left: -$gutter-md;
    margin-right: -$gutter-md;

    @media (min-width: $tablet) {
        margin-left: -$gutter-md;
        margin-right: -$gutter-md;
    }

    @media (min-width: $laptop) {
        margin-left: -$gutter-md;
        margin-right: -$gutter-md;
    }

    @media (min-width: $desktop) {
        margin-left: 0;
        margin-right: 0;
    }
}

@mixin verticalSpacingSmall() {
    padding-top: $space-22;
    padding-bottom: $space-32;

    @media (min-width: $tablet) {
        padding-top: $space-22;
        padding-bottom: $space-32;
    }

    @media (min-width: $laptop) {
        padding-top: $space-32;
        padding-bottom: $space-32;
    }

    @media (min-width: $desktop) {
        padding-top: $space-32;
        padding-bottom: $space-32;
    }
}

@mixin verticalSpacingMedium() {
    margin-top: $space-22;
    margin-bottom: $space-32;

    @media (min-width: $tablet) {
        margin-top: $space-22;
        margin-bottom: $space-48;
    }

    @media (min-width: $laptop) {
        margin-top: $space-32;
        margin-bottom: $space-48;
    }

    @media (min-width: $desktop) {
        margin-top: $space-32;
        margin-bottom: $space-48;
    }
}

@mixin verticalSpacingLarge() {
    margin-top: $space-22;
    margin-bottom: $space-32;

    @media (min-width: $tablet) {
        margin-top: $space-22;
        margin-bottom: $space-72;
    }

    @media (min-width: $laptop) {
        margin-top: $space-32;
        margin-bottom: $space-72;
    }

    @media (min-width: $desktop) {
        margin-top: $space-32;
        margin-bottom: $space-72;
    }
}

@mixin verticalSpacingCustom($top-sm, $bottom-sm, $top-md: $top-sm, $bottom-md: $bottom-sm, $top-lg: $top-md, $bottom-lg: $bottom-md) {
    padding-top: $top-sm;
    padding-bottom: $bottom-sm;

    @media (min-width: $tablet) {
        padding-top: $top-sm;
        padding-bottom: $bottom-sm;
    }

    @media (min-width: $laptop) {
        padding-top: $top-md;
        padding-bottom: $bottom-md;
    }

    @media (min-width: $desktop) {
        padding-top: $top-lg;
        padding-bottom: $bottom-lg;
    }
}

.spacing {
    &-top {
        &--none {
            padding-top: 0;

            @media (min-width: $tablet) {
                padding-top: 0;
            }

            @media (min-width: $laptop) {
                padding-top: 0;
            }

            @media (min-width: $desktop) {
                padding-top: 0;
            }
        }

        &--xsmall {
            padding-top: $space-8;

            @media (min-width: $tablet) {
                padding-top: $space-8;
            }

            @media (min-width: $laptop) {
                padding-top: $space-8;
            }

            @media (min-width: $desktop) {
                padding-top: $space-8;
            }
        }

        &--small {
            padding-top: $space-22;

            @media (min-width: $tablet) {
                padding-top: $space-32;
            }

            @media (min-width: $laptop) {
                padding-top: $space-32;
            }

            @media (min-width: $desktop) {
                padding-top: $space-32;
            }
        }

        &--medium {
            padding-top: $space-22;

            @media (min-width: $tablet) {
                padding-top: $space-48;
            }

            @media (min-width: $laptop) {
                padding-top: $space-48;
            }

            @media (min-width: $desktop) {
                padding-top: $space-48;
            }
        }

        &--large {
            padding-top: $space-32;

            @media (min-width: $tablet) {
                padding-top: $space-72;
            }

            @media (min-width: $laptop) {
                padding-top: $space-72;
            }

            @media (min-width: $desktop) {
                padding-top: $space-72;
            }
        }
    }

    &-bottom {
        &--none {
            padding-bottom: 0;

            @media (min-width: $tablet) {
                padding-bottom: 0;
            }

            @media (min-width: $laptop) {
                padding-bottom: 0;
            }

            @media (min-width: $desktop) {
                padding-bottom: 0;
            }
        }

        &--xsmall {
            padding-bottom: $space-8;

            @media (min-width: $tablet) {
                padding-bottom: $space-8;
            }

            @media (min-width: $laptop) {
                padding-bottom: $space-8;
            }

            @media (min-width: $desktop) {
                padding-bottom: $space-8;
            }
        }

        &--small {
            padding-bottom: $space-22;

            @media (min-width: $tablet) {
                padding-bottom: $space-32;
            }

            @media (min-width: $laptop) {
                padding-bottom: $space-32;
            }

            @media (min-width: $desktop) {
                padding-bottom: $space-32;
            }
        }

        &--medium {
            padding-bottom: $space-22;

            @media (min-width: $tablet) {
                padding-bottom: $space-48;
            }

            @media (min-width: $laptop) {
                padding-bottom: $space-48;
            }

            @media (min-width: $desktop) {
                padding-bottom: $space-48;
            }
        }

        &--large {
            padding-bottom: $space-32;

            @media (min-width: $tablet) {
                padding-bottom: $space-72;
            }

            @media (min-width: $laptop) {
                padding-bottom: $space-72;
            }

            @media (min-width: $desktop) {
                padding-bottom: $space-72;
            }
        }
    }
}


@mixin topSpacingSmall() {
    margin-top: $space-xs;

    @media (min-width: $tablet) {
        margin-top: $space-xs;
    }

    @media (min-width: $laptop) {
        margin-top: $space-sm;
    }

    @media (min-width: $desktop) {
        margin-top: $space-sm;
    }
}

@mixin bottomSpacingSmall() {
    margin-top: 0;
    margin-bottom: $space-32;

    @media (min-width: $tablet) {
        margin-top: 0;
        margin-bottom: $space-32;
    }

    @media (min-width: $laptop) {
        margin-top: 0;
        margin-bottom: $space-32;
    }

    @media (min-width: $desktop) {
        margin-top: 0;
        margin-bottom: $space-32;
    }
}

@mixin bottomSpacingLarge() {
    margin-top: 0;
    margin-bottom: $space-32;

    @media (min-width: $tablet) {
        margin-top: 0;
        margin-bottom: $space-32;
    }

    @media (min-width: $laptop) {
        margin-top: 0;
        margin-bottom: $space-72;
    }

    @media (min-width: $desktop) {
        margin-top: 0;
        margin-bottom: $space-72;
    }
}

@mixin topSpacingSmall() {
    margin-bottom: 0;
    margin-top: $space-32;

    @media (min-width: $tablet) {
        margin-bottom: 0;
        margin-top: $space-32;
    }

    @media (min-width: $laptop) {
        margin-bottom: 0;
        margin-top: $space-32;
    }

    @media (min-width: $desktop) {
        margin-bottom: 0;
        margin-top: $space-32;
    }
}

@mixin topSpacingLarge() {
    margin-bottom: 0;
    margin-top: $space-32;

    @media (min-width: $tablet) {
        margin-bottom: 0;
        margin-top: $space-32;
    }

    @media (min-width: $laptop) {
        margin-bottom: 0;
        margin-top: $space-72;
    }

    @media (min-width: $desktop) {
        margin-bottom: 0;
        margin-top: $space-72;
    }
}