.pdf {
    font-family: "Arial";
    max-width: 500px;
    transform: scale(0.5); // margin: 0 auto;

    &__header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        h1 {
            // font-size: 30px;
            font-weight: 300;
        }

        h2 {
            // font-size: 22px;
            font-weight: 300;
            color: $omm-blue;
        }

        h3 {
            font-size: 18px;
            font-weight: 300;
        }
    }

    hr {
        margin: 20px 0;
        border-color: black;
    }

    &__header-text {
        padding-right: 10%;
    }

    &__header-logo {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        &--brand {
            background-color: white;
            background-image: url("/img/logo.png");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 40px;
            height: 50px;

            @media (min-width: 1024px) {
                width: 180px;
            }
        }
    }

    &__header-graphic {
        width: 40%;
        display: flex;
        justify-content: flex-end;

        &--graphic {
            background-color: white;
            background-image: url("/img/logo.png");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 40px;
            height: 50px;

            @media (min-width: 1024px) {
                width: 180px;
            }
        }
    }

    &__body {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        h4 {
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            // letter-spacing: -0.3px;
            line-height: 1.4;
            text-align: left;
            color: $omm-blue;
            text-transform: uppercase;
        }
    }

    &__related {
        width: 25%;
    }

    &__content {
        flex: 1;
    }

}