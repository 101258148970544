#navigation {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4500;
  background-color: white;

  @media (min-width: 1024px) {
    min-height: 53px;
  }
}

.header {
  z-index: 99999;
  border-bottom: 1px solid #e7e7e7;
  background-color: white;
  position: relative;
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }

  &__inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 53px;

    @extend .container;
  }

  &__logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    padding: 0 12px;
    border-left: 1px solid $soft-grey-03;
    border-right: 1px solid $soft-grey-03;

    @media (min-width: $desktop) {
      padding: 0 31px;
    }
  }

  &__logo {
    align-self: stretch;
    background-color: white;
    background-image: url("/img/logo.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;

    @media (min-width: 1024px) {
      width: 124px;
    }
  }

  &__mobile-toggle {
    margin-right: 10px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    @media (min-width: 1024px) {
      display: none;
    }

    i {
      font-size: 22px;
    }
  }

  &__nav {
    min-height: 53px;
    display: none;

    @media (min-width: 1024px) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &--primary {
      flex: 1;
      padding: 0 15px;

      @media (min-width: $desktop) {
        padding: 0 30px;
      }
    }

    &--secondary {
      border-left: 1px solid $soft-grey-03;

      .header__nav-link:first-child {
        @media (min-width: $desktop) {
          margin-left: 36px;
        }
      }

      .header__nav-link:nth-last-child(2) {
        @media (min-width: $desktop) {
          margin-right: 36px;
        }
      }
    }

    &--search-open {
      border-left: none;
    }
  }

  &__nav-dropdown {
    min-height: 4rem;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    border-bottom: solid 1px $omm-grey-soft-3;
    position: absolute;
    top: 53px;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (min-width: 1024px) {
      border: solid 1px $omm-grey-soft-3;
    }

    & > div {
      width: 100%;
    }
  }

  &__nav-dropdown-inner {
    @extend .container;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 292px;
    max-height: calc(100vh - 53px);
    overflow: hidden;
  }

  &__nav-dropdown-intro {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $space-xs;
    padding: 25px 28px;
    width: 300px;
    min-width: 300px;
    border-right: 1px solid $omm-grey-soft-3;

    &-text {
      font-size: 20px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: -0.6px;
      color: black;
    }

    &-actions {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: $space-xxxs;
      width: 100%;
    }
  }

  &__nav-dropdown-primary-action,
  &__nav-dropdown-secondary-action {
    @extend .button;
    width: 100%;
  }

  &__nav-dropdown-component {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    width: 100%;
    flex: 1;
    align-self: stretch;
    border-right: 1px solid $omm-grey-soft-3;
  }

  &__nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $omm-midnight;
    cursor: pointer;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    position: relative;
    align-self: stretch;
    padding: 0 18px;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $omm-blue;
    }

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 4px;
      bottom: -1px;
      left: 50%;
      transform: translateX(-50%);
      background-color: transparent;
    }

    &:hover {
      color: $omm-blue;
      cursor: pointer;

      &:after {
        width: 95%;
        background-color: $omm-blue;
        transition: width 200ms ease-in-out;
      }
    }

    &--active {
      &:after {
        width: 95%;
        color: $omm-blue;
        background-color: $omm-blue;
      }
    }

    &--dropdown-active {
      &:after {
        width: 95%;
        color: $omm-blue;
        background-color: $omm-blue;
      }
    }
  }

  &__nav-search {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    min-height: 53px;
    color: $omm-blue;
    padding: 0 17px;
    cursor: pointer;
    font-size: 22px;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    border-left: 1px solid $soft-grey-03;
    border-right: 1px solid $soft-grey-03;
    line-height: 0;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $omm-blue;
    }

    &--active {
      background-color: $omm-blue;
      border-color: $omm-blue;
      color: white;

      &:hover,
      &:focus {
        text-decoration: none;
        color: white;
      }
    }
  }

  &--dropdown-open {
    .header__nav-link--active::after {
      width: 0%;
    }
  }

  &--dropdown-active {
    &:after {
      width: 95%;
      color: $omm-blue;
      background-color: $omm-blue;
    }
  }
}

#navigation-mobile {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3999;

  min-height: 53px;

  @media (min-width: 1024px) {
    min-height: 0px;
  }
}

.navigation__loader {
  display: flex;
  height: 100%;
  width: 100%;
}

.header-mobile {
  position: relative;
  z-index: 99999;

  @media (min-width: 1024px) {
    display: none;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    background-color: white;
    position: relative;
    height: 65px;
    border-top: 5px solid $omm-midnight;
  }

  &__toggle-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
  }

  &__toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: $omm-dark-blue-02;
    align-self: stretch;
    margin-right: 10px;
    padding-left: 23px;
    padding-right: 23px;
  }

  &__search-toggle {
    transition: all 200ms ease-in-out;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;
    color: $omm-blue;
    align-self: stretch;
    border-left: 1px solid $soft-grey-03;

    &--active {
      background-color: $omm-blue;

      i {
        color: white;
      }
    }
  }

  &__logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }

  &__logo {
    align-self: stretch;
    background-color: white;
    background-image: url("/img/logo.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 124px;
  }

  &__dropdown {
    position: absolute;
    top: 65px;
    left: 0;
    z-index: 5000;
    height: calc(100vh - 65px);
    width: 100%;
    background-color: white;
    overflow-y: scroll;
  }

  &__search {
    position: absolute;
    top: 65px;
    left: 0;
    z-index: 5000;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    border-bottom: solid 1px $omm-grey-soft-3;
  }

  &__search-form {
    padding: 22px 0 8px 0;
  }

  &__search-input-wrapper {
    padding: 0px 19px;
    position: relative;
    max-width: 956px;
    margin: 0 auto;

    input[type="text"] {
      height: 42px;
      border-radius: 42px;
      border: 1px solid #e2ebf6;
      padding: 0px 19px;
      display: block;
      width: 100%;
      background-color: $soft-blue-02;
      margin-bottom: 0px;
      border: none;
      outline: none;
      transition: background-color 200ms ease-in-out;
      font-size: 14px;
      letter-spacing: -0.5px;
      font-weight: 300;
      color: $slate-dark;

      &::placeholder {
        color: $slate-dark;
      }

      @media (min-width: $tablet) {
        padding: 0px 19px;
      }

      &:focus {
        background-color: $soft-blue-03;
        border: none;
        outline: none;
      }
    }

    button {
      background-color: transparent;
      border: none;
      color: $omm-blue;
      font-size: 22px;
      height: 42px;
      width: 40px;
      right: 24px;
      top: 0;
      position: absolute;
      cursor: pointer;
      transition: color 200ms ease-in-out;

      &:hover,
      &:focus {
        color: $omm-blue;
      }
    }
  }

  &__visited-clear {
    color: $omm-blue;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.5px;
    text-align: left;
    text-decoration: underline;
    padding-left: 39px;
  }

  &__frequent-terms {
    padding: 24px 39px 0 39px;
  }

  &__recent-terms {
    padding: 24px 39px 39px 39px;
  }

  &__frequent-terms,
  &__recent-terms {
    h4 {
      color: $omm-blue;
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: -0.64px;
      text-align: left;
      margin-bottom: 8px;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }

    li {
      color: $slate;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: -0.5px;
      text-align: left;
      margin-bottom: 8px;

      a {
        color: $slate;
      }
    }
  }

  &__dropdown-primary {
    padding: 13px 0px 0px 26px;
    border-bottom: 1px solid $soft-grey-03;
  }

  &__primary-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    cursor: pointer;
    opacity: 1;
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: -0.64px;
    text-align: left;
    padding: 14px 0;
    border-bottom: 1px solid $soft-grey-03;
    text-decoration: none;

    &:last-child {
      border-bottom: none;
    }

    i {
      color: $omm-blue;
      font-size: 22px;
      margin-right: 19px;
    }
  }

  &__secondary-header {
    padding: 26px 23px 0 23px;
  }

  &__secondary-intro {
    color: $slate;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: -0.5px;
    text-align: left;
    margin-bottom: 24px;
  }

  &__secondary-back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: black;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: -0.64px;
    text-align: left;
    margin-bottom: 26px;

    i {
      color: $slate;
      margin-left: 4px;
      margin-right: 39px;
      font-size: 18px;
    }
  }

  &__secondary-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 37px;
  }

  &__secondary-action {
    margin-bottom: 8px;
    height: 42px;
    min-width: 216px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    border-radius: 80px;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: -0.28px;
    background-color: $omm-blue;

    &:hover,
    &:focus {
      background-color: $omm-midnight;
      cursor: pointer;
    }
  }
}
