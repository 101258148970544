.flockler-feed {

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        margin: 0 auto;
        padding: 0;
        width: 100%;
        flex-wrap: wrap;

        @media (min-width: $tablet) {
            flex-direction: column;
            gap: 2rem;
            flex-wrap: nowrap;
        }

        &--Top {
            flex-direction: column;
        }

        &--Bottom {
            flex-direction: column-reverse;
        }

        &--Left {
            flex-direction: row;
        }

        &--Right {
            flex-direction: row-reverse;
        }
    }

    &__feed {
        min-width: 100%;
        max-width: 100%;

        @media (min-width: $tablet) {
            min-width: 100%;
            max-width: 100%;
        }

        &--Top {
            min-width: 100%;
            max-width: 100%;

            @media (min-width: $tablet) {
                min-width: 100%;
                max-width: 100%;
            }
        }

        &--Bottom {
            min-width: 100%;
            max-width: 100%;

            @media (min-width: $tablet) {
                min-width: 100%;
                max-width: 100%;
            }
        }

        &--Left {
            min-width: 100%;
            max-width: 100%;

            @media (min-width: $tablet) {
                min-width: 50%;
                max-width: 50%;
            }
        }

        &--Right {
            min-width: 100%;
            max-width: 100%;

            @media (min-width: $tablet) {
                min-width: 50%;
                max-width: 50%;
            }
        }
    }

    &__text {
        min-width: 50%;
        max-width: 100%;

        @media (min-width: $tablet) {
            max-width: 50%;
        }

        &--Top {
            min-width: 100%;
            max-width: 100%;

            @media (min-width: $tablet) {
                min-width: 100%;
                max-width: 100%;
            }
        }

        &--Bottom {
            min-width: 100%;
            max-width: 100%;

            @media (min-width: $tablet) {
                min-width: 100%;
                max-width: 100%;
            }
        }

        &--Left {
            min-width: 100%;
            max-width: 100%;

            @media (min-width: $tablet) {
                min-width: 50%;
                max-width: 50%;
            }
        }

        &--Right {
            min-width: 100%;
            max-width: 100%;

            @media (min-width: $tablet) {
                min-width: 50%;
                max-width: 50%;
            }
        }
    }
}