.quote {
    width: 100%;

    &__header {
        margin-bottom: 30px;
        display: block;
    }

    &__title {
        @extend h1;
        color: $omm-blue;
        text-align: center;
        margin-bottom: 29px;
    }

    &__slide {
        background-color: $omm-blue-soft-3;

        text-align: center;
        padding: 38px 24px;

        margin: 0 auto;

        @media (min-width: $tablet) {
            padding: 48px 32px 48px 32px;
            max-width: 80%;
            // border-radius: 10px;
        }

        @media (min-width: $desktop) {
            padding: 48px 108px 48px 108px;
            max-width: 1064px;
            // border-radius: 10px;
        }

        &-title {
            margin-bottom: 29px;
            font-size: 12px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: 0.5px;
            text-align: center;
            text-transform: uppercase;
            color: $omm-blue;

            @media (min-width: $tablet) {
                font-size: 12px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: 0px;
                text-align: center;
                text-transform: uppercase;
            }
        }

        &-content {
            color: #000;
            margin-bottom: 29px;
            font-size: 20px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: -0.6px;
            text-align: center;
            color: $omm-dark-blue-01;

            @media (min-width: $tablet) {
                font-size: 28px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -1px;
                text-align: center;
                text-align: center;

            }

        }

        &-name {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: center;
            color: $omm-blue;

            @media (min-width: $tablet) {
                font-size: 20px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.6px;
                text-align: center;

                margin-bottom: 10px;
            }
        }

        &-jobtitle {
            text-transform: none;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: center;
            text-align: center;
            color: $omm-blue;
            margin-bottom: 0;

            @media (min-width: $tablet) {
                font-size: 14px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -0.5px;
                text-align: center;

                margin-bottom: 10px;
            }
        }
    }
}

.container .quote {
    @include gutters();
}

.container-fluid .quote {
    @include guttersFullWidth();
}