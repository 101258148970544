.infographics {
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    padding: $space-xxs $space-xxs $space-sm $space-xxs;
    scrollbar-color: $omm-blue $soft-grey-03;
    scrollbar-width: thin;

    @media (min-width: $desktop) {
        padding: 0px 0px;
    }

    &__section-title {
        color: $omm-blue;
        font-size: 24px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.86px;
        margin-bottom: 0px;
        padding: 0px $space-xxs;
        text-align: center;
        width: 100%;

        @media (min-width: $tablet) {
            font-size: 40px;
            letter-spacing: -1px;
            margin-top: $space-sm;
            margin-bottom: $space-xxxs;
        }
    }

    &::-webkit-scrollbar {
        height: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: linear-gradient(to bottom,
                white 0px,
                white 4px,
                $soft-grey-03 4px,
                $soft-grey-03 5px,
                white 5px,
                white 8px);
        margin: 0 $gutter-md;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background-color: $omm-blue;
        opacity: 0.64;
    }

    &__inner {
        display: flex;
        gap: $space-xxxs;
        width: 100%;

        @media (min-width: $tablet) {
            gap: $space-xxs;
        }
    }

    &__wrapper {
        gap: $space-xxxs;
        display: flex;
        flex-direction: column;
        position: relative;

        @media (min-width: $tablet) {
            gap: $space-xxs;
        }

        @media (min-width: $desktop) {
            width: auto;
        }

        // this a fix to apply overflow:scroll right padding
        &::after {
            content: "";
            display: block;
            position: absolute;
            right: calc(-1 * $space-xxs);
            width: $space-xxs;
            height: 1px;

            @media (min-width: $desktop) {
                display: none;
            }
        }
    }

    &__card {
        align-self: stretch;
        border-radius: 10px;
        background-color: $soft-blue-01;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        width: 250px;


        @media (min-width: $tablet) {
            width: 308px;
        }
    }

    &__card-content {
        display: flex;
        flex-direction: column;
        gap: $space-xxs;
        height: 100%;
        padding: 18px;
        position: relative;
        overflow: hidden;

        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        &--between {
            justify-content: space-between;
        }

        &--bottom {
            justify-content: flex-end;
        }

        @media (min-width: $tablet) {
            gap: $space-xs;
            padding: 18px;
        }
    }

    &__image-overlay {
        bottom: $space-xxxs;
        display: flex;
        flex-direction: column;
        gap: $space-xxs;
        left: $space-xxxs;
        padding: $space-xxxs;
        position: absolute;
        right: $space-xxxs;
        top: $space-xxxs;
        z-index: 1;

        &--bottom {
            justify-content: flex-end;
        }

        @media (min-width: $tablet) {
            bottom: $space-xxs;
            gap: $space-xs;
            left: $space-xxs;
            padding: $space-xxs;
            right: $space-xxs;
            top: $space-xxs;
        }
    }

    &__image {
        padding-top: 100%;
        position: relative;
        margin-left: -$space-xxxs;
        margin-right: -$space-xxxs;
        background-position: center;
        background-size: cover;
        width: calc(100% + ($space-xxxs * 2));

        @media (min-width: $tablet) {
            margin-left: -$space-xxs;
            margin-right: -$space-xxs;
            width: calc(100% + ($space-xxs * 2));
        }

        img {
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &__number {
        color: $omm-blue;
        font-size: 60px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1.76px;
        line-height: 1;
        text-align: left;
    }

    &__text {
        color: black;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;

        & p {
            color: black;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
        }
    }

    &__text-content {
        display: flex;
        flex-direction: column;
        gap: $space-xxs;
    }

    &__lead {
        color: $omm-blue;
        font-size: 28px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        line-height: 1.4;
        text-align: left;

        & p {
            color: $omm-blue;
            font-size: 28px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            line-height: 1.4;
            text-align: left;
        }
    }

    &__action {
        text-align: center;

        &--bottom {
            margin-top: auto;
        }

        a {
            @extend .button;
            display: inline-flex;
        }
    }

    [class*="small"] {
        flex: 1;
        // min-height: 220px;
    }

    [class*="medium"] {
        flex: 2;
        // min-height: 220px;
    }

    [class*="large"] {
        flex: 3;
        // min-height: 220px;
    }

    &--small-1 {
        .infographics__text {
            color: $omm-blue;
            font-size: 24px;
            font-weight: 300;
            line-height: 1.2;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
        }

        .infographics__image {
            align-self: flex-end;
            padding-top: 32%;
            width: 32%;
        }
    }

    &--small-2 {
        .infographics__text {
            color: $omm-blue;
            font-size: 24px;
            font-weight: 300;
            line-height: 1.2;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
        }

        .infographics__image {
            align-self: flex-end;
            padding-top: 32%;
            width: 32%;
        }
    }

    &--small-3 {
        .infographics__image {
            width: calc(100% + 32px);
        }
    }

    &--medium-1 {
        .infographics__text {
            color: $omm-blue;
            font-size: 24px;
            font-weight: 300;
            line-height: 1.2;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                margin-bottom: 0;
            }
        }

        .infographics__number {

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                margin-bottom: 0;
            }

            h1 {
                font-size: 60px;
            }
        }

        .infographics__image {
            height: 170px;
            width: calc(100% + 36px);
            margin-top: -18px;
            border-radius: 10px;
            margin-left: -18px;
            margin-right: -18px;

            img {
                border-radius: 10px 10px 0 0;
            }
        }

        .infographics__image--bg {
            padding-top: 60%;

            img {
                height: 150%;
                border-radius: 10px;
            }
        }
    }

    &--medium-2 {
        .infographics__image {
            height: 170px;
            width: calc(100% + 32px);
        }
    }

    &--medium-3 {
        .infographics__image {
            height: 182px;
            width: 100%;
        }
    }

    &--medium-4 {
        .infographics__lead {
            margin-bottom: 16px;
        }

        .infographics__text {
            text-align: center;
            font-size: 24px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
            margin-bottom: 16px;
        }
    }

    &--medium-5 {
        .infographics__text {
            color: $omm-blue;
            font-size: 24px;
            font-weight: 300;
            line-height: 1.2;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                margin-bottom: 0;
            }
        }

        .infographics__number {

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                margin-bottom: 0;
            }

            h1 {
                font-size: 60px;
            }
        }

        .infographics__image {
            height: 170px;
            width: calc(100% + 36px);
            margin-top: -18px;
            border-radius: 10px;
            margin-left: -18px;
            margin-right: -18px;

            img {
                border-radius: 10px 10px 0 0;
            }
        }

        .infographics__image--bg {
            padding-top: 60%;

            img {
                height: 150%;
                border-radius: 10px;
            }
        }
    }

    &--large-1 {

        .infographics__number,
        .infographics__text {
            text-align: center;
        }
    }

    &--large-2 {
        .infographics__lead {
            flex: 1;
        }

        .infographics__card-content {
            justify-content: space-between;
        }

        .infographics__image {
            height: 460px;
            // width: 100%;
        }
    }

    &__links {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        padding: 21px 16px;
        gap: 7px;

        @media (min-width: $tablet) {
            gap: 24px;
            padding: 21px 16px;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
        }

        @media (min-width: $desktop) {
            padding: 21px 0px;
        }

        &--mobile {
            display: flex;

            @media (min-width: $desktop) {
                display: none;
            }
        }

        &--desktop {
            display: none;

            @media (min-width: $desktop) {
                display: flex;
            }
        }
    }

    &__link {
        color: $omm-blue;
    }

    &__card-content--inner {
        flex: 1;
    }

    &--vertical {
        &-top {
            .infographics__card-content--inner {
                display: flex;
                flex-direction: column-reverse;
                justify-content: flex-start;
            }

            .infographics__card-content {
                border-radius: 10px;
            }
        }

        &-bottom {
            .infographics__card-content {
                border-radius: 10px;
            }

            .infographics--medium-5 .infographics__image--bg img {
                border-radius: 0 0 10px 10px;
            }

            .infographics__card-content--inner {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }
    }
}