.navigation-news {
    flex: 1;
    position: relative;

    &__filters {

        display: flex;
        justify-content: flex-start;
        gap: 5px;
        align-items: center;
        border-bottom: 1px solid $omm-grey-soft-3;

        @media (min-width: $tablet) {
            padding: 22px 25px;
        }

        &-title {
            color: $slate-dark;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            margin-right: 18px;
        }
    }

    &__view-all {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 23px 0;
    }

    &__view-all-button {
        display: inline-flex;
        text-transform: none;
    }

    &__filter-button {
        padding: $space-xxxs $space-xxs;
        border-radius: 120px;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.5px;
        border: none;
        border-color: transparent;
        background-color: $omm-blue-soft-1;
        color: $omm-dark-blue-01;
        cursor: pointer;
        transition: all 200ms ease-in-out;

        &:hover,
        &:focus {
            background-color: $omm-blue-soft-3;
        }

        &--active {
            background-color: $omm-blue-soft-3;
        }
    }

    &__results {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
    }

    &__result-section {
        padding: 23px 23px 0 23px;
        width: 50%;
        align-self: stretch;

        &:first-of-type {
            // border-right: 1px solid $omm-grey-soft-3;
        }
    }

    &__result {
        text-decoration: none;

        &:hover,
        &:focus {
            .navigation-news__result-title {
                color: $omm-blue;
                text-decoration: underline;
            }
        }
    }

    &__result-meta {
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.3px;
        color: $slate;
        margin-bottom: 9px;

        span {
            margin-right: 24px;
        }
    }

    &__result-title {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -0.28px;
        color: $slate-dark;
        margin-bottom: 23px;
        max-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__link {
        display: block;
        color: $omm-midnight;
        padding: 10px 0px;
        cursor: pointer;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
    }
}