.alumni-spotlights {
    &__header {
        @include gutters();
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 22px;
        flex: 1;
        width: 100%;

        &--action {
            @media (min-width: $tablet) {
                justify-content: space-between;
            }
        }

        h2 {
            font-size: 28px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: -0.84px;
            color: $omm-blue;
            text-align: center;
            margin-bottom: 0px;

            @media (min-width: $tablet) {
                font-size: 40px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -1.2px;
            }
        }
    }

    &__action {
        display: none;

        @media (min-width: $tablet) {
            display: block;
        }

        &--mobile {
            display: flex;
            justify-content: center;

            a {
                display: inline-flex;
                min-width: 210px;

            }

            @media (min-width: $tablet) {
                display: none;
            }
        }
    }

    &__card-wrapper {
        padding: 0px 12px 0px 12px;
        width: 100%;
        overflow-x: scroll;
        display: flex;
        align-items: center;
        gap: 16px;

        @media (min-width: $desktop) {
            padding: 0px 0px;
        }

        /* width */
        &::-webkit-scrollbar {
            width: 2px;
            display: none;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background-color: $omm-blue-soft-1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            width: 2px;
            height: 2px;
            opacity: 0.64;
            background-color: $slate;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        border-radius: 10px;
        background-color: $omm-blue-soft-1;
        margin-bottom: $space-xxs;
        padding: 19px 17px;
        height: auto;

        @media (min-width: $tablet) {
            padding: 30px 34px;
        }

        &.swiper-slide {
            height: auto;
        }
    }

    &__card-header {

        margin-bottom: 17px;

        @media (min-width: $tablet) {
            margin-bottom: 22px;
            display: flex;
            align-items: center;
        }
    }

    &__card-image {
        width: 70px;
        height: 70px;
        border-radius: 70px;
        background-color: $slate;
        margin-right: 16px;
        margin-bottom: 13px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @media (min-width: $tablet) {
            margin-bottom: 0;
        }
    }

    &__card-title {
        line-height: 1.2;

        div:first-of-type {
            font-size: 20px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.83px;
            text-align: left;
            color: black;
            margin-top: 0px;
            margin-bottom: 2px;

            @media (min-width: $tablet) {
                font-size: 28px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -1px;
                text-align: left;
                margin-bottom: 0px;
            }
        }

        div:nth-of-type(2) {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            margin-top: 2px;
            margin-bottom: 2px;
            color: black;

            @media (min-width: $tablet) {
                margin-bottom: 4px;
                color: $slate;
                font-size: 14px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: -0.5px;
                text-align: left;
            }
        }
    }

    &__card-content {
        flex: 1;

        p {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.4;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            color: $omm-blue;
            margin-bottom: 25px;

            @media (min-width: $tablet) {
                font-size: 18px !important;
                font-weight: 300;
                font-style: normal;
                text-align: left;
            }
        }
    }

    &__card-action {
        display: flex;
        justify-content: center;

        @media (min-width: $tablet) {
            justify-content: flex-start;
        }
    }

    .swiper {
        border-radius: 10px;
        
        // this is to compensate for the next and prev buttons
        @media (min-width: $laptop) {
            width: calc(100% - (2 * 44px));
        }
    }
}