.professionals-services-search {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    gap: $space-md $space-sm;


    @media(min-width: $tablet) {
        flex-direction: row;
        gap: $space-md $space-xxs;
    }

    &__card {
        padding: 24px;
        border-radius: 10px;
        box-shadow: 0 0 11px 0 rgba(101, 119, 134, 0.04);
        background-color: $soft-blue-01;
        flex: 1;
        align-self: stretch;
        display: flex;
        flex-direction: column;

        &--dark {
            background-color: $soft-blue-03;

            .professionals-services-search__footer-title {
                display: none;

                @media(min-width: $desktop) {
                    display: block;
                }
            }

            .news-block__cards-filter {
                margin-bottom: 16px;

                @media(min-width: $desktop) {
                    margin-bottom: 24px;
                }
            }
        }

        width: 100%;

        @media(min-width: $desktop) {
            width: auto;
        }
    }

    &__card-title {
        font-size: 24px;
        flex: 1;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.86px;
        line-height: 1.14;
        text-align: left;
        margin-bottom: 11px;
        color: $omm-blue;

        @media(min-width: $desktop) {
            margin-bottom: 21px;
            font-size: 34px;
            font-stretch: normal;
            flex: 0;
            letter-spacing: -1.02px;
        }
    }

    &__form {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        @media(min-width: $desktop) {
            flex: 1;
            min-height: 60px;
        }

        form {
            width: 100%;
        }
    }

    &__radios {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
    }

    &__radio-wrapper {
        label {
            color: $slate-dark;
            font-size: 12px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: 0.5px;
            line-height: 1;
            vertical-align: middle;
            text-align: left;
            text-transform: uppercase;
        }
    }

    &__footer-title {
        margin-bottom: $space-xxxs;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.12px;
        text-align: left;
        color: #2256b2;
    }

    &__footer-alpha-select {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media(min-width: $desktop) {
            display: none;
        }
    }

    &__footer-alpha-links {
        display: none;

        @media(min-width: $desktop) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__footer-links {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;

        @media (min-width: $desktop) {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 27px;
        }
    }

    &__footer-link {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.42px;
        text-align: left;
        color: $omm-blue;
    }

    &__footer-alpha-link {
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.12px;
        text-align: left;
        text-decoration: none;
        color: $omm-dark-blue-02;
    }

    .news-block__cards-filter {
        max-width: none;

        select {
            @media (min-width: $desktop) {
                padding: $space-xxs $space-md $space-xxs $space-xs;
            }
        }

        i {
            @media (min-width: $desktop) {
                padding: 0 $space-xs;
            }
        }
    }

    &__input-wrapper {
        min-width: 250px;
        position: relative;
        width: 100%;

        input[type="text"] {
            background-color: white;
            border-radius: 50px;
            border: solid 1px $soft-grey-03;
            color: $slate;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            // needed height due to FireFox quirk with input height
            height: 36px; 
            line-height: 1.3;
            padding: $space-xxxs $space-sm $space-xxxs $space-xxs;
            text-overflow: ellipsis;
            transition: background-color 200ms ease-in-out;
            width: 100%;

            @media (min-width: $desktop) {
                height: auto;
                padding: $space-xxs $space-md $space-xxs $space-xs;
            }

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: $slate;
                opacity: 1;
            }
        }

        button {
            background-color: transparent;
            border: none;
            color: $omm-blue;
            cursor: pointer;
            font-size: 18px;
            line-height: 0;
            padding: 0 $space-xxs;
            right: 0;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
            transition: color 200ms ease-in-out;

            &:hover,
            &:focus {
                color: $omm-blue;
            }

            @media (min-width: $desktop) {
                padding: 0 $space-xs;
            }
        }

        &--small {
            @media (min-width: $tablet) {
                input[type="text"] {
                    height: 42px;
                }

                button {
                    height: 44px;
                    font-size: 18px;
                    line-height: 18px;
                }
            }
        }
    }
}

.container .professionals-services-search {
    @include gutters();
}

.container-fluid .professionals-services-search {
    @include guttersFullWidth();
}