.navigation-locations {
    flex: 1;

    &__filters {

        display: flex;
        justify-content: flex-start;
        gap: 5px;
        align-items: center;
        margin-bottom: 16px;
        padding: 0 23px 0 23px;

        @media (min-width: $tablet) {
            padding: 22px 25px;
        }

        @media (min-width: 1024px) {
            margin-bottom: 0px;
            border-bottom: 1px solid $omm-grey-soft-3;
        }

        &-title {
            display: none;
            color: $slate-dark;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;
            margin-right: 18px;

            @media (min-width: 1024px) {
                display: block;
            }
        }
    }

    &__filter-button {
        padding: $space-xxxs $space-xxs;
        border-radius: 120px;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.5px;
        border: none;
        border-color: transparent;
        background-color: $omm-blue-soft-1;
        color: $omm-dark-blue-01;
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: $omm-blue;
            color: white;

            @media (min-width: 1024px) {
                color: $omm-blue;
                background-color: $omm-blue-soft-3;
            }
        }

        &--active {
            background-color: $omm-blue;
            color: white;

            @media (min-width: 1024px) {
                color: $omm-blue;
                background-color: $omm-blue-soft-3;
            }
        }
    }

    &__results {

        @media (min-width: 1024px) {
            padding: 25px 25px;
        }

        @media (min-width: $tablet) {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
        }

        &>div {
            @media (min-width: 1024px) {
                display: inline-block;
                width: auto;
                min-width: 184px;
            }
        }
    }

    &__link-wrapper {
        width: 100%;
    }

    &__link {
        display: block;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: black;
        cursor: pointer;
        opacity: 1;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.64px;
        text-align: left;
        padding: 14px 23px;
        border-bottom: 1px solid $soft-grey-03;
        text-decoration: none;

        @media (min-width: 1024px) {
            border: none;
            padding: 0;
            display: block;
            color: black;
            font-size: 16px;
            line-height: 1.29;
            letter-spacing: -0.29px;
            margin-bottom: 12px;
            text-decoration: none;
        }

        &:hover,
        &:focus {
            color: $omm-blue;
        }
    }

    &__mobile-action {
        padding: $gutter-md;
        text-align: center;

        .button {
            display: inline-flex;
            margin: 0 auto;
        }

        @media (min-width: 1024px) {
            display: none;
        }
    }
}