.page-subnavigation {
    @include sticky();

    &__sticky {}

    &__non-sticky {
        @include resetSticky();
    }

    width: auto;
    min-width: 100%;
    margin: 0;
    padding: 0 0px;
    background-color: white;
    border-bottom: 1px solid $soft-grey-03;
    scroll-behavior: smooth;
    z-index: 3998;

    &__container {
        @include hiddenScrollbar();
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 auto;
        height: 52px;
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;

        @media (min-width: $tablet) {
            @include container();
            overflow-x: visible;
            overflow-y: visible;
        }

        @media (min-width: $desktop) {
            overflow-x: visible;
            overflow-y: visible;
            @include container();
        }

        &--full-width {
            max-width: unset;
        }
    }

    &__items-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        align-self: stretch;
        flex: 1;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    &__items {
        @include flexbox();
        @include hiddenScrollbar();
        @include gutters();
        align-self: stretch;
        flex: 1;
        overflow-x: auto;
        overflow-y: hidden;
    }

    &__gradient-overlay {
        background-image: linear-gradient(to left,
                rgba(255, 255, 255, 1) 10%,
                rgba(255, 255, 255, 0) 100%);
        height: 52px;
        position: absolute;
        right: 0;
        width: 18px;
        top: 0;
        transition: all ease-in-out 200ms;
        z-index: 6000;

        &--left {
            background-image: linear-gradient(to right,
                    rgba(255, 255, 255, 1) 10%,
                    rgba(255, 255, 255, 0) 100%);
            left: 0;

            @media (min-width: $desktop) {
                display: none;
            }
        }
    }

    &__item {
        min-width: fit-content;
        list-style: none;
        padding: 0 12px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.24px;
        color: $slate;
        text-transform: uppercase;
        position: relative;
        padding-bottom: 2px;
        word-break: keep-all;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-decoration: none;

        @media (min-width: $tablet) {
            padding: 0 18px;
        }

        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 4px;
            bottom: -1px;
            left: 50%;
            transform: translateX(-50%);
            background-color: transparent;
        }

        &:hover {
            color: $omm-blue;
            cursor: pointer;

            &:after {
                width: 100%;
                background-color: $omm-blue;
                transition: width 200ms ease-in-out;
            }
        }

        &.active {
            color: $omm-blue;

            &:after {
                width: 100%;
                color: $omm-blue;
                background-color: $omm-blue;
            }
        }

        &.no-pointer:hover {
            cursor: default;
        }
    }

    &__actions {
        display: none;
        position: absolute;
        right: 16px;
        top: 65px;

        @media (min-width: $tablet) {
            display: block;
            position: unset;
            @include gutters();
        }

        .button {
            font-size: 14px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: -0.3px;
            padding: $space-xxxs $space-xs;

            &--icon {
                padding: $space-xxxs $space-xxs $space-xxxs $space-xs;
            }
        }
    }

    &__actions--mobile {
        display: flex;
        padding: $space-xxxs $gutter-sm;
        justify-content: flex-end;
        width: 100%;

        @media (min-width: $tablet) {
            display: none;
        }
    }

    &+.image-text-block__wrapper {
        padding-top: 32px;
    }
}