.location {
    .page-hero__image {
        @media (max-width: $tablet) {
            border-radius: 10px 10px 0 0;
        }
    }

    .page-hero__intro-content--mobile {
        @media (max-width: $tablet) {
            margin-top: 0;
            border-radius: 0 0 10px 10px;
        }
    }

}

.location-content {
    @include gutters();

    &__content {

        h1,
        h2,
        h3,
        h4 {
            color: $omm-blue;
            line-height: 1.3;
        }

        h2 {
            @media (min-width: $tablet) {
                font-size: 28px;
            }
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 16px;

            @media (min-width: $tablet) {
                margin-bottom: 32px;
            }
        }

        p {
            color: $slate-dark;
        }
    }

    &__section {}

    .tagged-news__title {
        text-align: left;

        @media (min-width: $tablet) {
            text-align: left;
        }
    }
}

.location-address {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 16px;
    border-top: 1px solid $soft-grey-03;
    border-bottom: 1px solid $soft-grey-03;

    @media (min-width: $tablet) {
        border-radius: 10px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 16px;
        border: none;
        background-color: $omm-midnight;
        color: white;
        padding: 35px 30px 35px 35px;
        margin-bottom: 0;
    }

    @media (min-width: $desktop) {
        padding: 35px 40px 30px 35px;
    }

    &__toggle {
        cursor: pointer;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        padding: 15px 22px;
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        color: $omm-blue;

        @media (min-width: $tablet) {
            display: none;
        }

        &:after {
            content: "\e905";
            font-family: 'icomoon';
            font-size: 16px;
            color: $omm-blue;
        }
    }

    &__container {
        display: none;
        color: $slate;
        padding: 15px 22px;

        @media (min-width: $tablet) {
            display: block;
            color: white;
            padding: 0;
        }
    }

    &__content {

        min-height: 80px;

        p {
            @include justify-content(flex-end);
            width: 165px;
            white-space: pre-wrap;

            @media (min-width: 500px) {
                width: 225px;
                @include justify-content(flex-start);
            }

            @media (min-width: $tablet) {
                max-width: 50%;
                width: 250px;
                @include justify-content(flex-start);
            }
        }

        @media (min-width: $tablet) {
            @include flexbox();
            @include align-items(flex-start);
            @include justify-content(space-between);
            max-width: 500px;
        }
    }

    &__title {
        font-size: 16px;
        line-height: normal;
        letter-spacing: -0.48px;
        margin-bottom: 10px;

        @media (min-width: $tablet) {
            max-width: 250px;
        }
    }

    &__actions {
        @include flexbox();
        flex-direction: column;
        margin-top: $space-xs;

        p {
            margin: 0;
            line-height: 20px;
            flex: 1;
        }

        a {
            color: $omm-blue;
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            @media (min-width: $tablet) {
                color: white;
            }
        }

        @media (min-width: $tablet) {
            flex-direction: row;
            max-width: 100%;
            justify-content: flex-start;
        }

        &-tour {
            color: $omm-blue;
            order: 1;
            min-width: 120px;

            @media (min-width: $tablet) {
                color: white;
                order: unset;
                margin-top: 0;
                flex: 1;
                min-width: auto;
                max-width: 250px;
            }
        }

        &-phone {
            order: 0;
            width: 100%;
            max-width: 400px;
            @include flexbox();
            @include align-items(center);
            @include justify-content(flex-start);

            @media (min-width: 500px) {
                width: 225px;
                @include flexbox();
                @include flex-direction(column);
                @include align-items(flex-start);
            }

            @media (min-width: $tablet) {
                order: unset;
                margin-top: 0;
                flex: 1;
                min-width: auto;
                width: auto;
                max-width: 350px;
            }

            @media (min-width: $laptop) {
                margin-top: 0;
                @include flex-direction(row);
                flex: 1;
                min-width: auto;
                width: 100%;
                display: flex;
                max-width: 100%;
                justify-content: space-between;
            }
        }

        &-directions {
            margin-left: 15px;
            line-height: 1.3;

            @media (min-width: 500px) {
                width: 100%;
                margin-left: 0;
            }

            @media (min-width: $laptop) {
                width: auto;
                margin-left: 15px;
            }
        }
    }

    input {
        display: none;
    }

    input:checked~.location-address__toggle {
        &:after {
            content: "\e90f";
        }
    }

    input:checked~.location-address__container {
        display: block;
    }
}