.overlay {
    position: fixed;
    z-index: 11000;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: none;

    &--show {
        display: block;
    }

    .umbraco-forms-caption {
        display: none;
    }

    &__header {
        margin-bottom: 24px;
    }

    &__title {
        color: $omm-blue;
        font-size: 24px;
        margin-bottom: 8px;
        font-weight: 300;
        line-height: 1.3;
        text-align: center;

        @media (min-width: $tablet) {
            font-size: 28px;
        }
    }

    &__subtitle {
        color: $slate;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.3;
        text-align: center;

        @media (min-width: $tablet) {
            font-size: 14px;
        }
    }

    &__container {
        border-radius: 10px;
        border: solid 1px #707070;
        background-color: white;
        width: 90%;
        height: 90%;
        position: relative;
        z-index: 12000;
        overflow: hidden;
        left: 50%;
        transform: translate(-50%, 0);
        top: 5vh;

        @media (min-width: $tablet) {
            max-width: 850px;
            height: 90vh;
            left: 50%;
            transform: translate(-50%, 0);
            top: 4vh;
        }

        @media (max-height: 754px) {
            border-radius: 10px 10px 0 0;
            max-width: 850px;
            height: calc(100vh - 32px);
            top: 32px;
        }

        &--video {
            // max-width: 90% !important;
            // width: 90% !important;
            max-height: 90% !important;
            height: 90% !important;
            top: 5%;

            .overlay--clear .overlay__icon {
                top: $gutter-md;
                right: $gutter-md;
            }

            .overlay__scroll {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
            }

            .overlay__content {
                flex: 1;
                width: 100%;
            }
        }

        &--small {
            height: 90%;
            top: 10vh;
            border-radius: 10px !important;

            @media (min-width: $tablet) {
                height: 90vh;
                top: 10vh;
            }

            @media (min-height: 754px) {
                height: auto;
                top: 32px;
            }

            .overlay__subtitle {
                padding: 0px 16px 32px 0px;

                @media (min-width: $tablet) {
                    padding: 32px 64px 32px 64px;
                }
            }
        }
    }

    &__scroll {
        overflow-x: hidden;
        overflow-y: scroll;
        height: 100%;
        padding: 35px 20px;

        /* width */
        &::-webkit-scrollbar {
            width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background-color: white;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            width: 4px;
            height: 16px;
            opacity: 0.64;
            background-color: $omm-grey-soft-3;
        }

        @media (min-width: $tablet) {
            padding: 35px 20px;
        }
    }

    &__content {
        color: $slate;
        padding: 0;

        @media (min-width: $tablet) {
            max-width: 550px;
            margin: auto;

            &--full {
                max-width: none;
            }
        }
    }

    &__icon {
        color: $slate;
        font-size: 22px;
        position: absolute;
        top: 12px;
        right: 16px;
        font-weight: 300;
        line-height: 1.2;
        cursor: pointer;
        transition: all 200ms ease-in-out;
        z-index: 1;

        &:hover,
        &:focus {
            color: $omm-blue;
        }
    }

    &-bg {
        background-color: rgba(42, 46, 49, 0.73);
        width: 100%;
        left: 0;
        position: fixed;
        height: 100%;
        top: 0;
        z-index: 8500;
    }

    &__video-player-wrapper {
        // flex: 1;
        // display: flex;
        // width: 100%;
        // height: 100%;
        // flex-direction: column;
    }

    &__podcast-player-wrapper {}

    .umbraco-forms-page {
        max-width: 332px;
        margin: 0 auto;
    }

    &--clear {


        .overlay__title {
            display: none;
        }

        .overlay__container {
            background-color: transparent !important;
            border: none;
            overflow: visible;
            border-radius: 0;
        }

        .overlay__icon {
            color: white;
            top: 0;
            right: 0;
        }

        .overlay__content {
            padding: 10px 0px;
            max-width: none;
        }

        .overlay__scroll {
            padding: 0;
            overflow-y: hidden;
        }
    }

    &__items {
        color: rgba(101, 119, 134, 1);
        display: flex;
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
        gap: 16px;
        left: 16px;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 6px;
        z-index: 1;

        &::before {
            background: white;
            border-radius: 10px;
            box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.2);
            bottom: -6px;
            content: "";
            left: -50px;
            position: absolute;
            right: -10px;
            top: -50px;
        }

        @media(min-width: $tablet) {
            font-size: 14px;
        }
    }

    &__item {
        font-size: inherit;
        position: relative;

        &:not(:first-child)::before {
            background: rgba(101, 119, 134, 1);
            content: "";
            height: 60%;
            left: -8px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
        }

        &--current {
            color: $omm-blue;
        }
    }

    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 32px 32px 32px;
        gap: 16px;
    }
}