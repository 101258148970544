.video-plus-text {


    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        @media (min-width: $laptop) {
            flex: 1;
            padding-right: 49px;
        }
    }

    &__video {
        width: 100%;
        height: 350px;
        border-radius: 10px;
        background-color: $slate;
        background-position: center;
        background-size: cover;
        overflow: hidden;
        position: relative;

        @media (min-width: $laptop) {
            width: 50%;
            height: 660px;
        }
    }

    &__super-title {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.12px;
        margin-bottom: 12px;
        color: $omm-dark-blue-02;

        @media (min-width: $laptop) {
            margin-bottom: 16px;
        }

    }

    &__title {
        font-size: 24px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: -0.72px;
        margin-bottom: 16px;
        color: $omm-blue;

        @media (min-width: $laptop) {
            font-size: 34px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -1px;
            text-align: left;
            padding-right: 24px;
            line-height: 1.17;
        }
    }

    &__subtitle {
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        margin-bottom: 34px;

        ul {
            padding-left: 22px;
            margin-left: 0px;
        }

        li {
            padding-left: 10px;
            margin-bottom: 12px;

            &::marker {
                color: $omm-blue;
                font-size: 8px;
                vertical-align: middle;
                line-height: 1;
            }
        }

        * {
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: left;

            @media (min-width: $laptop) {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: -0.4px;
            }
        }
    }

    &--small {
        .video-plus-text__title {
            @media (min-width: $laptop) {
                font-size: 34px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: -1.02px;
            }
        }

        .video-plus-text__video {

            height: 267px;

            @media (min-width: $laptop) {
                height: 267px;
                max-width: 472px;
            }
        }
    }
}

.container .video-plus-text {
    @include gutters();
}

.container-fluid .video-plus-text {
    @include guttersFullWidth();
}