.contact-us {
    padding: 32px 16px 72px 16px;

    @media (min-width: $tablet) {
        padding: 32px 16px 72px 16px;
    }

    .umbraco-forms-form {
        max-width: 561px;
        margin: 0 auto;
    }
}