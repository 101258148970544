.navigation-intro-text {
    padding: 30px;
    max-width: 30%;

    h3 {
        font-weight: normal;
        font-size: 20px;
        margin-top: 0;
        margin-bottom: 20px;
    }
}